<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperAddEditFilter"
      @keydown.esc="discardChanges"
      :tabindex="0">
    <div class="popup popup-big overflow-auto">
      <p class="text-xl text-center mb-2">{{title}}</p>
      <hr class="mb-6"/>
      <p class="mb-2">Filter name</p>
      <CustomInput v-model='name'
                    :name="'identifier'"
                    :searchStyle="false"
                    class="mb-4"/>
      <p class="mb-2">Filter code</p>
      <CodeEditor :readonly="false"
                  :code="expression"
                  :language="'python'"
                  :loading="false"
                  :height="'h-32'"
                  @codeUpdated="onExpressionUpdated"
                  class="mb-6" />
      <Column class="mb-6">
        <p class="mb-2">Test it</p>
        <div class=" flex mb-4 -mx-2">
          <div class="w-1/2 px-2">
            <Column class="relative">
              <p class="mb-2">Source event payload</p>
              <CodeEditor :readonly="false"
                          :code="sourceEventPayload"
                          :language="'json'"
                          :loading="false"
                          :height="'h-32'"
                          @codeUpdated="onSourceEventPayloadUpdated"
                          class="mb-1" />
              <p v-if="sourceEventPayloadError" class="absolute text-red-500 text-xs">Incorrect json format</p>
            </Column>
          </div>
          <div class="w-1/2 px-2">
            <Column class="relative">
              <p class="mb-2">Envelope</p>
              <CodeEditor :readonly="false"
                          :code="envelope"
                          :language="'json'"
                          :loading="false"
                          :height="'h-32'"
                          @codeUpdated="onEnvelopeUpdated"
                          class="mb-1" />
              <p v-if="envelopeError" class="absolute text-red-500 text-xs">Incorrect json format</p>
            </Column>
          </div>
        </div>
        <CustomButton @clicked="testFilter">Test filter</CustomButton>
        <p class="flex items-center my-2 py-1">Outcome: <span :class="outComeStatus" class="outcome">{{outCome}}</span></p>
        <p class="mb-2">Log:</p>
        <Column class="overflow-auto py-1">
          <code :class="outComeStatus"
                v-html="logMessages"
                class="block text-xs h-16 mb-4 log-messages">
          </code>
        </Column>
      </Column>
      <Filters v-if="!editMode"
                @filtersChange="onFiltersChange"
                :selectedFilters="selectedFilters"/>
      <ItemsTable v-if="isAffectedRulesPanelEnabled"
                  :title="'Rules affected by this filter'"
                  :searchFilter="false"
                  :searchKey="''"
                  :dateRangeFilter="false"
                  :eventTypesFilter="false"
                  :fetchSettings="{}"
                  :defaultItems="affectedRules"
                  :tableSettings="affectedRulesTableSettings"
                  :selectable="false"
                  :multiSelect="false"
                  :dateDetailed="false"
                  :defaultItem="null"/>
      <div class="flex">
        <CustomButton :disabled="isSaveButtonDisabled" @clicked="saveChanges"
                      class="mr-4">
                      Save changes
        </CustomButton>
        <CustomButton @clicked="discardChanges">
          Discard changes and cancel
        </CustomButton>
      </div>
    </div>
  </div>
  <Popup v-if="isNotificationPopupVisible"
        :text="notificationPopupText"
        :isChoicePopup="notificationPopupChoiceMode"
        :confirmText="'Ok'"
        :cancelText="'Cancel'"
        @popupEvent="onNotificationPopupEvent"/>
  <FilterAffectsRulesPopup v-if="filterAffectsRulesPopupVisible"
        :rulesAffecteNr="affectedRules.length"
        @popupEvent="onFilterAffectsRulePopupEvent"/>
  <Preloader v-if="loading" :type="'full'"/>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import areModelsEqualMixin from '@/mixins/areModelsEqualMixin.js'
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import arrayToStringFormatterMixin from '@/mixins/arrayToStringFormatterMixin.js'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'
import Column from '@/components/common/ui/Column.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Filters from '@/components/common/filters/addEditFilterPopup/Filters.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import Popup from '@/components/common/ui/Popup.vue'
import FilterAffectsRulesPopup from '@/components/common/filters/addEditFilterPopup/FilterAffectsRulesPopup.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints.js'
import appSettings from '@/configs/appSettings.js'

export default {
  name: 'AddEditFilterPopup',
  mixins: [fetchDataMixin, areModelsEqualMixin, stringifyDataMixin, arrayToStringFormatterMixin, requestDataMixin],
  emits: ['popupEvent'],
  components: {
    CustomInput,
    CodeEditor,
    Column,
    CustomButton,
    Filters,
    ItemsTable,
    Popup,
    FilterAffectsRulesPopup,
    Preloader
  },
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    filterId: {
      type: String
    },
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fetchSettings: {},
      title: this.editMode ? 'Edit event payload filter' : 'New event payload filter',
      initialFilter: {
        id: null,
        name: '',
        expression: '',
        domains: null,
        sources: null,
        types: null
      },
      name: '',
      expression: '',
      description: '',
      sourceEventPayload: '',
      sourceEventPayloadError: false,
      envelope: '',
      envelopeError: false,
      outCome: '',
      outComeStatus: '',
      logMessages: '',
      activeScopeFilters: {
        domain: null,
        source: null,
        eventType: null
      },
      selectedFilters: {},
      affectedRulesTableSettings: {
        columnTitles: ['Active from', 'Active to', 'Rule name'],
        columnKeys: ['validFrom', 'validTo', 'name']
      },
      affectedRules: [],
      loading: false,
      isNotificationPopupVisible: false,
      notificationPopupText: '',
      notificationPopupChoiceMode: false,
      notificationType: '',
      filterAffectsRulesPopupVisible: false,
      parentEventTypeId: null
    }
  },
  mounted () {
    this.$refs.wrapperAddEditFilter.focus()
    if (this.editMode && this.filterId) {
      this.fetchSettings = {
        url: endpoints.filters + '/' + this.filterId
      }
    }

    this.selectedFilters = {
      domains: this.activeFilters.domain,
      sources: this.activeFilters.eventType,
      types: this.activeFilters.source
    }

    this.activeScopeFilters.domain = this.activeFilters.domain
    this.activeScopeFilters.eventType = this.activeFilters.eventType
    this.activeScopeFilters.source = this.activeFilters.source
  },
  computed: {
    isAffectedRulesPanelEnabled () {
      return this.editMode
    },
    isSaveButtonDisabled () {
      if (this.editMode) {
        return this.areModelsEqual(this.initialFilter, this.filter)
      } else {
        return this.areModelsEqual(this.initialFilter, this.filter) ||
               !this.name.length ||
               !this.expression.length ||
               !this.activeScopeFilters.domain ||
               !this.activeScopeFilters.eventType ||
               !this.activeScopeFilters.source
      }
    },
    filter () {
      return {
        parentEventTypeId: this.editMode ? this.parentEventTypeId : (this.activeScopeFilters?.eventType ? this.activeScopeFilters?.eventType[0] : null),
        name: this.name,
        description: this.description,
        expression: this.expression
      }
    }
  },
  watch: {
    fetchedData ({ name, expression, description, domains, sources, eventTypes, rulesAffectedByFilter, parentEventTypeId }) {
      if (this.editMode) {
        this.parentEventTypeId = parentEventTypeId
        this.name = name
        this.expression = expression
        this.description = description
        this.selectedFilters = {
          domains,
          sources,
          types: eventTypes
        }
        this.affectedRules = rulesAffectedByFilter
        this.activeScopeFilters = {
          domain: domains,
          source: sources,
          eventType: eventTypes
        }
        this.initialFilter = {
          id: this.filterId,
          name: name,
          expression: expression,
          domains: domains,
          sources: sources,
          types: eventTypes
        }
      }
    }
  },
  methods: {
    onExpressionUpdated (code) {
      this.expression = code
    },
    onSourceEventPayloadUpdated (code) {
      this.sourceEventPayload = code
    },
    onEnvelopeUpdated (code) {
      this.envelope = code
    },
    async testFilter () {
      this.sourceEventPayloadError = false
      this.envelopeError = false
      let sourceEventPayload = ''
      let envelope = ''

      try {
        sourceEventPayload = JSON.parse(this.sourceEventPayload)
      } catch (error) {
        sourceEventPayload = ''
        this.sourceEventPayloadError = true
      }

      try {
        envelope = JSON.parse(this.envelope)
      } catch (error) {
        envelope = ''
        this.envelopeError = true
      }

      if (!this.sourceEventPayloadError && !this.envelopeError) {
        const url = this.editMode ? endpoints.filters + '/' + this.filterId + '/test' : endpoints.filterTest
        const testNewFilterData = {
          expression: this.expression,
          sourceEventPayload,
          envelope
        }

        const [error, response] = await this.requestData('post', url, {}, testNewFilterData, 'loading')

        if (error) {
          this.cleanTestResultFields()

          const testErrors = error.response.data.detail
          let string = ''

          testErrors.forEach((item) => {
            string += item.msg + ': "' + item.loc.at(-1) + '" in field ' + item.loc[1] + '</br>'
          })

          this.outCome = 'Failed - see errors below'
          this.logMessages = string
          this.outComeStatus = 'failed'
        } else {
          this.outCome = (response.success ? 'True ' : 'False ') + (response.message ? this.arrayToStringFormatter(response.message) : '')
          this.logMessages = this.arrayToStringFormatter(response.log)
          this.outComeStatus = response.success ? 'success-true' : 'success-false'
        }
      }
    },
    cleanTestResultFields () {
      this.outCome = ''
      this.logMessages = ''
    },
    onFiltersChange (filters) {
      this.activeScopeFilters = filters
      if (this.editMode) {
        this.selectedFilters = {
          domains: [],
          sources: [],
          types: []
        }
      }
    },
    saveChanges () {
      if (this.affectedRules.length > 0) {
        this.filterAffectsRulesPopupVisible = true
      } else {
        this.handleSaveChangesRequest()
      }
    },
    async handleSaveChangesRequest () {
      const url = this.editMode ? endpoints.filters + '/' + this.filterId : endpoints.filters

      const [error, response] = await this.requestData(this.editMode ? 'put' : 'post', url, {}, this.filter, 'loading')

      if (error) {
        this.notificationType = 'error'
        this.notificationPopupChoiceMode = false
        this.notificationPopupText = appSettings.messages.error
        this.isNotificationPopupVisible = true
      } else {
        if (response.success) {
          this.$emit('popupEvent', { type: this.editMode ? 'edit' : 'add', filter: response.payload })
        }
      }
    },
    discardChanges () {
      if (this.areModelsEqual(this.initialFilter, this.filter)) {
        this.$emit('popupEvent', false)
      } else {
        this.notificationType = 'discard'
        this.notificationPopupChoiceMode = true
        this.notificationPopupText = 'The changes you made will be discarded, are you sure?'
        this.isNotificationPopupVisible = true
      }
    },
    onNotificationPopupEvent (event) {
      if (!event) this.$refs.wrapperAddEditFilter.focus()
      if (this.notificationType === 'discard') {
        if (event) {
          this.$emit('popupEvent', false)
        }
      }
      this.isNotificationPopupVisible = false
    },
    onFilterAffectsRulePopupEvent (event) {
      switch (event) {
        case 'save':
          this.handleSaveChangesRequest()
          break
        case 'discard':
          this.$emit('popupEvent', false)
          break
        case 'edit':
          this.$refs.wrapperAddEditFilter.focus()
          break
      }
      this.filterAffectsRulesPopupVisible = false
    }
  }
}
</script>
