import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import ViewEditRules from '@/views/ViewEditRules.vue'
import AuditIndividual from '@/views/AuditIndividual.vue'
import AuditEvent from '@/views/AuditEvent'
import AuditRule from '@/views/AuditRule'
import AuditUser from '@/views/AuditUser'
import ManageAccess from '@/views/ManageAccess.vue'
import ReviewAccess from '@/views/ReviewAccess'
import EventSources from '@/views/EventSources.vue'
import PathNotFound from '@/views/PathNotFound.vue'
import Storage from '@/views/Storage.vue'
import Notifications from '@/views/Notifications.vue'
import { authenticationGuard } from '@/services/authenticationGuard'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: authenticationGuard
  },
  {
    path: '/view-edit-rules',
    name: 'ViewEditRules',
    component: ViewEditRules,
    beforeEnter: authenticationGuard
  },
  {
    path: '/audit-individual',
    name: 'AuditIndividual',
    component: AuditIndividual,
    beforeEnter: authenticationGuard
  },
  {
    path: '/audit-event',
    name: 'AuditEvent',
    component: AuditEvent,
    beforeEnter: authenticationGuard
  },
  {
    path: '/audit-rule',
    name: 'AuditRule',
    component: AuditRule,
    beforeEnter: authenticationGuard
  },
  {
    path: '/audit-user',
    name: 'AuditUser',
    component: AuditUser,
    beforeEnter: authenticationGuard
  },
  {
    path: '/manage-access',
    name: 'ManageAccess',
    component: ManageAccess,
    beforeEnter: authenticationGuard
  },
  {
    path: '/review-access',
    name: 'ReviewAccess',
    component: ReviewAccess,
    beforeEnter: authenticationGuard
  },
  {
    path: '/event-sources',
    name: 'EventSources',
    component: EventSources,
    beforeEnter: authenticationGuard
  },
  {
    path: '/storage',
    name: 'Storage',
    component: Storage,
    beforeEnter: authenticationGuard
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    beforeEnter: authenticationGuard
  },
  {
    path: '/:pathMatch(.*)*',
    component: PathNotFound,
    beforeEnter: authenticationGuard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
