<template>
  <Panel>
    <CustomButton @clicked="revokeAccess">
      Revoke Access
    </CustomButton>
  </Panel>
  <Popup v-if="isRevokeAccessPopupVisible"
          :text="revokeAccessText"
          :isChoicePopup="true"
          :confirmText="'Revoke'"
          :cancelText="'Cancel'"
          @popupEvent="onRevokeAccessPopupEvent"/>
  <Popup v-if="dataProcessedPopupVisible"
        :text="dataProcessedPopupText"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="onDataProcessedPopupEvent"/>
  <Preloader v-if="loading" :type="'full'"/>
</template>

<script>
import manageReviewAccessMixin from '@/mixins/manageReviewAccessMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Popup from '@/components/common/ui/Popup.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints'

export default {
  name: 'RemoveAccess',
  mixins: [manageReviewAccessMixin],
  emits: ['dataUpdated'],
  components: {
    Panel,
    Popup,
    CustomButton,
    Preloader
  },
  props: {
    selectedAuthorizedUsers: {
      type: Array,
      reuqired: true
    },
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isRevokeAccessPopupVisible: false
    }
  },
  computed: {
    selectedAuthorizedUserEmails () {
      let emails = ''
      if (this.selectedAuthorizedUsers.length > 0) {
        emails += '?'

        for (let i = 0; i < this.selectedAuthorizedUsers.length; i++) {
          emails += 'emails=' + this.selectedAuthorizedUsers[i].email

          if (i < this.selectedAuthorizedUsers.length - 1) {
            emails += '&'
          }
        }
      }

      return emails
    },
    selectedAuthorizedUserEmailsString () {
      let emails = ''
      if (this.selectedAuthorizedUsers.length > 0) {
        this.selectedAuthorizedUsers.forEach(item => { emails += '</br>' + item.email })
      }

      return emails
    },
    revokeAccessText () {
      return 'Revoke the access of ' + this.selectedAuthorizedUserEmailsString + '?'
    }
  },
  methods: {
    revokeAccess () {
      this.isRevokeAccessPopupVisible = true
    },
    onRevokeAccessPopupEvent (event) {
      if (event) {
        this.handleRequest({}, endpoints.authorizedUsersUI + this.selectedAuthorizedUserEmails, 'delete')
      }

      this.isRevokeAccessPopupVisible = false
    }
  }
}
</script>
