<template>
  <div class="w-full">
    <p class="mb-4">Rules applied:</p>
    <Options :label="''"
            :fetchSettings='{}'
            :options="rulesApplied"
            :selectable="true"
            :multiselect="false"
            :selectedItems="[]"
            :optionType="'rulesApplied'"
            @optionChange="onAppliedRuledChange"/>
  </div>
  <div  class="w-full">
    <Column v-if="isAppliedRuleDetailsVisible">
      <p class="mb-4">Description: {{selectedRuleDescription}}</p>
      <p class="mb-4">Version: {{ruleVersion}}, last edited by: {{lastEditedBy}} on {{lastEditedOn}}
        <span v-if="versionComment">({{versionComment}})</span>
      </p>
      <p class="mb-4">Version valid from {{versionValidFrom}} to {{versionValidTo}}</p>
      <CodeEditor :readonly="true"
                  :code="codeExecutedStringified"
                  :language="'python'"
                  :loading="loading"
                  :height="'h-56'"
                  class="mb-4" />
      <div  v-if="childRules">
        <Options :label="'Child rules:'"
                :fetchSettings='{}'
                :options="childRulesApplied"
                :selectable="true"
                :multiselect="false"
                :selectedItems="[]"
                :optionType="'childRulesApplied'"
                @optionChange="onAppliedChildRuledChange"/>
      </div>
      <div  class="w-full">
        <Column v-if="isAppliedChildRuleDetailsVisible">
          <p class="mb-4">Domain: {{selectedAppliedChildRuleDetails.domain_name}}</p>
          <p class="mb-4">Source: {{selectedAppliedChildRuleDetails.source_name}}</p>
          <p class="mb-4">Event: {{selectedAppliedChildRuleDetails.event_type_name}}</p>
          <p class="mb-4">Version id: {{selectedAppliedChildRuleDetails.version_id}}</p>
          <p class="mb-4">Domain: {{selectedAppliedChildRuleDetails.domain_name}}</p>
          <p class="mb-4">Version: {{selectedAppliedChildRule.versionNum}}, last edited by: {{selectedAppliedChildRule.editedBy}} on {{selectedAppliedChildRule.editedOn}}
            <span v-if="selectedAppliedChildRule.comment">({{selectedAppliedChildRule.comment}})</span>
          </p>
          <p class="mb-4">Version valid from {{selectedAppliedChildRule.validFrom}} to {{selectedAppliedChildRule.validTo}}</p>
          <CodeEditor :readonly="true"
                      :code="selectedAppliedChildRule.code"
                      :language="'python'"
                      :loading="loading"
                      :height="'h-56'"
                      class="mb-4" />
        </Column>
      </div>
      <div v-if="logStringified">
        <CustomPre :heading="'Log output'"
                    :content="logStringified"/>
      </div>
      <div v-if="exceptionsStringified">
        <CustomPre :heading="'Exception raised'"
                    :content="exceptionsStringified"/>
      </div>
      <div v-if="entitiesBefore">
        <h1 class="mb-4">State before rule was applied</h1>
        <div v-for="item in entitiesBefore" :key="item.entity_name + item.entity_id">
          <CustomPre :heading="item.entity_name + '/' + item.entity_id + ' before rule was applied'"
                     :content="item.data"/>
        </div>
      </div>
      <div v-if="entitiesSaved || entitiesSavedFields">
        <h1 class="mb-4">Modified by the rule</h1>
        <div v-for="item in entitiesSaved" :key="item.domain_name + item.domain_document_id">
          <CustomPre :heading="item.domain_name + '/' + item.domain_document_id + '/' + item.subdomain_name + ' replaced with:'"
                     :content="item.data"/>
        </div>
        <div v-for="item in entitiesSavedFields" :key="item.domain_name + item.domain_document_id">
          <CustomPre :heading="item.domain_name + '/' + item.domain_document_id + '/' + item.subdomain_name + '/' + item.field_name + ' replaced with:'"
                     :content="item.data"/>
        </div>
        <div v-for="item in entitiesPatched" :key="item.domain_name + item.domain_document_id">
          <CustomPre :heading="item.domain_name + '/' + item.domain_document_id + '/' + item.subdomain_name + ' patched with:'"
                     :content="item.data"/>
        </div>
      </div>
      <div v-if="postReturn">
        <CustomPre :heading="'POST call response'"
                    :content="postReturn"/>
      </div>
    </Column>
  </div>
</template>

<script>
import arrayToStringFormatterMixin from '@/mixins/arrayToStringFormatterMixin.js'
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import Column from '@/components/common/ui/Column.vue'
import Options from '@/components/common/Options.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'
import CustomPre from '@/components/common/custom/CustomPre.vue'
import endpoints from '@/configs/endpoints.js'

export default {
  name: 'AppliedRuleDetails',
  mixins: [arrayToStringFormatterMixin, stringifyDataMixin, dateFormatterMixin, fetchDataMixin],
  emits: ['ruleSelected', 'ruleUnselected', 'childRuleSelected', 'childRuleUnselected'],
  components: {
    Column,
    Options,
    CodeEditor,
    CustomPre
  },
  props: {
    details: {
      type: [null, Object],
      required: false
    },
    loading: {
      type: Boolean,
      rquired: true
    }
  },
  data () {
    return {
      selectedAppliedRule: null,
      selectedRuleDescription: '',
      selectedRuleDescriptionStringified: '',
      logMessagesFormatted: '',
      codeExecutedStringified: '',
      logStringified: '',
      exceptionsStringified: '',
      versionData: null,
      ruleVersion: '',
      lastEditedBy: '',
      lastEditedOn: '',
      versionValidFrom: '',
      versionValidTo: '',
      versionComment: '',
      entitiesBefore: [],
      entitiesSaved: [],
      entitiesSavedFields: [],
      entitiesPatched: [],
      childRules: [],
      childRule: null,
      postReturn: null,
      fetchedData: null,
      selectedAppliedChildRuleDetails: null,
      selectedAppliedChildRule: null
    }
  },
  computed: {
    isAppliedRuleDetailsVisible () {
      return this.selectedAppliedRule
    },
    isAppliedChildRuleDetailsVisible () {
      return this.selectedAppliedChildRule
    },
    rulesApplied () {
      if (this.details && this.details.rules_outcomes) {
        const ruleList = []
        for (const rule of Object.values(this.details.rules_outcomes)) {
          ruleList.push({
            name: rule.rule_name + ' ' + rule.rule_version_name,
            id: rule.rule_id
          })
        }
        return ruleList
      } else {
        return []
      }
    },
    childRulesApplied () {
      if (this.details && this.details.rules_outcomes && this?.selectedAppliedRule?.reused_rules) {
        const ruleList = []
        for (const rule of Object.values(this.selectedAppliedRule.reused_rules)) {
          ruleList.push({
            name: rule.rule_name + ' ' + rule.rule_version,
            id: rule.rule_id,
            version_id: rule.rule_version_id,
            domain_name: rule.domain_name,
            source_name: rule.source_name,
            event_type_name: rule.event_type_name,
            rule_name: rule.rule_name
          })
        }
        return ruleList
      } else {
        return []
      }
    },
    fetchSettings () {
      return {
        url: endpoints.rules,
        params: {}
      }
    }
  },
  watch: {
    fetchSettings () {
      this.clearSelectedData()
    }
  },
  methods: {
    onAppliedRuledChange (filter) {
      const rule = filter && filter.activeOptions[0] ? filter.activeOptions[0] : null
      if (rule && rule.id) {
        this.selectedAppliedRule = this.details.rules_outcomes[rule.id]
        this.versionData = this.details.rules_accepted[rule.id]

        this.selectedRuleDescription = this.selectedAppliedRule.rule_description
        this.codeExecutedStringified = this.versionData.code
        this.logStringified = this.selectedAppliedRule.log
        this.exceptionsStringified = this.selectedAppliedRule.exception

        this.ruleVersion = this.versionData.versionNum
        this.lastEditedBy = this.versionData.editedBy
        this.lastEditedOn = this.formatDateDetailedUI(this.versionData.editedOn)
        this.versionValidFrom = this.formatDateDetailedUI(this.versionData.validFrom)
        this.versionValidTo = this.formatDateDetailedUI(this.versionData.validTo)
        this.versionComment = this.versionData.comment
        this.childRules = this.selectedAppliedRule.reused_rules
        this.postReturn = this.selectedAppliedRule.post_return

        this.entitiesBefore = this.selectedAppliedRule.before_event
        this.entitiesSaved = this.selectedAppliedRule.save
        this.entitiesSavedFields = this.selectedAppliedRule.save_fields
        this.entitiesPatched = this.selectedAppliedRule.patch

        this.selectedRuleDescriptionStringified = 'ab'
        this.logMessagesFormatted = rule ? this.arrayToStringFormatter(rule.logMessages) : ''
        this.$emit('ruleSelected', this.selectedAppliedRule)
      } else {
        this.selectedAppliedRule = null
        this.$emit('ruleUnselected')
      }
    },
    onAppliedChildRuledChange (filter) {
      const rule = filter && filter.activeOptions[0] ? filter.activeOptions[0] : null
      if (rule && rule.id) {
        const version = this.fetchedData.filter(obj => {
          return obj.id === rule.id
        })[0].versions.filter(obj => {
          return obj.id === rule.version_id
        })[0]

        this.selectedAppliedChildRule = version
        this.selectedAppliedChildRuleDetails = {
          id: rule.rule_id,
          version_id: rule.version_id,
          domain_name: rule.domain_name,
          source_name: rule.source_name,
          event_type_name: rule.event_type_name,
          rule_name: rule.rule_name
        }
        this.$emit('childRuleSelected', this.selectedAppliedChildRule)
      } else {
        this.selectedAppliedChildRule = null
        this.$emit('childRuleUnselected')
      }
    }
  }
}
</script>
