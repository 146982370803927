<template>
  <div class="panel-section">
    <div class="px-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel'
}
</script>
