const viewMixin = {
  data () {
    return {
      searchStarted: false,
      activeFilters: null
    }
  },
  methods: {
    onFiltersChange () {
      this.searchStarted = false
    },
    onSubmitFilters (filters) {
      this.activeFilters = filters
      this.searchStarted = true
    }
  }
}

export default viewMixin
