<template>
  <Panel>
    <Heading class="text-center">Edit configuration</Heading>
    <hr class="mb-4">
    <div class="mr-4">
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Domain name</span>
        <CustomInput
          v-model.trim='domain.domain_name'
          name="Domain name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Sub domain name</span>
        <CustomInput
          v-model.trim='domain.subdomain_name'
          name="Sub domain name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Alias subdomain name</span>
        <CustomInput
          v-model.trim='domain.alias_subdomain_name'
          :name="'Alias subdomain name'"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Entity name</span>
        <CustomInput
          v-model.trim='domain.entity_name'
          name="Entity name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Self owned</span>
        <select v-model.trim="domain.self_owned">
          <option v-for="option in selfOwneds" :key="option.value" :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Access type</span>
        <select v-model.trim="domain.access_type">
          <option v-for="option in accessTypes" :key="option.value" :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Collection name</span>
        <CustomInput
          v-model.trim='domain.collection_name'
          name="Collection name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Primary key name</span>
        <CustomInput
          v-model.trim='domain.record_key'
          name="Primary key name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8 gap-y-4">
        <span
          class="block"
          :style="resourceIdHeight"
        >
          Resource id template
        </span>
        <div
          v-for="(_, id) in domain.resource_id_template"
          :key="id"
        >
          <div class="w-full flex">
            <CustomInput
              v-model.trim='domain.resource_id_template[id]'
              name="Domain resource id"
              :searchStyle="false"
              class="-mr-4"
            />
            <div class="flex flex-col relative top-0 left-5">
              <button
                class="h-4 w-4 border-t border-l border-r border-blue-900 text-blue-900 hover:bg-blue-900 hover:text-white text-xs text-center"
                @click="addItem(id)"
              >
                <span class="text-center block -mt-px">+</span>
              </button>
              <button
                class="h-4 w-4 border border-blue-900 text-blue-900 hover:bg-blue-900 hover:text-white text-xs text-center"
                @click="removeItem(id)"
              >
                <p class="text-center block -mt-1">-</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-5 mb-4 gap-x-8">
        <span class="block my-auto">Model</span>
        <CustomInput
          v-model.trim='domain.model'
          name="Model"
          :searchStyle="false"
        />
      </div>
      <div class="flex space-x-4 mb-4">
        <CustomButton
          class="rounded-md px-4 py-2"
          @click="saveChanges()"
        >
          Save changes
        </CustomButton>
        <CustomButton
          class="rounded-md px-4 py-2"
          @click="revertsValues()"
        >
          Cancel
        </CustomButton>
        <CustomButton
          class="rounded-md px-4"
          @click="exportSelectedDomain"
        >
          Export selected
        </CustomButton>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'

export default {
  name: 'EditFormStorage',
  components: {
    Panel,
    Heading,
    CustomInput,
    CustomButton
  },
  props: {
    activeItem: {
      type: Object
    }
  },
  emits: ['saveChanges', 'showPopup'],
  data () {
    return {
      domain: {
        domain_name: '',
        subdomain_name: '',
        alias_subdomain_name: '',
        entity_name: '',
        self_owned: true,
        access_type: 'our_single_document',
        collection_name: '',
        record_key: '',
        resource_id_template: [''],
        model: ''
      },
      selfOwneds: [
        { name: 'yes', value: true },
        { name: 'no', value: false }
      ],
      accessTypes: [
        { name: 'single document', value: 'our_single_document' },
        { name: 'separate document', value: 'our_separate_documents' }
      ]
    }
  },
  mounted () {
    this.checkData()
  },
  watch: {
    activeItem () {
      this.checkData()
    }
  },
  computed: {
    resourceIdHeight () {
      const height = Math.ceil(this.domain.resource_id_template.length / 4)
      return `grid-row: span ${height} / span ${height}`
    }
  },
  methods: {
    resetForm () {
      this.domain = {
        domain_name: '',
        subdomain_name: '',
        alias_subdomain_name: '',
        entity_name: '',
        self_owned: true,
        access_type: 'our_single_document',
        collection_name: '',
        record_key: '',
        resource_id_template: [''],
        model: ''
      }
    },
    checkData () {
      if (this.activeItem.id) {
        this.domain = { ...this.activeItem }
        this.domain.resource_id_template = [...this.activeItem.resource_id_template]
      } else {
        this.resetForm()
      }
    },
    addItem (id) {
      this.domain.resource_id_template.splice(id, 0, '')
    },
    removeItem (id) {
      if (this.domain.resource_id_template.length > 1) this.domain.resource_id_template.splice(id, 1)
    },
    saveChanges () {
      if (Object.values(this.domain).every(x => typeof x === 'string' ? !!x : true) && !this.domain.resource_id_template.includes('')) {
        this.$emit('saveChanges', this.domain)
      } else {
        this.$emit('showPopup')
      }
    },
    revertsValues () {
      this.checkData()
    },
    exportSelectedDomain () {
      const exportItem = { ...this.domain }
      delete exportItem.id
      const a = document.createElement('a')
      const file = new Blob([JSON.stringify(exportItem)], { type: 'text/plain' })
      a.href = URL.createObjectURL(file)
      a.download = `${exportItem.entity_name}.json`
      a.click()
    }
  }
}
</script>
