<template>
 <Panel>
    <div class="mb-4">
      <Heading class="text-center">Event details</Heading>
      <hr class="mb-4">
      <p>Source timestamp: {{sourceTimeStamp}}</p>
      <p>Processed on: {{processedOn}}</p>
    </div>
    <div class="flex -mx-2 mb-4">
      <div class="w-1/2 px-2">
        <Column>
          <div v-if="eventPayloadEnabled" class="mb-4">
            <heading>Attributes</heading>
            <CodeEditor :readonly="true"
                      :code="envelopeStringified"
                      :language="'json'"
                      :loading="loading"
                      :height="'h-56'" />
          </div>
        </Column>
      </div>
      <div class="w-1/2 px-2">
        <Column>
          <div v-if="envelopeEnabled" class="mb-4">
            <heading>Payload</heading>
            <CodeEditor :readonly="true"
                      :code="eventPayloadStringified"
                      :language="'json'"
                      :loading="loading"
                      :height="'h-56'"/>
          </div>
        </Column>
      </div>
    </div>
    <AppliedRuleDetails :details="details"
                        :loading="loading"
                        @ruleSelected="onRuleSelected"
                        @ruleUnselected="onRuleUnselected"/>
    <RejectedRuleDetails v-if="rulesRejectedEnabled && anyRejectedRules"
                        :details="details"
                        :loading="loading"/>
 </Panel>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import Column from '@/components/common/ui/Column.vue'
import AppliedRuleDetails from '@/components/common/AppliedRuleDetails.vue'
import RejectedRuleDetails from '@/components/common/RejectedRuleDetails.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'

export default {
  name: 'EventDetails',
  mixins: [fetchDataMixin, dateFormatterMixin, stringifyDataMixin],
  emits: ['ruleSelected', 'ruleUnselected'],
  components: {
    Panel,
    Heading,
    Column,
    AppliedRuleDetails,
    RejectedRuleDetails,
    CodeEditor
  },
  props: {
    fetchSettings: {
      type: Object,
      required: true
    },
    defaultDetails: {
      type: [Object, null],
      required: true
    },
    envelopeEnabled: {
      type: Boolean,
      required: true
    },
    eventPayloadEnabled: {
      type: Boolean,
      required: true
    },
    rulesRejectedEnabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fetchedData: {
        event_ts: '',
        processed_ts: '',
        payload: {},
        attributes: {},
        authorizationsBeforeEvent: [],
        explicitConsentsBeforeEvent: [],
        authorizationsAfterRulesApplied: [],
        consentsBeforeEvent: [],
        authorizationsAfterRuleApplied: null
      },
      selectedRejectedRule: null
    }
  },
  methods: {
    onRuleSelected (rule) {
      this.$emit('ruleSelected', rule)
    },
    onRuleUnselected () {
      this.$emit('ruleUnselected')
    }
  },
  computed: {
    details () {
      return this.defaultDetails ? this.defaultDetails : this.fetchedData
    },
    anyRejectedRules () {
      return this.details ? this.details.rules_rejected ? this.details.rules_rejected.length > 0 : false : false
    },
    sourceTimeStamp () {
      return this.defaultDetails && this.defaultDetails.event_ts
        ? this.formatDateDetailedUI(this.defaultDetails.event_ts)
        : (this.fetchedData ? this.formatDateDetailedUI(this.fetchedData.event_ts) : '')
    },
    processedOn () {
      return this.defaultDetails && this.defaultDetails.processed_ts
        ? this.formatDateDetailedUI(this.defaultDetails.processed_ts)
        : (this.fetchedData ? this.formatDateDetailedUI(this.fetchedData.processed_ts) : '')
    },
    producedEntityName () {
      return this.defaultDetails && this.defaultDetails.producedEntityName
        ? this.defaultDetails.producedEntityName
        : (this.fetchedData && this.fetchedData.producedEntityName ? this.fetchedData.producedEntityName : '')
    },
    sourceEntityName () {
      return this.defaultDetails && this.defaultDetails.sourceEntityName
        ? this.defaultDetails.sourceEntityName
        : (this.fetchedData && this.fetchedData.sourceEntityName ? this.fetchedData.sourceEntityName : '')
    },
    authorizationsBeforeEventStringified () {
      return this.defaultDetails &&
      this.defaultDetails.authorizationsBeforeEvent &&
      this.defaultDetails.authorizationsBeforeEvent.length > 0
        ? this.stringifyData(this.defaultDetails.authorizationsBeforeEvent)
        : (this.fetchedData &&
      this.fetchedData.authorizationsBeforeEvent &&
      this.fetchedData.authorizationsBeforeEvent.length > 0
          ? this.stringifyData(this.fetchedData.authorizationsBeforeEvent)
          : '')
    },
    appliedRuleDetailsData () {
      return this.defaultDetails && this.defaultDetails.rulesApplied
        ? this.defaultDetails.rulesApplied
        : (this.fetchedData && this.fetchedData.rulesApplied ? this.fetchedData.rulesApplied : [])
    },
    rejectedRuleDetailsData () {
      return this.defaultDetails && this.defaultDetails.rulesRejected
        ? this.defaultDetails.rulesRejected
        : (this.fetchedData && this.fetchedData.rulesRejected ? this.fetchedData.rulesRejected : [])
    },
    explicitConsentsBeforeEventStringified () {
      return this.defaultDetails &&
      this.defaultDetails.consentsBeforeEvent &&
      this.defaultDetails.consentsBeforeEvent.length > 0
        ? this.stringifyData(this.defaultDetails.consentsBeforeEvent)
        : (this.fetchedData &&
      this.fetchedData.consentsBeforeEvent &&
      this.fetchedData.consentsBeforeEvent.length > 0
          ? this.stringifyData(this.fetchedData.consentsBeforeEvent)
          : '')
    },
    eventPayloadStringified () {
      return this.defaultDetails && this.defaultDetails.payload
        ? this.stringifyData(this.defaultDetails.payload)
        : (this.fetchedData && this.fetchedData.payload
          ? this.stringifyData(this.fetchedData.payload)
          : '')
    },
    envelopeStringified () {
      return this.defaultDetails && this.defaultDetails.attributes
        ? this.stringifyData(this.defaultDetails.attributes)
        : (this.fetchedData && this.fetchedData.attributes
          ? this.stringifyData(this.fetchedData.attributes)
          : '')
    },
    authorizationsAfterAfterAllRulesAppliedStringified () {
      return this.defaultDetails &&
      this.defaultDetails.authorizationsAfterRulesApplied &&
      this.defaultDetails.authorizationsAfterRulesApplied.length > 0
        ? this.stringifyData(this.defaultDetails.authorizationsAfterRulesApplied)
        : (this.fetchedData &&
      this.fetchedData.authorizationsAfterRulesApplied &&
      this.fetchedData.authorizationsAfterRulesApplied.length > 0
          ? this.stringifyData(this.fetchedData.authorizationsAfterRulesApplied)
          : '')
    }
  }
}
</script>
