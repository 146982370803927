<template>
  <Filters :viewEditRulesView="false"
          :auditDataView="true"
          :auditIndividual="false"
          :filtersConfiguration="filtersConfiguration"
          :dateRangeEnabled="false"
          :effectiveDateEnabled="false"
          :addEditFilterEnabled="false"
          @filtersChange="onFiltersChange"
          @submitFilters="onSubmitFilters"/>
  <ItemsTable v-if="isItemsTableEnabled"
            :title="'Authorized users'"
            :searchFilter="true"
            :searchKey="'name'"
            :dateRangeFilter="false"
            :eventTypesFilter="false"
            :fetchSettings="authorizedUsersFetchSettings"
            :tableSettings="authorizedUsersTableSettings"
            :selectable="true"
            :multiSelect="true"
            :defaultItem="null"
            :dateDetailed="false"
            @selectItems="onSelectedUsers"
            ref="authorizedUsers"/>
  <RemoveAccess v-if="isRevekeAccessEnabled"
                :selectedAuthorizedUsers="selectedAuthorizedUsers"
                :activeFilters="activeFilters"
                @dataUpdated="onDataUpdated" />
</template>

<script>
import Filters from '@/components/common/filters/Filters.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import RemoveAccess from '@/components/reviewAccess/RemoveAccess.vue'
import viewMixin from '@/mixins/viewMixin.js'
import endpoints from '@/configs/endpoints'

export default {
  name: 'ReviewAccessView',
  mixins: [viewMixin],
  components: {
    Filters,
    ItemsTable,
    RemoveAccess
  },
  data () {
    return {
      filtersConfiguration: {
        domain: {
          required: true,
          multiselect: false
        },
        source: {
          required: true,
          multiselect: false
        },
        eventType: {
          required: true,
          multiselect: false
        }
      },
      authorizedUsersTableSettings: {
        columnTitles: ['Name', 'Domain', 'Source', 'Event Type', 'Filters', 'Scope'],
        columnKeys: ['name', ['domain', 'name'], ['source', 'name'], ['eventType', 'name'], 'filters', 'scopes']
      },
      selectedAuthorizedUsers: []
    }
  },
  computed: {
    isItemsTableEnabled () {
      return this.searchStarted
    },
    authorizedUsersFetchSettings () {
      const params = Object.assign({ manage_access: false }, this.activeFilters)

      return {
        url: endpoints.usersUI,
        params
      }
    },
    isRevekeAccessEnabled () {
      return this.searchStarted
    }
  },
  methods: {
    onSelectedUsers (users) {
      this.selectedAuthorizedUsers = users
    },
    onDataUpdated () {
      this.$refs.authorizedUsers.fetchData()
    }
  }
}
</script>
