<template>
  <div class="page-not-found flex items-center justify-center h-full">
    <h1 class="text-2xl">Page not found</h1>
  </div>
</template>

<script>

export default {
  name: 'PathNotFoundView'
}
</script>
