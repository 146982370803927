<template>
  <button @click="callback($event)"
          :disabled="disabled"
          class="btn-primary">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    callback (e) {
      this.$emit('clicked', e)
    }
  }
}
</script>
