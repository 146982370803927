<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperPopup"
      @keydown.esc="popuptEvent(!isChoicePopup)"
      :tabindex="0">
    <div class="popup">
      <p v-html="text" class="text-center mb-6"></p>
      <div class="flex items-center justify-center">
        <CustomButton v-if="isChoicePopup"
                      @clicked="popuptEvent(false)"
                      class="w-36 mx-2">
          {{cancelText}}
        </CustomButton>
        <CustomButton @clicked="popuptEvent(true)"
                      class="mx-2 w-36">
          {{confirmText}}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/common/custom/CustomButton.vue'

export default {
  name: 'Popup',
  components: {
    CustomButton
  },
  props: {
    isChoicePopup: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    cancelText: {
      type: String,
      required: false
    },
    confirmText: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.$refs.wrapperPopup.focus()
  },
  methods: {
    popuptEvent (event) {
      this.$emit('popupEvent', event)
    }
  }
}
</script>
