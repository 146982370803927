<template>
  <div v-if="auth0 && auth0.isLoading.value" class="page-layout">
    <Preloader :type="'full'"/>
  </div>
  <template v-else>
    <div v-if="isLoginPage">
      <router-view/>
    </div>
    <div v-else>
      <div class="navbar">
        <div class="flex">
          <div class="hamburger mt-0.5" @click="toggleMenu()" :class="{'active': menuActive}">
            <div class="hamburger-line"></div>
            <div class="hamburger-line"></div>
            <div class="hamburger-line"></div>
          </div>
          <div class="ml-4">
            {{ path }}
          </div>
        </div>
        <div class="hidden md:block">
          Data Enhancement Service
        </div>
        <div class="flex">
          <div class="mr-4">
            {{ env }}
          </div>
        <CustomButton @clicked="changeThemeMode">
          {{buttonThemeModeText}}
        </CustomButton>
        <div>
          <select v-model="selectedBackendApiURL"  @change="selectBackendApiURL()">
            <option v-for="option in backendApiURLs" :value="option" :key="option">{{option}}</option>
          </select>
        </div>
        </div>
      </div>
      <div v-if="menuActive" class="inset-0 flex loader fixed bg-black bg-opacity-5 z-10"></div>
      <Transition name="menu-slide">
        <Navigation v-if="menuActive" @linkClicked="onLinkClicked"/>
      </Transition>
      <div class="container mx-auto pt-16 pb-4">
        <router-view/>
      </div>
    </div>
    <Popup
      v-if="isError"
      :text="`Application error:<b> ${$store._state.data.error?.content}</b><br>Message: <b>${$store._state.data.error?.message}<br></b>Timestamp:<b>${new Date()}</b>`"
      :isChoicePopup="false"
      confirmText="ok"
      @popupEvent="$store.commit('closePopup')"
    />
  </template>
</template>

<script>
import Navigation from '@/components/navigation/Navigation.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import Popup from '@/components/common/ui/Popup.vue'
import appSettings from '@/configs/appSettings.js'
import { auth0Service } from '@/services/auth0Service.js'
import { onMounted } from 'vue'
import { userService } from '@/services/userService.js'

export default {
  components: {
    Navigation,
    CustomButton,
    Preloader,
    Popup
  },
  data () {
    return {
      themeDarkMode: false,
      menuActive: false,
      selectedBackendApiURL: appSettings.backendApiURL,
      backendApiURLs: appSettings.backendApiURLs
    }
  },
  mounted () {
    if (localStorage.selectedBackendApiURL) {
      this.selectedBackendApiURL = localStorage.selectedBackendApiURL
      appSettings.backendApiURL = localStorage.selectedBackendApiURL
    }
  },
  watch: {
    selectedBackendApiURL (newValue) {
      localStorage.selectedBackendApiURL = newValue
    }
  },
  setup () {
    let auth0 = false

    if (appSettings.auth0Active) {
      auth0 = auth0Service()
      onMounted(async () => {
        if (!auth0) {
          return
        }

        await auth0.createClient()
        await auth0.handleCallback()
        await userService.setUserInfo()
      })
    }

    return {
      auth0
    }
  },
  computed: {
    isLoginPage () {
      return this.$route.path === '/'
    },
    path () {
      const fullPath = this.$route.fullPath
      if (fullPath === '/view-edit-rules') {
        return 'View or edit rules'
      } else {
        const path = this.$route.fullPath.substring(1).replaceAll('-', ' ')
        return `${path[0].toUpperCase()}${path.slice(1)}`
      }
    },
    env () {
      return appSettings.environment
    },
    buttonThemeModeText () {
      return this.themeDarkMode ? 'Light mode' : 'Dark mode'
    },
    isError () {
      return this.$store?._state?.data?.error?.error
    }
  },
  methods: {
    changeThemeMode () {
      this.themeDarkMode = !this.themeDarkMode
      document.body.classList.toggle('dark-mode')
      this.$store.commit('changeThemeDarkMode', this.themeDarkMode)
    },
    toggleMenu () {
      this.menuActive = !this.menuActive
    },
    onLinkClicked () {
      this.menuActive = false
    },
    selectBackendApiURL () {
      appSettings.backendApiURL = this.selectedBackendApiURL
      this.$router.go(this.$router.currentRoute)
    }
  }
}
</script>
