<template>
  <div v-if="showHelp" class="flex w-full px-2 mb-4 place-content-end">
    <button
    @click="(showHelpPanel = !showHelpPanel)"
    v-bind:class="(showHelpPanel) ? 'btn-primary active' : 'btn-primary'">
      Show help
    </button>
  </div>
  <div class="flex h-full">
    <div class="h-full min-w-1/2 w-full px-2">
      <v-ace-editor
        :readonly="readonly"
        :value="code"
        :lang="language"
        :minLines="minLines"
        :theme="theme"
        @update:value="onUpdateValue"
        class="code-editor" :class="[heightClass]"
      />
      <div v-if="readonly" class="code-editor-overlay"></div>
      <Preloader v-if="loading" :type="'big'"/>
      <div class="cursor-pointer" @click="showPopup = true">
        <div class="zoom-container" />
      </div>
    </div>
    <div v-if="showHelpPanel" class="w-1/2 px-2">
      <div :class="[heightClass]">
        <RulesHelp />
      </div>
    </div>
    <div v-if="showPopup"
      class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperPopup"
      @keydown.esc="showPopup = false"
      :tabindex="0"
    >
      <div class="popup" style="max-width: 90%; height: 90%;">
        <div v-if="showHelp" class="flex w-full px-2 mb-4 place-content-end">
          <button
          @click="(showHelpPanel = !showHelpPanel)"
          v-bind:class="(showHelpPanel) ? 'btn-primary active' : 'btn-primary'">
            Show help
          </button>
        </div>
        <div class="flex w-full" style="height: 95%;">
          <div class="min-w-1/2 w-full px-2">
            <v-ace-editor
              :readonly="readonly"
              :value="code"
              :lang="language"
              :minLines="minLines"
              :theme="theme"
              @update:value="onUpdateValue"
              class="code-editor h-full"
            />
            <div class="cursor-pointer" @click="showPopup = false">
              <div class="unzoom-container" />
            </div>
          </div>
          <div v-if="showHelpPanel"
          class="w-1/2 px-2">
            <RulesHelp />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VAceEditor } from 'vue3-ace-editor'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/theme-one_dark'
import Preloader from '@/components/common/ui/Preloader.vue'
import RulesHelp from '@/components/common/RulesHelp.vue'

export default {
  name: 'CodeEditor',
  props: {
    readonly: {
      type: Boolean,
      required: true
    },
    code: {
      required: true
    },
    language: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean
    },
    height: {
      type: String,
      required: true
    },
    showHelp: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VAceEditor,
    Preloader,
    RulesHelp
  },
  data () {
    return {
      themeDarkMode: false,
      modelCode: '',
      minLines: 4,
      showPopup: false,
      showHelpPanel: false
    }
  },
  computed: {
    theme () {
      return this.$store.state.appTheme.themeDarkMode ? 'one_dark' : 'chrome'
    },
    heightClass () {
      return this.height
    }
  },
  methods: {
    onUpdateValue (code) {
      this.$emit('codeUpdated', code)
    }
  }
}
</script>
