<template>
    <Filters :viewEditRulesView="false"
            :auditDataView="true"
            :auditIndividual="false"
            :filtersConfiguration="filtersConfiguration"
            :dateRangeEnabled="false"
            :effectiveDateEnabled="false"
            :addEditFilterEnabled="false"
            @filtersChange="onFiltersChange"
            @submitFilters="onSubmitFilters"/>
    <ItemsList v-if="isAuthorizedUsersEnabled"
              :label="'Authorized users'"
              :fetchSettings="authorizedUsersFetchSettings"
              :itemListWithEmail="true"
               @selectedItemChange="onAutorizedUserChange"
               ref="authorizedUsers"/>
    <AddRemoveAcces v-if="isAddRemoveAccessEnabled"
                    :activeFilters="activeFilters"
                    :userEmail="selectedAuthorizedUserEmail"
                    :selectedAuthorizedUserEmail="selectedAuthorizedUserEmail"
                    @dataUpdated="onDataUpdated"/>
    <UserRoles v-if="isUserRolesEnabled"
              :autorizedUserId="selectedAuthorizedUserId"
              :activeFilters="activeFilters"
              @rolesUpdated="onRolesUpdated"
              @rolesModelUpdated="onRolesModelUpdated"
              ref="userRoles"/>
    <ItemsTable v-if="isEditableRulesEnabled"
              :title="'Rules that user can edit'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="false"
              :eventTypesFilter="false"
              :fetchSettings="editableRulesFetchSettings"
              :tableSettings="editableRulesItemsSettings"
              :selectable="false"
              :multiSelect="false"
              :defaultItem="null"
              :dateDetailed="false"
              ref="editableRules"/>
    <ItemsTable v-if="isViewableRulesEnabled"
              :title="'Rules that user can view'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="false"
              :eventTypesFilter="false"
              :fetchSettings="viewableRulesFetchSettings"
              :tableSettings="viewableRulesItemsSettings"
              :selectable="false"
              :multiSelect="false"
              :defaultItem="null"
              :dateDetailed="false"
              ref="viewableRules"/>
    <ItemsTable v-if="isAuditableRulesVisible"
              :title="'Event sources the user can audit'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="false"
              :eventTypesFilter="false"
              :fetchSettings="auditableRulesFetchSettings"
              :tableSettings="auditableRulesItemsSettings"
              :selectable="false"
              :multiSelect="false"
              :defaultItem="null"
              :dateDetailed="false"
              ref="auditableRules"/>
    <HandleChanges v-if="isHandleChangesEnabled"
                  :rolesUpdated="rolesUpdated"
                  :rolesModelUpdated="rolesModelUpdated"
                  :autorizedUserId="selectedAuthorizedUserId"
                  :activeFilters="activeFilters"
                  @cancelChanges="onCancelChanges"
                  @saveChanges="onSaveChanges"/>
</template>

<script>
import Filters from '@/components/common/filters/Filters.vue'
import ItemsList from '@/components/common/items/ItemsList.vue'
import UserRoles from '@/components/manageAccess/UserRoles.vue'
import AddRemoveAcces from '@/components/manageAccess/AddRemoveAcces.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import HandleChanges from '@/components/manageAccess/HandleChanges.vue'
import viewMixin from '@/mixins/viewMixin.js'
import endpoints from '@/configs/endpoints'

export default {
  name: 'ManageAccessView',
  mixins: [viewMixin],
  components: {
    Filters,
    ItemsList,
    UserRoles,
    AddRemoveAcces,
    ItemsTable,
    HandleChanges
  },
  data () {
    return {
      filtersConfiguration: {
        domain: {
          required: true,
          multiselect: false
        },
        source: {
          required: true,
          multiselect: false
        },
        eventType: {
          required: true,
          multiselect: false
        }
      },
      selectedAuthorizedUserId: null,
      selectedAuthorizedUserEmail: null,
      rolesUpdated: false,
      rolesModelUpdated: null,
      editableRulesItemsSettings: {
        columnTitles: ['Active from', 'Active to', 'Rule name'],
        columnKeys: ['activeFrom', 'activeTo', 'name']
      },
      viewableRulesItemsSettings: {
        columnTitles: ['Active from', 'Active to', 'Rule name'],
        columnKeys: ['activeFrom', 'activeTo', 'name']
      },
      auditableRulesItemsSettings: {
        columnTitles: ['Event channel', 'Source system', 'With filters applied'],
        columnKeys: ['eventChannel', 'sourceSystem', 'filtersApplied']
      }
    }
  },
  computed: {
    isAuthorizedUsersEnabled () {
      return this.searchStarted
    },
    authorizedUsersFetchSettings () {
      const params = Object.assign({ manage_access: true }, this.activeFilters)

      return {
        url: endpoints.usersUI,
        params
      }
    },
    isAddRemoveAccessEnabled () {
      return this.searchStarted
    },
    isUserRolesEnabled () {
      return this.selectedAuthorizedUserId
    },
    isEditableRulesEnabled () {
      return this.searchStarted
    },
    editableRulesFetchSettings () {
      return {
        url: endpoints.editableRulesUI,
        params: this.activeFilters
      }
    },
    isViewableRulesEnabled () {
      return this.searchStarted
    },
    viewableRulesFetchSettings () {
      return {
        url: endpoints.viewableRulesUI,
        params: this.activeFilters
      }
    },
    isAuditableRulesVisible () {
      return this.searchStarted
    },
    auditableRulesFetchSettings () {
      return {
        url: endpoints.auditableEventSourcesUI,
        params: this.activeFilters
      }
    },
    isHandleChangesEnabled () {
      return this.selectedAuthorizedUserId
    }
  },
  methods: {
    onAutorizedUserChange (user) {
      this.selectedAuthorizedUserId = user?.userId || null
      this.selectedAuthorizedUserEmail = user?.email || null
    },
    onRolesUpdated (event) {
      this.rolesUpdated = event
    },
    onRolesModelUpdated (event) {
      this.rolesModelUpdated = event
    },
    onDataUpdated () {
      this.selectedAuthorizedUserId = null
      this.selectedAuthorizedUserEmail = null
      this.$refs.authorizedUsers.fetchData()
    },
    onCancelChanges () {
      this.rolesUpdated = false
      this.$refs.userRoles.resetRolesModel()
    },
    onSaveChanges () {
      this.rolesUpdated = false
      this.$refs.userRoles.updateRolesModel()
      this.$refs.editableRules.fetchData()
      this.$refs.viewableRules.fetchData()
      this.$refs.auditableRules.fetchData()
    }
  }
}
</script>
