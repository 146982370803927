export default {
  environment: process.env.NODE_ENV,
  backendApiURLs: JSON.parse(process.env.VUE_APP_BACKEND_API_URLS),
  backendApiURL: JSON.parse(process.env.VUE_APP_BACKEND_API_URLS)[0],
  axiosHeaders: { 'x-api-key': '1234' },
  auth0Active: process.env.VUE_APP_AUTH0_ACTIVE === 'true',
  dateFormatUI: 'YYYY-MM-DD',
  dateFormatDetailedUI: 'YYYY-MM-DD HH:mm:ss zz',
  dateFromatBE: 'YYYY-MM-DDTHH:mm:ss',
  messages: {
    error: 'An error occured. Please contact the administrator.'
  }
}
