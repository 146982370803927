<template>
  <App />
</template>

<script>
import App from '@/App.vue'
import appSettings from '@/configs/appSettings.js'
import { provideAuth0 } from './services/auth0Service.js'

export default {
  name: 'Shell',
  components: { App },
  created () {
    if (appSettings.auth0Active) {
      provideAuth0()
    }
  }
}
</script>
