<template>
  <select v-show="!loading" @change="onRuleVersionStatusChange" class="w-16 px-1">
    <option v-for="option in options"
              :key="option"
              :value="option.value"
              :selected="option.value == versionActive">
              {{option.label}}
    </option>
  </select>
  <Preloader v-if="loading" :type="'small'"/>
</template>

<script>
import endpoints from '@/configs/endpoints'
import Preloader from '@/components/common/ui/Preloader.vue'
import requestDataMixin from '@/mixins/requestDataMixin.js'

export default {
  name: 'RuleVersionStatus',
  mixins: [requestDataMixin],
  emits: ['versionUpdate'],
  components: {
    Preloader
  },
  props: {
    versionActive: {
      type: Boolean,
      required: true
    },
    ruleId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
    }
  },
  methods: {
    async onRuleVersionStatusChange (e) {
      const url = endpoints.rules + '/' + this.ruleId + '/versions/' + this.versionId
      const data = { active: e.target.value }
      const [error] = await this.requestData('patch', url, {}, data, 'loading')

      if (error) {
        this.error = true
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.error = false
        this.$emit('versionUpdate')
      }
    }
  }
}
</script>
