<template>
  <svg width="100%" :height="chartHeight" ref="gantt" :id="chartId" :style="chartStyle">
    <g v-for="(point, idx) in this.checkpoints" v-bind:key="point.name" :id="chartId + 'Bar' + idx">
      <text :x="labelX" :y="labelY(idx)" :fill="labelColor(idx)" dominant-baseline="hanging" text-anchor="end"
        :font-family="labelFontFamily" :font-size="labelFontSize" :style="labelStyle">
        {{point.name}}
      </text>
      <rect :x="barLineX(idx)" :y="barLineY(idx)" :fill="barLineColor"
        :width="barLineWidth" :height="barLineHeight" />
      <rect :x="barX(idx)" :y="barY(idx)" :fill="barColor(idx)"
        :width="barWidth(idx)" :height="barHeight" :style="barStyle" />
      <text :x="barLabelX" :y="barLabelY(idx)" :fill="labelColor(idx)" dominant-baseline="hanging"
        :font-family="BarLabelFontFamily" :font-size="barLabelFontSize" :style="barLabelStyle">
        {{point.duration}} ms
      </text>
    </g>
    <g id="'total'">
      <text :x="totalLabelX" :y="totalLabelY(idx)" dominant-baseline="hanging" text-anchor="end"
        :font-family="labelFontFamily" :font-size="labelFontSize" :style="labelStyle">
        Total: {{(duration)}} ms
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GanttChart',
  props: {
    data: {
      type: Array,
      required: true
    },
    // chart
    chartId: {
      type: String,
      default: 'gantt'
    },
    chartBorderSize: {
      type: Number,
      default: 1
    },
    chartBorderColor: {
      type: String,
      default: '#9ca3af'
    },
    chartPadding: {
      type: Number,
      default: 16
    },
    chartBackground: {
      type: String,
      default: '#ffffff'
    },
    // legend
    legendWidth: {
      type: Number,
      default: 150
    },
    legendOffset: {
      type: Number,
      default: 10
    },
    // labels
    labelFontSize: {
      type: Number,
      default: 12
    },
    labelFontFamily: {
      type: String,
      default: 'Arial, Helvetica, sans-serif'
    },
    labelDefaultColor: {
      type: String,
      default: '#000000'
    },
    labelHighlight1Color: {
      type: String,
      default: '#999900'
    },
    labelHighlight2Color: {
      type: String,
      default: '#990000'
    },
    // bar
    barOffset: {
      type: Number,
      default: 24
    },
    barHeight: {
      type: Number,
      default: 8
    },
    barBorderSize: {
      type: Number,
      default: 0.3
    },
    barBorderColor: {
      type: String,
      default: '#9ca3af'
    },
    barDefaultColor: {
      type: String,
      default: '#ccffcc'
    },
    barHighlight1Color: {
      type: String,
      default: '#ffffcc'
    },
    barHighlight2Color: {
      type: String,
      default: '#ffcccc'
    },
    barLineHeight: {
      type: Number,
      default: 1
    },
    barLineColor: {
      type: String,
      default: '#999999'
    },
    barLabelWidth: {
      type: Number,
      default: 55
    },
    barLabelOffset: {
      type: Number,
      default: 5
    },
    barLabelFontSize: {
      type: Number,
      default: 10
    },
    barLabelFontFamily: {
      type: String,
      default: 'Arial, Helvetica, sans-serif'
    },
    barLabelDefaultColor: {
      type: String,
      default: '#000000'
    }
  },
  data () {
    return {
      chartWidth: 0,
      chartHeight: 0,
      checkpoints: [],
      chartStart: 0,
      chartEnd: 0,
      duration: 0
    }
  },
  mounted () {
    this.parseData()
  },
  computed: {
    // chart
    chartStyle () {
      var style = []
      style.push('border-width:' + this.chartBorderSize + 'px')
      style.push('border-color:' + this.chartBorderColor)
      style.push('background:' + this.chartBackground)
      return style.join(';')
    },
    // labels
    labelStyle () {
      var style = []
      style.push('text-align: right')
      return style.join(';')
    },
    labelX () {
      return parseInt(this.legendWidth)
    },
    // bars
    barCanvasWidth () {
      return this.chartWidth - parseInt(this.chartPadding) - parseInt(this.legendWidth) - parseInt(this.legendOffset) - parseInt(this.barLabelWidth) - parseInt(this.barLabelOffset)
    },
    barLineWidth () {
      return this.barCanvasWidth
    },
    barStyle () {
      var style = []
      style.push('stroke-width:' + this.barBorderSize)
      style.push('stroke:' + this.barBorderColor)
      style.push('background:' + this.barColor)
      return style.join(';')
    },
    barLabelX () {
      return parseInt(this.legendWidth) + parseInt(this.legendOffset) + this.barCanvasWidth + parseInt(this.barLabelOffset)
    },
    totalLabelX () {
      return parseInt(this.legendWidth) + parseInt(this.legendOffset) + this.barCanvasWidth + parseInt(this.barLabelWidth) + parseInt(this.barLabelOffset)
    }
  },
  methods: {
    parseData () {
      this.checkpoints = this.data.checkpoints
      this.chartStart = this.data.total.start
      this.chartEnd = this.data.total.end
      this.duration = this.data.total.duration
      this.calculateChartWidth()
      this.calculateChartHeight()
    },
    // chart
    calculateChartWidth () {
      this.chartWidth = this.$refs.gantt.clientWidth
    },
    calculateChartHeight () {
      this.chartHeight = this.labelY(this.checkpoints.length) + parseInt(this.chartPadding)
    },
    // labels
    labelY (index) {
      var y = parseInt(this.labelFontSize)
      if (parseInt(this.barHeight) > parseInt(this.labelFontSize)) {
        y = parseInt(this.barHeight)
      }
      return parseInt(this.chartPadding) + (y + parseInt(this.barOffset)) * index
    },
    labelColor (index) {
      var color = this.labelDefaultColor
      if (this.checkpoints[index].duration >= this.checkpoints[index].l1) {
        color = this.labelHighlight1Color
      }
      if (this.checkpoints[index].duration >= this.checkpoints[index].l2) {
        color = this.labelHighlight2Color
      }
      return color
    },
    totalLabelY () {
      return this.labelY(this.checkpoints.length)
    },
    // bars
    barX (index) {
      // include legend offset
      var x = parseInt(this.legendWidth) + parseInt(this.legendOffset)
      // add chart starting point offset
      x += this.barCanvasWidth * ((this.checkpoints[index].start - this.chartStart) / this.duration)
      return Math.round(x, 0)
    },
    barY (index) {
      var y = 0
      if (parseInt(this.labelFontSize) > parseInt(this.barHeight)) {
        y = parseInt(this.labelFontSize)
      }
      return parseInt(this.chartPadding) + (y + parseInt(this.barOffset)) * index
    },
    barWidth (index) {
      var length = this.barCanvasWidth * (this.checkpoints[index].duration) / this.duration
      return length
    },
    barColor (index) {
      var color = this.barDefaultColor
      if (this.checkpoints[index].duration >= this.checkpoints[index].l1) {
        color = this.barHighlight1Color
      }
      if (this.checkpoints[index].duration >= this.checkpoints[index].l2) {
        color = this.barHighlight2Color
      }
      return color
    },
    barLineX (index) {
      return parseInt(this.legendWidth) + parseInt(this.legendOffset)
    },
    barLineY (index) {
      return this.barY(index) + parseInt(this.barHeight) / 2
    },
    barLabelY (index) {
      return this.labelY(index)
    }
  }
}
</script>
