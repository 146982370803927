const error = {
  state: {
    error: false,
    content: ''
  },
  mutations: {
    catchError (state, data) {
      state.content = data
      state.error = true
      if (data === 501) {
        state.message = 'Index for this operation is missing. Please see in backend logs for informations on missing firestore indexes.'
      }
    },
    closePopup (state) {
      state.error = false
      state.content = ''
    }
  }
}
export default error
