export default {
  domainsUI: '/v1/ui/filters/domains',
  domains: '/v1/domains',
  sourcesUI: '/v1/ui/filters/sources',
  sources: '/v1/sources',
  eventTypesUI: '/v1/ui/filters/eventTypes',
  eventTypes: '/v1/eventTypes',
  filtersUI: '/v1/ui/filters',
  filters: '/v1/filters',
  filterTest: '/v1/filters/test',
  filterEventTypesUI: '/v1/ui/filterEventTypes',
  individuals: '/v1/individuals',
  eventsUI: '/v1/ui/events',
  eventDetailsUI: '/v1/ui/events',
  rulesUI: '/v1/ui/rules',
  rules: '/v1/rules',
  rulesImportUI: '/v1/ui/rules-import',
  rulesForAuditUI: '/v1/ui/rulesForAudit',
  ruleUI: '/v1/ui/rule',
  authorizedUsersUI: '/v1/ui/users/authorized',
  usersUI: '/v1/ui/users',
  authUsers: '/auth/users',
  authMe: '/auth/me',
  auditableEventSourcesUI: '/v1/ui/auditableEventSources',
  editableRulesUI: '/v1/ui/editableRules',
  viewableRulesUI: '/v1/ui/viewableRules',
  userActivities: '/v1/audit/userActivities',
  individualTypes: '/v1/individualTypes',
  dataSources: '/v1/dataSources',
  dataSourceTypes: '/v1/dataSourceTypes',
  dataPickerEvents: '/v1/ui/events',
  models: '/v1/models',
  testNewRule: '/v1/tests',
  storageConfigResource: '/v1/storage-config',
  storageConfig: '/v1/storage-config',
  notificationsConfig: '/v1/notifications-config',
  rulesEngineHelp: '/v1/rules_engine/docs',
  entityTypesUI: '/v1/ui/filters/storageConfigurations'
}
