<template>
  <Panel>
    <Column>
      <Heading class="text-center">{{title}}</Heading>
      <hr class="mb-2"/>
      <div class="flex mb-4 -mx-2">
        <div v-if="searchFilter" class="px-2 mt-2">
          <CustomInput v-model='search'
                :name="search"
                :placeholder="'search'"
                :searchStyle="true"/>
        </div>
        <div v-if="dateRangeFilter" class="flex w-1/2">
          <div class="w-1/2 px-2">
            <Datepicker v-model="rangeDateFrom"
                        :maxDate="rangeDateTo"
                        :enableTimePicker="false"
                        placeholder="Valid from"
                        class="w-full mt-2" />
          </div>
          <div class="w-1/2 px-2">
            <Datepicker v-model="rangeDateTo"
                        :minDate="rangeDateFrom"
                        :enableTimePicker="false"
                        placeholder="Valid to"
                        class="w-full mt-2" />
          </div>
        </div>
      </div>
      <div class="relative flex flex-col min-h-48">
        <table class="w-full items-table">
          <thead>
            <tr class="border-b border-gray-200">
              <td v-for="title in tableSettings.columnTitles" :key="title" class="pb-2">{{title}}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in itemsFiltered"
              :key="item"
              @click="selectable ? selectItem(item) : ''"
              :class="{active: multiSelect ? selectedItems.find(elem => elem.id === item.id) : selectedItem ? selectedItem.id === item.id : false}"
            >
                <td v-for="key in tableSettings.columnKeys"
                  :key="key"
                  class="py-2 cursor-pointer"
                  v-html="itemKeys(item, key)">
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="noData || error" class="absolute inset-0 flex justify-center items-center">
          No data found
        </p>
        <Preloader v-if="loading" :type="'big'"/>
        <v-pagination
          v-if="isPaginationVisible"
          v-model="paginationPage"
          :pages="paginationPages"
          :range-size="paginationRangeSize"
          active-color="#DCEDFF"
          @update:modelValue="updatePagination"
          class="justify-center py-2 mt-auto"
        />
      </div>
    </Column>
  </Panel>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import Column from '@/components/common/ui/Column.vue'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Preloader from '@/components/common/ui/Preloader.vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import moment from 'moment'

export default {
  name: 'ItemsTable',
  mixins: [fetchDataMixin, dateFormatterMixin],
  components: {
    Panel,
    Heading,
    Column,
    CustomInput,
    Datepicker,
    Preloader,
    VPagination
  },
  props: {
    title: {
      type: String,
      required: true
    },
    searchFilter: {
      type: Boolean,
      requied: true
    },
    searchKey: {
      type: String,
      required: false
    },
    dateRangeFilter: {
      type: Boolean,
      required: true
    },
    eventTypesFilter: {
      type: Boolean,
      required: true
    },
    fetchSettings: {
      type: Object,
      required: true
    },
    defaultItems: {
      type: Array,
      required: false
    },
    tableSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    defaultItem: {
      type: [Number, null],
      required: true
    },
    dateDetailed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      search: '',
      rangeDateFrom: null,
      rangeDateTo: null,
      selectedEventType: null,
      selectedItem: {},
      selectedItems: [],
      paginationPage: 1,
      paginationPageSize: 4,
      paginationRangeSize: 1
    }
  },
  computed: {
    paginationPages () {
      return Math.ceil(this.itemsOptionsFiltered.length / this.paginationPageSize)
    },
    items () {
      return this.defaultItems ? this.defaultItems : this.fetchedData ? this.fetchedData : []
    },
    itemsOptionsFiltered () {
      this.items.map((item) => {
        for (const property in item) {
          if (moment(item[property], moment.ISO_8601, true).isValid()) {
            item[property] = this.dateDetailed ? this.formatDateDetailedUI(item[property]) : this.formatDateUI(item[property])
          }

          if (property !== 'filters' && Array.isArray(item[property])) {
            let string = ''
            item[property].forEach(item => {
              string += item + ' '
            })

            item[property] = string
          }
        }
      })

      let filteredItems = this.items

      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => {
          return item[this.searchKey].toLowerCase().includes(this.search.toLowerCase())
        })
      }

      if (this.rangeDateFrom !== null) {
        filteredItems = filteredItems.filter(item => {
          return new Date(this.formatDateUI(item.eventDate)) >= new Date(this.formatDateUI(this.rangeDateFrom))
        })
      }

      if (this.rangeDateTo !== null) {
        filteredItems = filteredItems.filter(item => {
          return new Date(this.formatDateUI(item.eventDate)) <= new Date(this.formatDateUI(this.rangeDateTo))
        })
      }

      if (this.selectedEventType !== null && this.selectedEventType !== '') {
        filteredItems = filteredItems.filter(item => {
          return item.eventType === this.selectedEventType
        })
      }
      return filteredItems
    },
    itemsPaginationFiltered () {
      return this.itemsOptionsFiltered.slice((this.paginationPage - 1) * this.paginationPageSize, this.paginationPage * this.paginationPageSize)
    },
    itemsFiltered () {
      if (this.itemsOptionsFiltered.length <= this.paginationPageSize) {
        this.updatePagination(1)
      }

      return this.itemsPaginationFiltered
    },
    noData () {
      return !this.itemsFiltered.length && !this.loading
    },
    isPaginationVisible () {
      return this.itemsOptionsFiltered.length > this.paginationPageSize
    }
  },
  watch: {
    fetchSettings () {
      this.selectedItem = null
    }
  },
  mounted () {
    if (this.defaultItem) {
      this.selectedItem.id = this.defaultItem
    }
  },
  methods: {
    selectItem (item) {
      if (this.multiSelect) {
        if (this.selectedItems.find(elem => elem.id === item.id)) {
          this.selectedItems = this.selectedItems.filter(elem => elem.id !== item.id)
        } else {
          this.selectedItems.push(item)
        }

        this.$emit('selectedItems', this.selectedItems)
      } else {
        if (this.selectedItem && this.selectedItem.id === item.id) {
          this.selectedItem = null
        } else {
          this.selectedItem = item
        }

        this.$emit('selectItems', this.selectedItem)
      }
    },
    updatePagination (page) {
      this.paginationPage = page
    },
    onSelectedEventType (eventType) {
      this.selectedEventType = eventType
    },
    itemKeys (item, keys) {
      let result = ''
      if (Array.isArray(keys)) {
        result = item[keys[0]][keys[1]]
      } else {
        result = item[keys]
      }

      return result
    }
  },
  beforeUnmount () {
    this.selectedItemId = null
  }
}
</script>
