<template>
  <Column class="flex flex-col max-h-70">
    <Heading>{{label}}</Heading>
    <hr class="mb-4" />
    <CustomInput v-model='search'
          :name="search"
          :placeholder="'search'"
          :searchStyle="true"
          class="mb-2 max-w-sm"/>
    <div class="relative flex-grow max-h-48 overflow-auto">
      <ul v-if="filteredOptionsVisible" class="flex flex-wrap pr-2">
        <li class="inline-flex mr-2" v-for="option in filteredOptions" :key="option">
            <button @click="selectOption(option)"
                    :class="{active: selectedOptions.find(item => item[this.id] === option[this.id])}"
                    class="btn-choice">
              <template v-if="itemListWithEmail">
                {{option.name}} ({{option.email}})
              </template>
              <template v-else>
                {{option.name}}
              </template>
            </button>
        </li>
      </ul>
      <div v-if="noData || error" class="flex justify-center items-center w-full h-full p-2">
        <p class="text-center">No data found</p>
      </div>
      <Preloader v-if="dataLoading" :type="'big'"/>
    </div>
  </Column>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import Column from '@/components/common/ui/Column.vue'
import Heading from '@/components/common/ui/Heading.vue'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import Preloader from '@/components/common/ui/Preloader.vue'

export default {
  name: 'Options',
  mixins: [fetchDataMixin],
  components: {
    Column,
    Heading,
    CustomInput,
    Preloader
  },
  props: {
    label: {
      type: String,
      required: true
    },
    fetchSettings: {
      type: [Object, null],
      required: true
    },
    options: {
      type: Array,
      required: false
    },
    multiselect: {
      type: Boolean,
      required: true
    },
    selectedItems: {
      type: Array,
      required: true
    },
    optionType: {
      type: String,
      required: true
    },
    itemListWithEmail: {
      type: Boolean,
      required: false
    },
    parentLoading: {
      type: Boolean,
      required: false
    },
    disableOnlyChildPreselect: {
      // if true, and the list has only one item, it's pre-selected
      type: Boolean,
      required: false
    },
    ruleAdded: {
      type: Boolean,
      default: false
    },
    idField: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      search: '',
      selectedOptions: [],
      allOptions: []
    }
  },
  created () {
    if (this.selectedItems && this.selectedItems.length > 0) {
      this.selectedOptions = this.selectedItems
    }

    if (this.options && this.options.length) {
      this.allOptions = this.options
    }
  },
  computed: {
    fetchedOptions () {
      return this.fetchedData || []
    },
    dataLoading () {
      return this.parentLoading || this.loading
    },
    noData () {
      return !this.filteredOptions.length && (this.parentLoading ? !this.parentLoading : !this.loading)
    },
    filteredOptionsVisible () {
      return this.filteredOptions.length
    },
    mergedOptions () {
      return this.options ? [...this.fetchedOptions, ...this.options] : this.fetchedOptions
    },
    filteredOptions () {
      return this.allOptions.filter(option => {
        return option.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    id () {
      return this.idField || 'id'
    }
  },
  watch: {
    fetchSettings () {
      this.clearSelectedData()
      this.selectedOptions = []
    },
    selectedItems (items) {
      if (items && items.length > 0) {
        this.selectedOptions = items
      }
    },
    mergedOptions (options) {
      if (!this.disableOnlyChildPreselect && options.length === 1) {
        this.selectedOptions = [options[0]]
        this.$emit('optionChange', { type: this.optionType, activeOptions: this.selectedOptions })
      }
      this.allOptions = options
    },
    ruleAdded (val) {
      if (val) {
        this.selectOption(this.allOptions[this.allOptions.length - 1])
        this.$emit('ruleAdded')
      }
    }
  },
  methods: {
    selectOption (option) {
      if (this.multiselect) {
        if (this.selectedOptions.find(item => item[this.id] === option[this.id])) {
          this.selectedOptions = this.selectedOptions.filter(item => item[this.id] !== option[this.id])
        } else {
          if (option.type === 'date') {
            this.selectedOptions = this.selectedOptions.filter(item => item.type !== 'date')
          }
          this.selectedOptions.push(option)
        }
      } else {
        if (this.selectedOptions.find(item => item[this.id] === option[this.id])) {
          this.selectedOptions = []
        } else {
          this.selectedOptions = []
          this.selectedOptions.push(option)
        }
      }

      this.$emit('optionChange', { type: this.optionType, activeOptions: this.selectedOptions })
    },
    clearSelectedData () {
      this.$emit('optionChange', { type: this.optionType, activeOptions: [] })
    },
    editOption (option) {
      this.allOptions = this.allOptions.map(item =>
        item[this.id] === option[this.id] ? option : item)
    }
  },
  beforeUnmount () {
    this.clearSelectedData()
  }
}
</script>
