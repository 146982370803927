<template>
  <div v-if="optionsVisible">
    <div class="flex items-center w-1/3">
      <label class="ml-auto whitespace-nowrap pr-8">{{label}}</label>
      <select name="models" @change="onChangeOption">
        <option v-if="allowNoChoice" value="">{{labelNoChoice}}</option>
        <option v-for="(option, index) in allOptions"
          :key="index"
          :value="index"
          :selected="option.name == selectedOption">
          {{option.name}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'

export default {
  name: 'DropdownOptions',
  emits: ['optionChange'],
  mixins: [fetchDataMixin],
  props: {
    label: {
      type: String,
      required: true
    },
    fetchSettings: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: false
    },
    selectedItem: {
      type: [null, String],
      required: true
    },
    disableOnlyChildPreselect: {
      type: Boolean,
      required: false
    },
    allowNoChoice: {
      type: Boolean,
      required: false,
      default: true
    },
    labelNoChoice: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      selectedOption: null,
      allOptions: []
    }
  },
  created () {
    if (this.selectedItem) {
      this.selectedOption = this.selectedItem
    }

    if (this.options && this.options.length) {
      this.allOptions = this.options
    }
  },
  computed: {
    fetchedOptions () {
      return this.fetchedData || []
    },
    dataLoading () {
      return this.parentLoading || this.loading
    },
    noData () {
      return !this.allOptions.length && (this.parentLoading ? !this.parentLoading : !this.loading)
    },
    mergedOptions () {
      return this.options ? [...this.fetchedOptions, ...this.options] : this.fetchedOptions
    },
    optionsVisible () {
      return this.allOptions && this.allOptions.length > 0
    }
  },
  watch: {
    fetchSettings () {
      this.clearSelectedData()
      this.selectedOption = null
    },
    mergedOptions (options) {
      if (!this.disableOnlyChildPreselect && this.mergedOptions.length === 1) {
        this.selectedOption = this.mergedOptions[0]
        this.$emit('optionChange', this.mergedOptions[0])
      }
      this.allOptions = options
    }
  },
  methods: {
    onChangeOption (event) {
      this.$emit('optionChange', this.mergedOptions[event.target.value])
    }
  }
}
</script>
