<template>
  <Panel id="testRule">
      <Heading class="text-center">Test the rule on a historic event</Heading>
      <hr class="mb-4">
      <div class="mb-4 mr-4">
        <CustomButton @clicked="selectFromHistory"
                class="ml-auto mr-4">
          Select from history
        </CustomButton>
        <CustomButton @clicked="pickFromHistory"
                class="ml-auto mr-4">
          Auto pick from history
        </CustomButton>
        <span class="ml-3.5">...or enter manually:</span>
      </div>

      <div class="w-full px-2">
        <Column>
            <heading @click="(showAttributes = !showAttributes)" class="cursor-pointer">
              <span>{{(showAttributes ? '&#9660;' : '&#9654;')}}</span>
              Attributes
            </heading>
            <CodeEditor
              v-if="showAttributes"
              :readonly="false"
              :code="attributes"
              :language="'json'"
              :height="'h-64'"
              @codeUpdated="onAttributesUpdated"/>
            <p v-if="eventAttributesError" class="absolute text-red-500 text-xs">{{eventAttributesError}}</p>
        </Column>
      </div>
      <div class="w-full px-2">
        <Column>
            <heading @click="(showPayload = !showPayload)" class="cursor-pointer">
              <span>{{(showPayload ? '&#9660;' : '&#9654;')}}</span>
              Payload
            </heading>
            <CodeEditor
              v-if="showPayload"
              :readonly="false"
              :code="payload"
              :language="'json'"
              :height="'h-64'"
              @codeUpdated="onPayloadUpdated"/>
            <p v-if="eventPayloadError" class="absolute text-red-500 text-xs">{{eventPayloadError}}</p>
        </Column>
      </div>

      <hr class="mb-4">
      <PriorData :data="priorData"
                 @dataChanged="onPriorDataChanged"
                 @modelChanged="onPriorDataModelChanged"
                 @resourceIdChanged="onPriorDataResourceIdChanged"
                 @modelUnselected="onPriorDataModelUnselected"/>

      <div class="flex items-center w-1/3">
        <label class="ml-auto whitespace-nowrap pr-8">Compare with prior version</label>
        <select v-model="selectedTestPriorVersion" name="priorVersions" class="min-w-full">
          <option value=""></option>
          <option v-for="version in priorVersions" :key="version" :value="version.id">{{version.versionNum}}</option>
        </select>
      </div>

      <div class="mt-3" id="testRuleBtn">
        <CustomButton @clicked="executeRule('test', false)"
                :disabled="executeRuleDisabled"
                class="px-10">
          Test rule
        </CustomButton>
      </div>

      <div>
        <h1>Outcome</h1>
        <div v-if="anyFilterFailed">
          <p class="bg-red-300 font-bold text-lg">Those filters have returned a non-true result, which prevented the execution of the rule:</p>
            <div v-for="(item, index) in filterOutcomes" :key="'filterOutcomes-'+index">
              <div v-if="!item.outcome">
                <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                  <pre>Filter name: {{item.name}}</pre>
                  <pre>Outcome: {{item.outcome}}</pre>
                  <hr>
                  <pre>{{item.stringifiedCode}}</pre>
                  <hr>
                  <div v-if="item.exception" class="bg-red-300">
                    <p>Exception raised:</p>
                    <pre>{{item.exception}}</pre>
                  </div>
                  <div v-if="item.log">
                    <p>Log produced:</p>
                    <pre>{{item.log}}</pre>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div v-else>
          <div v-if="comparisonMode">
            <div v-for="(item, index) in entitiesSavedDiffPairs" :key="'entitiesSavedDiffPairs-'+index">
              <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                <div v-if="item[0].stringified && item[1].stringified">
                  <p><span class="font-bold text-lg">Present in both versions</span><pre>{{item[0].domain_name}}/{{item[0].domain_document_id}}/{{item[0].subdomain_name}}/{{item[0].sub_domain_document_id}}</pre></p>
                  <div v-if="item[0].stringified === item[1].stringified">
                    <p class="font-bold text-lg">Both versions generated identical data</p>
                    <CustomPre :content="item[0].stringified"/>
                  </div>
                  <div v-else>
                    <p class="bg-red-300 font-bold text-lg">There are differences between the versions</p>
                    <Diff
                      :mode="'split'"
                      :theme="'light'"
                      :language="'json'"
                      :prev="item[0].stringified"
                      :current="item[1].stringified"
                    />
                  </div>
                </div>
                <div v-if="item[0].domain_name && !item[1].domain_name">
                  <p class="bg-green-300"><span class="font-bold text-lg">Present in new version only</span><pre>{{item[0].domain_name}}/{{item[0].domain_document_id}}/{{item[0].subdomain_name}}/{{item[0].sub_domain_document_id}}</pre></p>
                  <CustomPre :heading="'Log output'"
                              :content="item[0].stringified"/>
                </div>
                <div v-if="item[1].domain_name && !item[0].domain_name">
                  <p class="bg-red-300"><span class="font-bold text-lg">Present in prior version only</span><pre>{{item[1].domain_name}}/{{item[1].domain_document_id}}/{{item[1].subdomain_name}}/{{item[1].sub_domain_document_id}}</pre></p>
                  <CustomPre :heading="'Log output'"
                              :content="item[1].stringified"/>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in entitiesSavedFieldsDiffPairs" :key="'entitiesSavedFieldsDiffPairs-'+index">
              <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                <div v-if="item[0].stringified && item[1].stringified">
                  <p><span class="font-bold text-lg">Present in both versions</span><pre>{{item[0].domain_name}}/{{item[0].domain_document_id}}/{{item[0].subdomain_name}}/{{item[0].sub_domain_document_id}}</pre>Field id: <pre>{{item[0].field_name}}</pre></p>
                  <div v-if="item[0].stringified === item[1].stringified">
                    <p class="font-bold text-lg">Both versions generated identical data</p>
                    <CustomPre :content="item[0].stringified"/>
                  </div>
                  <div v-else>
                    <p class="bg-red-300 font-bold text-lg">There are differences between the versions</p>
                    <Diff
                      :mode="'split'"
                      :theme="'light'"
                      :language="'json'"
                      :prev="item[0].stringified"
                      :current="item[1].stringified"
                    />
                  </div>
                </div>
                <div v-if="item[0].domain_name && !item[1].domain_name">
                  <p class="bg-green-300"><span class="font-bold text-lg">Present in new version only</span><pre>{{item[0].domain_name}}/{{item[0].domain_document_id}}/{{item[0].subdomain_name}}/{{item[0].sub_domain_document_id}}</pre>Field id: <pre>{{item[0].field_name}}</pre></p>
                  <CustomPre :content="item[0].stringified"/>
                </div>
                <div v-if="item[1].domain_name && !item[0].domain_name">
                  <p class="bg-red-300"><span class="font-bold text-lg">Present in prior version only</span><pre>{{item[1].domain_name}}/{{item[1].domain_document_id}}/{{item[1].subdomain_name}}/{{item[1].sub_domain_document_id}}</pre>Field id: <pre>{{item[1].field_name}}</pre></p>
                  <CustomPre :content="item[1].stringified"/>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in entitiesPatchedDiffPairs" :key="'entitiesPatchedDiffPairs-'+index">
              <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                <div v-if="item[0].stringified && item[1].stringified">
                  <p><span class="font-bold text-lg">Present in both versions</span><pre>{{item[0].domain_name}}/{{item[0].domain_document_id}}/{{item[0].subdomain_name}}/{{item[0].sub_domain_document_id}}</pre> (patched)</p>
                  <div v-if="item[0].stringified === item[1].stringified">
                    <p class="font-bold text-lg">Both versions generated identical data</p>
                    <CustomPre :content="item[0].stringified"/>
                  </div>
                  <div v-else>
                    <p class="bg-red-300 font-bold text-lg">There are differences between the versions</p>
                    <Diff
                      :mode="'split'"
                      :theme="'light'"
                      :language="'json'"
                      :prev="item[0].stringified"
                      :current="item[1].stringified"
                    />
                  </div>
                </div>
                <div v-if="item[0].domain_name && !item[1].domain_name">
                  <p class="bg-green-300"><span class="font-bold text-lg">Present in new version only</span><pre>{{item[0].domain_name}}/{{item[0].domain_document_id}}/{{item[0].subdomain_name}}/{{item[0].sub_domain_document_id}}</pre></p>
                  <CustomPre :heading="'Log output'"
                              :content="item[0].stringified"/>
                </div>
                <div v-if="item[1].domain_name && !item[0].domain_name">
                  <p class="bg-red-300"><span class="font-bold text-lg">Present in prior version only</span><pre>{{item[1].domain_name}}/{{item[1].domain_document_id}}/{{item[1].subdomain_name}}/{{item[1].sub_domain_document_id}}</pre></p>
                  <CustomPre :heading="'Log output'"
                              :content="item[1].stringified"/>
                </div>
              </div>
            </div>

            <div v-if="showLogDifferences">
              <p>There were differences in log output generated</p>
              <p>(left: old version, right: new version)</p>
              <Diff
                :mode="'split'"
                :theme="'light'"
                :language="'plaintext'"
                :prev="logCompared"
                :current="logTested"
              />
            </div>
            <div v-if="!showLogDifferences">
              <div v-if="logTested">
                <p>Log output was identical with both versions</p>
                <CustomPre :heading="'Log output'"
                            :content="logTested"/>
              </div>
              <div v-else>
                <p>No log was generated by either version</p>
              </div>
            </div>
          </div>
          <div v-if="!comparisonMode">
            <div v-if="entitiesSaved || entitiesSavedFields || entitiesPatched">
              <h1 class="mb-4">Data modified by the rule</h1>
              <div v-for="item in entitiesSaved" :key="item.domain_name + item.domain_document_id">
                <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                  <p><pre>{{item.domain_name}}/{{item.domain_document_id}}/{{item.subdomain_name}}/{{item.sub_domain_document_id}}</pre></p>
                  <CustomPre :content="item.data"/>
                </div>
              </div>
              <div v-for="item in entitiesSavedFields" :key="item.domain_name + item.domain_document_id">
                <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                  <p><pre>{{item.domain_name}}/{{item.domain_document_id}}/{{item.subdomain_name}}/{{item.sub_domain_document_id}}</pre>Field id: <pre>{{item.field_name}}</pre></p>
                  <CustomPre :content="item.data"/>
                </div>
              </div>
              <div v-for="item in entitiesPatched" :key="item.domain_name + item.domain_document_id">
                <div class="border-2 rounded-xl mt-4 mb-4 p-4">
                  <p><pre>{{item.domain_name}}/{{item.domain_document_id}}/{{item.subdomain_name}}/{{item.sub_domain_document_id}}</pre> (patched)</p>
                  <CustomPre :content="item.data"/>
                </div>
              </div>
            </div>
            <div v-if="logTested">
              <CustomPre :heading="'Log output'"
                          :content="logTested"/>
            </div>
            <div v-else>
              <p>No log was generated</p>
            </div>
            <div class="mb-4">
              <h1 class="mt-4 mb-2">Performance graph</h1>
              <div v-if="performanceData">
                <GanttChart :data="performanceData" legendWidth="300"/>
              </div>
              <div v-else>
                <p>No performance data gathered</p>
              </div>
            </div>
          </div>
          <div v-if="showExceptions" class="mt-4 mb-4">
            <div v-if="exceptionTested" class="bg-red-200 p-4 mt-2">
              <p>Exception raised by the new version:</p>
              <pre class="text-red-600">{{exceptionTested}}</pre>
            </div>
            <div v-if="exceptionCompared" class="bg-red-200 p-4 mt-2">
              <p>Exception raised by the old version:</p>
              <pre class="text-red-600">{{exceptionCompared}}</pre>
            </div>
            <div v-if="showCode && showCodeAsDiff" class="mt-2">
              <h1>Code difference:</h1>
              <Diff
                :mode="'unified'"
                :theme="'light'"
                :language="'plaintext'"
                :prev="codeCompared"
                :current="codeTested"
              />
            </div>
            <div v-if="showCode &! showCodeAsDiff" class="mt-2">
              <div v-if="exceptionCompared">
                <CustomPre :heading="'Old code'"
                            :content="codeCompared"/>
              </div>
              <div v-if="exceptionTested">
                <CustomPre :heading="'New code'"
                            :content="codeTested"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions flex">
        <CustomButton @clicked="executeRule('save')"
                :disabled="saveDisabled"
                class="mr-4">
          Save changes
        </CustomButton>
        <CustomButton @clicked="disableEditRule"
                class="mr-4">
                Cancel & Discard changes
        </CustomButton>
        <CustomButton @clicked="deleteVersion"
                class="mr-4">
          Delete this version
        </CustomButton>
        <CustomButton @clicked="scrollToEditing"
                class="mr-4">
          Jump to editing
        </CustomButton>
      </div>
      <div v-if="saveDisabled" class="reddiff">
        <p class="text-red">Errors preventing save:</p>
        <ul>
          <span v-html="saveDisabledReason"></span>
        </ul>
      </div>
  </Panel>
  <Popup v-if="dataProcessedPopupVisible"
        :text="dataProcessedPopupText"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="onDataProcessedPopupEvent"/>
  <EventDataPickerPopup v-if="isEventDataPickerPopupEnabled"
                        :activeFilters="activeFilters"
                        @datasetSelected="onEventPickerDatasetSelected"
                        @cancelled="onEventPickerCancelled"
  />
  <Preloader v-if="loading" :type="'full'"/>
</template>

<script>
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'
import Popup from '@/components/common/ui/Popup.vue'
import EventDataPickerPopup from '@/components/viewEditRules/EventDataPickerPopup.vue'
import PriorData from '@/components/viewEditRules/PriorData.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import { api } from '@/services/apiService.js'
import endpoints from '@/configs/endpoints'
import appSettings from '@/configs/appSettings.js'
import Column from '@/components/common/ui/Column.vue'
import CustomPre from '@/components/common/custom/CustomPre.vue'
import GanttChart from '@/components/common/custom/gantt/GanttChart.vue'

export default {
  name: 'RuleTestSave',
  mixins: [stringifyDataMixin, requestDataMixin, dateFormatterMixin],
  components: {
    Panel,
    Heading,
    CodeEditor,
    Popup,
    EventDataPickerPopup,
    CustomButton,
    Preloader,
    PriorData,
    Column,
    CustomPre,
    GanttChart
  },
  emits: ['toggleRuleEdit', 'dataProcessed'],
  props: {
    activeFilters: {
      type: Object,
      required: true
    },
    ruleId: {
      type: String,
      required: true
    },
    versionEditing: {
      type: Boolean,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    versionNum: {
      type: [String, Number],
      required: true
    },
    versionCode: {
      type: String,
      required: true
    },
    versionComment: {
      type: String,
      required: true
    },
    versionValidFrom: {
      type: [String, Date],
      required: true
    },
    versionValidTo: {
      type: [String, Date],
      required: true
    },
    priorVersions: {
      type: Array,
      required: true
    },
    filterSelection: {
      type: [null, Array],
      required: false
    }
  },
  data () {
    return {
      priorData: null,
      payload: '',
      priorAuthorizations: '',
      priorConsents: '',
      attributes: '',
      selectedTestPriorVersion: null,
      resultingAuthorizations: '',
      resultingAuthorizationsCompare: '',
      logMessages: '',
      logMessagesCompare: '',
      testNewRuleError: null,
      testExistingRuleError: null,
      saveRuleError: null,
      dataProcessedPopupVisible: false,
      dataProcessedPopupText: '',
      priorAuthorizationsError: false,
      priorConsentsError: false,
      testErrors: [],
      testErrorsString: '',
      loading: false,
      testLoading: false,
      testLoadingCompare: false,
      isEventDataPickerPopupEnabled: false,
      testData: [],
      eventAttributes: null,
      eventPayload: null,
      eventAttributesError: null,
      eventPayloadError: null,
      comparisonMode: false,
      showLogDifferences: false,
      logTested: null,
      logCompared: null,
      showExceptions: false,
      exceptionTested: null,
      exceptionCompared: null,
      showCode: false,
      showCodeAsDiff: false,
      codeTested: null,
      codeCompared: null,
      entitiesSaved: [],
      entitiesSavedFields: [],
      entitiesSavedCompared: [],
      entitiesSavedFieldsCompared: [],
      entitiesSavedDiffPairs: [],
      entitiesSavedFieldsDiffPairs: [],
      entitiesPatched: [],
      entitiesPatchedFields: [],
      entitiesPatchedCompared: [],
      showDataDifferences: false,
      anyFilterFailed: false,
      showAttributes: true,
      showPayload: true,
      filterOutcomes: [],
      performanceData: null
    }
  },
  computed: {
    executeRuleDisabled () {
      return !this.versionCode.replace(/\s/g, '')
    },
    saveDisabled () {
      return !this.versionNum || !this.versionValidFrom || !this.versionValidTo
    },
    saveDisabledReason () {
      let reason = ''
      if (!this.versionNum) {
        reason = reason + "<li>Version number can't be blank</li>"
      }
      if (!this.versionValidFrom) {
        reason = reason + "<li>Valid-From can't be blank</li>"
      }
      if (!this.versionValidTo) {
        reason = reason + "<li>Valid-To can't be blank</li>"
      }
      return reason
    }
  },
  watch: {
    payload () {
      try {
        this.eventPayload = JSON.parse(this.payload)
        this.eventPayloadError = null
      } catch (error) {
        this.eventPayload = null
        this.eventPayloadError = error.message
      }
    },
    attributes () {
      try {
        this.eventAttributes = JSON.parse(this.attributes)
        this.eventAttributesError = null
      } catch (error) {
        this.eventAttributes = null
        this.eventAttributesError = error.message
      }
    }
  },
  methods: {
    onPriorDataChanged (index, data) {
      this.priorData[index].data = data
    },
    onPriorDataModelChanged (index, data) {
      this.testData[index].model = data
    },
    onPriorDataModelUnselected (index) {
      this.testData[index].model = null
    },
    onPriorDataResourceIdChanged (index, data) {
      this.testData[index].resource_id = data
    },
    disableEditRule () {
      this.$emit('toggleRuleEdit', false)
    },
    onPayloadUpdated (code) {
      this.payload = code
    },
    onAttributesUpdated (code) {
      this.attributes = code
    },
    onPriorAuthorizationsUpdated (code) {
      this.priorAuthorizations = code
    },
    onPriorConsentsUpdated (code) {
      this.priorConsents = code
    },
    async executeRule (type) {
      if (type === 'test') {
        this.entitiesSaved = []
        this.entitiesSavedFields = []
        this.entitiesPatched = []
        this.performanceData = null
        const filtersToApply = []
        for (const filterSelected of this.filterSelection) {
          filtersToApply.push(filterSelected.id)
        }

        const request = {
          attributes: this.eventAttributes ? this.eventAttributes : {},
          payload: this.eventPayload ? this.eventPayload : {},
          priorData: this.priorData ? this.priorData : [],
          code: this.versionCode,
          filters: filtersToApply,
          compareWithVersionId: this.selectedTestPriorVersion,
          ruleId: this.ruleId
        }

        const [error, response] = await api.post(endpoints.testNewRule, {}, request)

        if (error) {
          console.log('error while testing', error)
          this.cleanTestResultFields()
          this.testNewRuleError = true
          this.testErrors = error.response.data.detail
        } else {
          if (response.anyFilterFailed) {
            // any filter failing means nothing was executed, and we only show filter outcomes
            this.anyFilterFailed = true
            this.filterOutcomes = []
            for (const outcome of response.filters) {
              outcome.stringifiedCode = outcome.expression
              this.filterOutcomes.push(outcome)
            }
          } else {
            this.anyFilterFailed = false
            this.comparisonMode = response.compared !== null

            this.logTested = response.tested.log
            this.exceptionTested = response.tested.exception
            this.codeTested = response.tested.code
            this.entitiesSaved = response.tested.save
            this.entitiesSavedFields = response.tested.save_fields
            this.entitiesPatched = response.tested.patch
            this.performanceData = response.tested.performance_data

            if (this.comparisonMode) {
              this.logCompared = response.compared.log
              if (this.logTested === this.logCompared) {
                this.showLogDifferences = false
              } else {
                this.showLogDifferences = true
              }

              this.exceptionCompared = response.compared.exception
              this.codeCompared = response.compared.code

              this.entitiesSavedCompared = response.compared.save
              this.entitiesSavedFieldsCompared = response.compared.save_fields
              this.entitiesPatchedCompared = response.compared.patch
              this.showDataDifferences = true
              this.entitiesSavedDiffPairs = []
              this.entitiesSavedFieldsDiffPairs = []
              this.entitiesPatchedDiffPairs = []
              // lets build the diff pairs for data comparisons - first, whole document saves
              for (const entitySaved of this.entitiesSaved) {
                // find the matching saved entity in the rule compared to
                let matchFound = false
                for (const entityCompared of this.entitiesSavedCompared) {
                  if (entitySaved.domain_name === entityCompared.domain_name &&
                      entitySaved.subdomain_name === entityCompared.subdomain_name &&
                      entitySaved.domain_document_id === entityCompared.domain_document_id) {
                    matchFound = true
                    entityCompared.matched = true
                    entitySaved.stringified = this.stringifyData(entitySaved.data)
                    entityCompared.stringified = this.stringifyData(entityCompared.data)
                    this.entitiesSavedDiffPairs.push([entitySaved, entityCompared])
                  }
                }
                if (!matchFound) {
                  // then this whole document was not generated by the old version
                  entitySaved.stringified = this.stringifyData(entitySaved.data)
                  const compared = {
                    stringified: ''
                  }
                  this.entitiesSavedDiffPairs.push([entitySaved, compared])
                }
              }
              // now add all that were not matched
              for (const entityCompared of this.entitiesSavedCompared) {
                if (!entityCompared.matched) {
                  entityCompared.stringified = this.stringifyData(entityCompared.data)
                  const saved = {
                    stringified: ''
                  }
                  this.entitiesSavedDiffPairs.push([saved, entityCompared])
                }
              }
              // lets do the same for field-level saves
              for (const entitySaved of this.entitiesSavedFields) {
                // find the matching saved entity in the rule compared to
                let matchFound = false
                for (const entityCompared of this.entitiesSavedFieldsCompared) {
                  if (entitySaved.domain_name === entityCompared.domain_name &&
                      entitySaved.subdomain_name === entityCompared.subdomain_name &&
                      entitySaved.domain_document_id === entityCompared.domain_document_id &&
                      entitySaved.field_name === entityCompared.field_name) {
                    matchFound = true
                    entityCompared.matched = true
                    entitySaved.stringified = this.stringifyData(entitySaved.data)
                    entityCompared.stringified = this.stringifyData(entityCompared.data)
                    this.entitiesSavedFieldsDiffPairs.push([entitySaved, entityCompared])
                  }
                }
                if (!matchFound) {
                  // then this whole document was not generated by the old version
                  entitySaved.stringified = this.stringifyData(entitySaved.data)
                  const compared = {
                    stringified: ''
                  }
                  this.entitiesSavedFieldsDiffPairs.push([entitySaved, compared])
                }
              }
              // now add all that were not matched
              for (const entityCompared of this.entitiesSavedFieldsCompared) {
                if (!entityCompared.matched) {
                  entityCompared.stringified = this.stringifyData(entityCompared.data)
                  const saved = {
                    stringified: ''
                  }
                  this.entitiesSavedFieldsDiffPairs.push([saved, entityCompared])
                }
              }
              // And the same for patched data
              for (const entitySaved of this.entitiesPatched) {
                // find the matching saved entity in the rule compared to
                let matchFound = false
                for (const entityCompared of this.entitiesPatchedCompared) {
                  if (entitySaved.domain_name === entityCompared.domain_name &&
                      entitySaved.subdomain_name === entityCompared.subdomain_name &&
                      entitySaved.domain_document_id === entityCompared.domain_document_id) {
                    matchFound = true
                    entityCompared.matched = true
                    entitySaved.stringified = this.stringifyData(entitySaved.data)
                    entityCompared.stringified = this.stringifyData(entityCompared.data)
                    this.entitiesPatchedDiffPairs.push([entitySaved, entityCompared])
                  }
                }
                if (!matchFound) {
                  // then this whole document was not generated by the old version
                  entitySaved.stringified = this.stringifyData(entitySaved.data)
                  const compared = {
                    stringified: ''
                  }
                  this.entitiesPatchedDiffPairs.push([entitySaved, compared])
                }
              }
              // now add all that were not matched
              for (const entityCompared of this.entitiesPatchedCompared) {
                if (!entityCompared.matched) {
                  entityCompared.stringified = this.stringifyData(entityCompared.data)
                  const saved = {
                    stringified: ''
                  }
                  this.entitiesPatchedDiffPairs.push([saved, entityCompared])
                }
              }
            } else {
              this.logCompared = null
              this.showLogDifferences = false
              this.exceptionCompared = null
            }

            this.showExceptions = this.exceptionTested || this.exceptionCompared
            this.showCode = this.showExceptions
            this.showCodeAsDiff = this.comparisonMode

            this.testNewRuleError = false
            this.testErrorsString = ''
            this.resultingAuthorizations = this.stringifyData(response.stdOutMessages)
            this.logMessages = this.stringifyData(response.logMessages)
          }
        }
      }

      if (type === 'save') {
        const saveRuleData = {
          code: this.versionCode,
          comment: this.versionComment,
          versionNum: this.versionNum,
          validFrom: this.formatDateBE(this.versionValidFrom),
          validTo: this.formatDateBE(this.versionValidTo)
        }
        console.log(saveRuleData)

        if (!this.versionEditing) {
          saveRuleData.active = false
        }

        const reqType = this.versionEditing ? 'patch' : 'post'

        const url = this.versionEditing
          ? endpoints.rules + '/' + this.ruleId + '/versions/' + this.versionId
          : endpoints.rules + '/' + this.ruleId + '/versions'

        const [error] = await this.requestData(reqType, url, {}, saveRuleData, 'loading')

        if (error) {
          this.dataProcessedPopupText = appSettings.messages.error
          console.log(error)
        } else {
          this.dataProcessedPopupText = this.versionEditing ? 'Version edited.' : 'New version saved.'
        }

        this.dataProcessedPopupVisible = true
      }
    },
    cleanTestResultFields () {
      this.resultingAuthorizations = ''
      this.resultingAuthorizationsCompare = ''
      this.logMessages = ''
      this.logMessagesCompare = ''
      this.testErrorsString = ''
    },
    async deleteVersion () {
      const url = endpoints.rules + '/' + this.ruleId + '/versions/' + this.versionId
      const [error] = await this.requestData('delete', url, {}, {}, 'loading')

      if (error) {
        this.dataProcessedPopupText = appSettings.messages.error
        console.log(error)
      } else {
        this.dataProcessedPopupText = 'Version deleted.'
      }

      this.dataProcessedPopupVisible = true
    },
    onDataProcessedPopupEvent (event) {
      if (event) {
        this.dataProcessedPopupText = ''
        this.$emit('dataProcessed', false)
        this.dataProcessedPopupVisible = false
      }
    },
    selectFromHistory () {
      this.isEventDataPickerPopupEnabled = true
    },
    async pickFromHistory () {
      const url = endpoints.dataPickerEvents
      const params = { eventSourceId: this.activeFilters?.eventType[0], autoPick: true }
      const [error, response] = await this.requestData('get', url, params, {}, 'loading')
      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        // TODO: when endpoint will be ready, uncomment and test it
        // const { payload, attributes } = response
        // this.payload = this.stringifyData(payload)
        // this.attributes = this.stringifyData(attributes)

        // just for test
        const id = response[0].id
        const [err, res] = await this.requestData('get', `${endpoints.eventDetailsUI}/${id}`, {}, {}, 'loading')
        if (!err) {
          const { payload, attributes } = res
          this.payload = this.stringifyData(payload)
          this.attributes = this.stringifyData(attributes)
        }
      }
    },
    onEventPickerDatasetSelected ({ attributes, payload, priorData }) {
      this.isEventDataPickerPopupEnabled = false
      this.payload = this.stringifyData(payload)
      this.attributes = this.stringifyData(attributes)
      this.priorData = priorData
    },
    onEventPickerCancelled () {
      this.isEventDataPickerPopupEnabled = false
    },
    onEventDataPickerPopupEvent ({ eventPayload, authorizations, attributes, consents }) {
      this.isEventDataPickerPopupEnabled = false
      if (eventPayload && authorizations && attributes && consents) {
        this.payload = this.stringifyData(eventPayload)
        this.priorAuthorizations = this.stringifyData(authorizations)
        this.attributes = this.stringifyData(attributes)
        this.priorConsents = this.stringifyData(consents)
      }
    },
    scrollToEditing () {
      document.getElementById('ruleDetails').scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>
  .reddiff {
    background-color: lightcoral;
  }
  .greendiff {
    background-color: lightgreen;
  }
</style>
