<template>
  <AuditFilters v-if="filtersEnabled"
        :viewEditRulesView="false"
        :auditDataView="true"
        :auditIndividual="true"
        :filtersConfiguration="filtersConfiguration"
        :dateRangeEnabled="true"
        :effectiveDateEnabled="false"
        :addEditFilterEnabled="false"
        @filtersChange="onFiltersChange"
        @submitFilters="onSubmitFilters"/>
  <ItemsTable v-if="isUserActivitiesPanelEnabled"
            :title="'User activity'"
            :searchFilter="false"
            :searchKey="'uid'"
            :dateRangeFilter="false"
            :eventTypesFilter="false"
            :fetchSettings="authorizedUsersFetchSettings"
            :tableSettings="userActivityTableSettings"
            :selectable="true"
            :multiSelect="false"
            :defaultItem="null"
            :dateDetailed="true"
            @selectItems="onSelectedUserActivity"/>
  <ActivityDetails v-if="isVersionsPanelEnabled"
            :selectedActivityDetails="selectedActivityDetails"
            :dataBeforeEnabled="true"
            :dataAfterEnabled="true"/>
  <Popup v-if="showPopup"
        text="You are not authorized to view this page"
        :isChoicePopup="false"
        :confirmText="'ok'"
        @popupEvent="showPopup = false"/>
</template>

<script>
import AuditFilters from '@/components/auditUser/filters/AuditFilters.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import ActivityDetails from '@/components/auditUser/ActivityDetails.vue'
import Popup from '@/components/common/ui/Popup.vue'
import viewMixin from '@/mixins/viewMixin.js'
import endpoints from '@/configs/endpoints'

export default {
  name: 'AuditUserView',
  mixins: [viewMixin],
  components: {
    AuditFilters,
    ItemsTable,
    ActivityDetails,
    Popup
  },
  data () {
    return {
      filtersConfiguration: {
        domain: {
          required: false,
          multiselect: false
        },
        source: {
          required: false,
          multiselect: false
        },
        eventType: {
          required: false,
          multiselect: false
        }
      },
      userActivityTableSettings: {
        columnTitles: ['Date', 'User ID', 'Request ID', 'Activity'],
        columnKeys: ['onDate', 'uid', 'requestID', 'details']
      },
      selectedActivityDetails: null,
      showPopup: false
    }
  },
  mounted () {
    if (this.filtersEnabled === false) {
      this.showPopup = true
    }
  },
  computed: {
    filtersEnabled () {
      return this.$store._state.data.user.userInfo && this.$store._state.data.user.userInfo.superuser
    },
    isUserActivitiesPanelEnabled () {
      return this.searchStarted
    },
    authorizedUsersFetchSettings () {
      return {
        url: endpoints.userActivities,
        params: this.activeFilters
      }
    },
    isVersionsPanelEnabled () {
      return this.searchStarted && this.selectedActivityDetails
    }
  },
  watch: {
    searchStarted () {
      this.selectedActivityDetails = null
    }
  },
  methods: {
    onSelectedUserActivity (activity) {
      this.selectedActivityDetails = activity
    }
  }
}
</script>
