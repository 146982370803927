<template>
  <Panel>
    <div class="flex -mx-2">
      <div class="w-4/5 px-2">
        <Options :label="label"
                :fetchSettings="{}"
                :options="sourceTypeOptions"
                :selectable="true"
                :multiselect="false"
                :selectedItems="[]"
                :optionType="sourceTypeName"
                @optionChange="onOptionChange"/>
      </div>
      <div class="w-1/5 px-2">
        <Column class="h-full">
          <CustomButton @clicked="showAddSourcePopup" class="w-full mb-4">
            (+) Define new {{sourceTypeLabel}}
          </CustomButton>
          <CustomButton @clicked="showDeleteSourcePopup(false)" :disabled="isDeleteBtnDisabled" class="w-full">
            Delete selected {{selectedItemsSourceTypeLabel}}
          </CustomButton>
          <CustomButton
            v-if="selectedItemsSourceTypeLabel === 'domain'"
            @clicked="showDeleteSourcePopup(true)"
            :disabled="isDeleteBtnDisabled"
            class="w-full mt-4"
          >
            Delete recursively
          </CustomButton>
        </Column>
      </div>
    </div>
  </Panel>
  <AddValuePopup v-if="addSourcePopupVisible"
                  :valueType="'string'"
                  :title="addSourcePopupTitle"
                  :text="addSourcePopupText"
                  :confirmText="'Add'"
                  :cancelText="'Cancel'"
                  @popupEvent="onAddSource"/>
  <Popup v-if="popupVisible"
        :text="popupText"
        :isChoicePopup="isChoicePopup"
        :confirmText="'ok'"
        :cancelText="'cancel'"
        @popupEvent="popupEvent"/>
  <Preloader v-if="dataProcessing" :type="'full'"/>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Column from '@/components/common/ui/Column.vue'
import Options from '@/components/common/Options.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import AddValuePopup from '@/components/common/AddValuePopup.vue'
import Popup from '@/components/common/ui/Popup.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints.js'
import appSettings from '@/configs/appSettings.js'

export default {
  name: 'Domains',
  mixins: [fetchDataMixin, requestDataMixin],
  emits: ['optionChange'],
  components: {
    Panel,
    Column,
    Options,
    CustomButton,
    AddValuePopup,
    Popup,
    Preloader
  },
  props: {
    label: {
      type: String,
      required: true
    },
    sourceTypeLabel: {
      type: String,
      required: true
    },
    sourceTypeName: {
      type: String,
      required: true
    },
    parentObj: {
      type: [Object, null],
      required: true
    }
  },
  data () {
    return {
      dataProcessing: false,
      sourceTypeOptions: [],
      selectedItems: [],
      addSourcePopupVisible: false,
      popupVisible: false,
      popupText: '',
      isChoicePopup: false,
      requestType: '',
      isRequestFailed: false,
      isRequestProcessed: false,
      removeSourcePopupVisible: false,
      recursive: false
    }
  },
  computed: {
    fetchSettings () {
      return {
        url: '/v1/ui/filters/' + this.sourceTypeName,
        params: this.parentObj || {}
      }
    },
    isDeleteBtnDisabled () {
      return this.selectedItems.length === 0
    },
    addSourcePopupTitle () {
      return 'Create a new ' + this.sourceTypeLabel
    },
    addSourcePopupText () {
      return 'Name the ' + this.sourceTypeLabel + ':'
    },
    selectedItemsSourceTypeLabel () {
      return this.selectedItems.length > 1 ? (this.sourceTypeLabel + 's') : this.sourceTypeLabel
    },
    removeSourcePopupText () {
      return 'Remove the ' + this.selectedItemsSourceTypeLabel + '?'
    }
  },
  watch: {
    fetchedData (data) {
      if (data.length) {
        this.sourceTypeOptions = data
      }
    }
  },
  methods: {
    onOptionChange ({ type, activeOptions }) {
      this.selectedItems = activeOptions
      this.$emit('optionChange', { type, activeOptions })
    },
    showAddSourcePopup () {
      this.requestType = 'add'
      this.addSourcePopupVisible = true
    },
    onAddSource (event, name) {
      if (event) {
        this.handleAddRequest(name)
      }
      this.addSourcePopupVisible = false
    },
    async handleAddRequest (name) {
      const url = endpoints[this.sourceTypeName]
      const nameObj = { name }
      const parentDomainId = this.parentObj ? this.parentObj.domain : {}
      const parentSourceId = this.parentObj ? this.parentObj.source : {}
      const data = { ...nameObj, parentDomainId, parentSourceId }

      const [error, response] = await this.requestData('post', url, {}, data, 'dataProcessing')

      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.sourceTypeOptions.push(response.payload)
        this.isChoicePopup = false
        this.popupText = 'Done.'
        this.popupVisible = true
      }
    },
    showDeleteSourcePopup (recursive) {
      this.requestType = 'delete'
      this.popupText = 'Remove selected ' + this.selectedItemsSourceTypeLabel + '?'
      this.isChoicePopup = true
      this.isRequestFailed = false
      this.isRequestProcessed = false
      this.popupVisible = true
      this.recursive = recursive
    },
    async handleRemoveRequest () {
      const url = '/v1/' + this.sourceTypeName
      const selectedItems = this.selectedItems.map(item => item.id)
      const params = { ids: selectedItems, recursive: this.recursive }
      const [error, response] = await this.requestData('delete', url, params, {}, 'dataProcessing')

      if (error) {
        this.isRequestFailed = true
        this.popupText = appSettings.messages.error
      } else {
        if (response.success) {
          this.popupText = 'Done'
          this.isRequestFailed = false
          const that = this
          selectedItems.forEach(selectedItem => {
            that.sourceTypeOptions = that.sourceTypeOptions.filter(sourceTypeOption =>
              sourceTypeOption.id !== selectedItem
            )
          })
        } else {
          this.isRequestFailed = true
          this.popupText = response.detail + '.'
        }
      }
      this.isRequestProcessed = true
      this.isChoicePopup = false
      this.popupVisible = true
    },
    popupEvent (event) {
      if (this.requestType === 'add') {
        this.isChoicePopup = false
        this.popupVisible = false
      } else if (this.requestType === 'delete') {
        if (event && !this.isRequestFailed && !this.isRequestProcessed) {
          this.popupVisible = false
          this.handleRemoveRequest()
        } else {
          this.isChoicePopup = false
          this.popupVisible = false
        }
      }
    }
  }
}
</script>
