<template>
    <DomainList
      :data="data"
      :notifications="notifications"
      :title="title"
      @activeItem="changeActiveItem($event)"
      @addNewItem="addNewItem()"
      @deleteItem="showDeleteDomainPopup()"
      @uploadFile="uploadItem($event)"
      @uploadFileFailed="showPopup($event)"
    />
    <EditFormNotifications
      v-if="showEditForm && notifications"
      :activeItem="activeItem"
      @saveChanges="showAddDomainPopup($event)"
      @showPopup="showPopup('All fields in left column must be completed')"
    />
    <EditFormStorage
      v-if="showEditForm && !notifications"
      :activeItem="activeItem"
      @saveChanges="showAddDomainPopup($event)"
      @showPopup="showPopup('All fields must be completed')"
    />
    <Popup
      v-if="popupChoiceVisible"
      :text="popupText"
      :isChoicePopup="true"
      confirmText="ok"
      cancelText="cancel"
      @popupEvent="confirmChoicePopup"
    />
    <Popup
      v-if="popupVisible"
      :text="popupText"
      :isChoicePopup="false"
      confirmText="ok"
      @popupEvent="confirmPopup"
    />
</template>

<script>
import DomainList from '@/components/common/DomainList.vue'
import EditFormNotifications from '@/components/notifications/EditFormNotifications.vue'
import EditFormStorage from '@/components/storage/EditFormStorage.vue'
import Popup from '@/components/common/ui/Popup.vue'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import endpoints from '@/configs/endpoints'

export default {
  name: 'Configuration',
  props: {
    notifications: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  mixins: [requestDataMixin],
  components: { DomainList, EditFormNotifications, EditFormStorage, Popup },
  data () {
    return {
      data: [],
      activeItem: {},
      addedItem: {},
      showEditForm: false,
      popupVisible: false,
      popupChoiceVisible: false,
      popupText: '',
      url: this.notifications ? endpoints.notificationsConfig : endpoints.storageConfig
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const [error, response] = await this.requestData('get', this.url, {}, {}, 'loading')
      if (error) {
        this.showError()
      }
      this.data = response
    },
    changeActiveItem (item) {
      this.activeItem = item
      Object.keys(item).length === 0 ? this.showEditForm = false : this.showEditForm = true
    },
    addNewItem () {
      Object.keys(this.activeItem).length === 0 ? this.showEditForm = !this.showEditForm : this.showEditForm = true
      this.activeItem = {}
    },
    confirmPopup () {
      this.popupVisible = false
      if (this.popupText === 'Domain added') {
        this.activeItem = {}
        this.addedItem = {}
      }
    },
    confirmChoicePopup (ev) {
      this.popupChoiceVisible = false
      if (ev) {
        if (this.popupText.startsWith('Remove')) {
          this.deleteItem()
        } else {
          if (this.addedItem.id) {
            this.changeItem()
          } else {
            this.addItem()
          }
        }
      }
    },
    async deleteItem () {
      const params = { ids: [this.activeItem.id] }
      const [error] = await this.requestData('delete', this.url, params, {}, 'loading')
      if (error) {
        this.showError()
      } else {
        this.showPopup('Domain removed')
        this.showEditForm = false
      }
    },
    async changeItem () {
      const url = `${this.url}/${this.addedItem.id}`
      const [error] = await this.requestData('patch', url, {}, this.addedItem, 'loading')
      if (error) {
        this.showError()
      } else {
        this.showPopup('Domain changed')
        this.activeItem = this.addedItem
      }
    },
    async addItem () {
      const [error] = await this.requestData('post', this.url, {}, this.addedItem, 'loading')
      if (error) {
        this.showError()
      } else {
        this.showPopup('Domain added')
      }
    },
    uploadItem (ev) {
      this.addedItem = JSON.parse(ev)
      this.addItem()
    },
    showDeleteDomainPopup () {
      this.showChoicePopup(`Remove domain ${this.activeItem.domain_name}?`)
    },
    showAddDomainPopup (ev) {
      if (JSON.stringify(this.activeItem) === JSON.stringify(ev)) {
        this.popupText = 'Nothing to change'
        this.popupVisible = true
      } else {
        let text = ''
        ev.id ? text = `Change domain ${this.activeItem.domain_name}?` : text = 'Add a new domain?'
        this.showChoicePopup(text)
        this.addedItem = ev
      }
    },
    showError () {
      this.popupText = 'An error occured. Please contact the administrator'
      this.popupVisible = true
    },
    showPopup (text) {
      this.popupText = text
      this.popupVisible = true
      this.getData()
    },
    showChoicePopup (text) {
      this.popupText = text
      this.popupChoiceVisible = true
    }
  }
}
</script>
