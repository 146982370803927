<template>
  <Panel>
    <div class="flex -mx-2 mb-4">
      <div class="w-1/2 px-2">
        <Options :label="'Active rules'"
                  :fetchSettings="activeRulesSettings"
                  :options="activeRules"
                  :selectable="true"
                  :multiselect="false"
                  :selectedItems="[]"
                  :optionType="'active'"
                  :parentLoading="loading"
                  @optionChange="onOptionChange"
                  ref="activeRules"/>
      </div>
      <div class="w-1/2 px-2">
        <Options :label="'Inactive rules'"
                  :fetchSettings="inActiveRulesSettings"
                  :options="inactiveRules"
                  :selectable="true"
                  :multiselect="false"
                  :selectedItems="[]"
                  :optionType="'inactive'"
                  :parentLoading="loading"
                  @optionChange="onOptionChange"
                  ref="inactiveRules"
                  :ruleAdded="ruleAdded"
                  @ruleAdded="ruleAdded = false"/>
      </div>
    </div>
    <CustomButton v-if="createButtonEnabled"
                  @clicked="enableCreateRulePopup">
      Create a new rule
    </CustomButton>
    <CustomButton :disabled="exportRuleButtonEnabled"
                  @clicked="exportSelectedRule"
                  class="ml-4">
      Export selected rule
    </CustomButton>
    <CustomButton :disabled="exportRuleButtonEnabledWithChildren"
                  @clicked="exportSelectedRuleWithChildren"
                  class="ml-4">
      Export selected rule with children rules
    </CustomButton>
    <CustomButton :disabled="deleteButtonEnabled"
                  @clicked="showDeleteRulePopup"
                  class="ml-4">
      Delete selected rule
    </CustomButton>
  </Panel>

  <!-- @TODO one popup for all notifications -->
  <Popup v-if="notificationPopupVisible"
        :text="notificationPopupText"
        :isChoicePopup="notificationPopupIsChoicePopup"
        :confirmText="notificationPopupConfirmText"
        :cancelText="notificationPopupCancelText"
        @popupEvent="onNotificationPopupEvent"/>
  <Popup v-if="codeUpdatedPopupVisible"
        :text="codeUpdatedPopupText"
        :isChoicePopup="true"
        :confirmText="'Discard'"
        :cancelText="'Back to editing'"
        @popupEvent="onCodeUpdatedPopupEvent"/>
  <Popup v-if="deleteRulePopupVisible"
        :text="deletePopupText"
        :isChoicePopup="deleteChoicePopup"
        :confirmText="'ok'"
        :cancelText="'cancel'"
        @popupEvent="onDeleteRulePopupEvent"/>
  <CreateNewRuelePopup v-if="isCreateNewRulePopupVisible"
                      :activeFilters="activeFilters"
                      @cancel="onCancelRuleCreate"
                      @popupEvent="onCreateNewRuelePopupEvent"/>
  <Preloader v-if="exportRuleLoading" :type="'full'"/>
  <Preloader v-if="dataProcessing" :type="'full'"/>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Options from '@/components/common/Options.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Popup from '@/components/common/ui/Popup.vue'
import CreateNewRuelePopup from '@/components/common/CreateNewRulePopup.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints'
import appSettings from '@/configs/appSettings.js'

export default {
  name: 'Rules',
  mixins: [fetchDataMixin, requestDataMixin],
  components: {
    Panel,
    Options,
    Popup,
    CreateNewRuelePopup,
    CustomButton,
    Preloader
  },
  emits: ['selectedRuleChange'],
  props: {
    createButtonEnabled: {
      type: Boolean,
      default: true,
      required: false
    },
    fetchParams: {
      type: Object,
      required: true
    },
    codeUpdated: {
      type: Boolean,
      required: true
    },
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fetchSettingsUrl: endpoints.rulesUI,
      activeRulesSettings: {},
      additionalInactiveRules: [],
      inActiveRulesSettings: {},
      codeUpdatedPopupVisible: false,
      codeUpdatedPopupText: 'You have made changes to the rule bot not saved them',
      optionsChanged: null,
      isCreateNewRulePopupVisible: false,
      notificationPopupVisible: false,
      notificationPopupText: '',
      notificationPopupIsChoicePopup: false,
      notificationPopupConfirmText: '',
      notificationPopupCancelText: '',
      selectedRule: null,
      exportRuleLoading: false,
      ruleAdded: false,
      deleteRulePopupVisible: false,
      dataProcessing: false,
      deleteChoicePopup: true
    }
  },
  computed: {
    fetchSettings () {
      return {
        url: this.fetchSettingsUrl,
        params: this.fetchParams
      }
    },
    activeRules () {
      return this.fetchedData?.activeRules || []
    },
    inactiveRules () {
      const inactiveRules = this.fetchedData?.inactiveRules.length ? this.fetchedData?.inactiveRules : []
      return [...inactiveRules, ...this.additionalInactiveRules]
    },
    exportRuleButtonEnabled () {
      return !this.selectedRule
    },
    exportRuleButtonEnabledWithChildren () {
      return !this.selectedRule
    },
    deleteButtonEnabled () {
      return !this.selectedRule
    }
  },
  methods: {
    showDeleteRulePopup () {
      this.requestType = 'delete'
      this.deleteChoicePopup = true
      this.deletePopupText = 'Permanently remove ' + this.selectedRule.name + '?'
      this.isRequestFailed = false
      this.isRequestProcessed = false
      this.deleteRulePopupVisible = true
    },
    onDeleteRulePopupEvent (event) {
      console.log('event', event)
      if (event && !this.isRequestFailed && !this.isRequestProcessed) {
        this.deleteRulePopupVisible = false
        this.handleRemoveRuleRequest()
      } else {
        this.isChoicePopup = false
        this.deleteRulePopupVisible = false
      }
    },
    async handleRemoveRuleRequest () {
      const url = '/v1/rules/' + this.selectedRule.id
      const [error, response] = await this.requestData('delete', url, {}, {}, 'dataProcessing')

      if (error) {
        this.isRequestFailed = true
        this.popupText = appSettings.messages.error
      } else {
        if (response.success) {
          this.deletePopupText = 'Done'
          this.isRequestProcessed = true
          this.deleteChoicePopup = false
          this.deleteRulePopupVisible = true
          const that = this
          this.fetchedData.inactiveRules = this.fetchedData.inactiveRules.filter(rule => rule.id !== that.selectedRule.id)
          this.fetchedData.activeRules = this.fetchedData.activeRules.filter(rule => rule.id !== that.selectedRule.id)
        } else {
          this.isRequestFailed = true
          this.popupText = response.detail + '.'
        }
      }
    },
    onOptionChange (options) {
      this.optionsChanged = options
      this.selectedRule = this.optionsChanged?.activeOptions[0]
      if (this.codeUpdated) {
        this.codeUpdatedPopupVisible = true
      } else {
        this.handleOptionChange()
      }
    },
    handleOptionChange () {
      if (this.optionsChanged.type === 'active' && this.optionsChanged.activeOptions.length !== 0) {
        this.$refs.inactiveRules.cleanSelectedOptions()
      } else if (this.optionsChanged.type === 'inactive' && this.optionsChanged.activeOptions.length !== 0) {
        this.$refs.activeRules.cleanSelectedOptions()
      }

      this.$emit('selectedRuleChange', this.optionsChanged)
    },
    onCodeUpdatedPopupEvent (event) {
      if (event) {
        this.handleOptionChange()
      } else {
        if (this.optionsChanged.type === 'active') {
          this.$refs.activeRules.cleanSelectedOptions()
        } else if (this.optionsChanged.type === 'inactive') {
          this.$refs.inactiveRules.cleanSelectedOptions()
        }
      }

      this.codeUpdatedPopupVisible = false
    },
    enableCreateRulePopup () {
      this.isCreateNewRulePopupVisible = true
    },
    onCancelRuleCreate () {
      this.isCreateNewRulePopupVisible = false
    },
    onCreateNewRuelePopupEvent (newRule) {
      this.isCreateNewRulePopupVisible = false
      this.notificationPopupIsChoicePopup = false
      this.notificationPopupConfirmText = 'Ok'

      if (newRule) {
        this.additionalInactiveRules.push(newRule)
        this.notificationPopupText = 'Rule added.'
        this.notificationPopupVisible = true
        this.ruleAdded = true
      } else if (newRule === false) {
        this.notificationPopupText = appSettings.messages.error
        this.notificationPopupVisible = true
      }
    },
    onNotificationPopupEvent () {
      this.notificationPopupVisible = false
    },
    async exportSelectedRule () {
      const url = endpoints.rulesUI + '/' + this.selectedRule.id + '/export'
      const [error, response] = await this.requestData('get', url, {}, {}, 'exportRuleLoading')
      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        const a = document.createElement('a')
        const file = new Blob([JSON.stringify(response)], { type: 'text/plain' })
        a.href = URL.createObjectURL(file)
        a.download = response.saveAs + '.json'
        a.click()
      }
    },
    async exportSelectedRuleWithChildren () {
      const url = endpoints.rulesUI + '/' + this.selectedRule.id + '/export_with_children'
      const [error, response] = await this.requestData('get', url, {}, {}, 'exportRuleLoading')
      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        const a = document.createElement('a')
        const file = new Blob([JSON.stringify(response)], { type: 'text/plain' })
        a.href = URL.createObjectURL(file)
        a.download = response?.[0].saveAs + '-with_children_rules.json'
        a.click()
      }
    }
  }
}
</script>
