<template>
  <Panel>
    <label class="block mb-2">{{label}}</label>
    <div class="w-96">
      <div class="flex items-center">
        <CustomInput v-model='identifierValue'
                    :name="'identifier'"
                    :searchStyle="false"
                    class="mr-4"/>
        <CustomButton :disabled="isSubmitButtonDisbaled"
                @clicked="submit"
                class="w-64 ml-auto">
                Search
        </CustomButton>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/common/ui/Panel.vue'
import validateEmailMixin from '@/mixins/validateEmailMixin.js'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'

export default {
  name: 'IndividualIdentifier',
  mixins: [validateEmailMixin],
  components: {
    Panel,
    CustomInput,
    CustomButton
  },
  emits: ['identifierSelected'],
  props: {
    identifierValidationType: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      identifierValue: ''
    }
  },
  computed: {
    label () {
      let label = ''

      if (this.identifierValidationType && this.identifierValidationType !== '') {
        switch (this.identifierValidationType) {
          case 1:
            label = 'Specify the global id:'
            break
          case 2:
            label = 'Specify the email:'
            break
          case 3:
            label = 'Specify the phone number:'
            break
          case 4:
            label = 'Specify the facebook handle:'
            break
          default:
            label = ''
        }
      }

      return label
    },
    isSubmitButtonDisbaled () {
      switch (this.identifierValidationType) {
        case 1:
          return !this.validateGuid(this.identifierValue)
        case 2:
          return !this.validateEmail(this.identifierValue)
        case 3:
          return !this.validatePhone(this.identifierValue)
        case 4:
          return !this.validateFacebook(this.identifierValue)
        default:
          return true
      }
    }
  },
  methods: {
    changeInputValue ($event) {
      this.identifierValue = $event.target.value
    },
    validateGuid (value) {
      const regex = /^[0-9a-f\\-]+$/
      return regex.test(value)
    },
    validatePhone (value) {
      const regex = /^(?=.*[0-9])[- +()0-9]+$/

      return regex.test(String(value).toLowerCase()) && value.replace(/\s+/g, '').replace(/-/gi, '').length > 6
    },
    validateFacebook (value) {
      const pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i')

      return !!pattern.test(value)
    },
    submit () {
      this.$emit('identifierSelected', this.identifierValue)
    }
  },
  beforeUnmount () {
    this.$emit('identifierSelected', null)
  }
}
</script>
