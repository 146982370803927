<template>
  <Panel>
    <div class="flex">
      <CustomButton @clicked="cancelChanges"
             :disabled="buttonsDisabled"
             class="mr-4">
        Cancel changes
      </CustomButton>
      <CustomButton  @clicked="saveChanges"
              :disabled="buttonsDisabled">
        Approve updated access rights
      </CustomButton>
    </div>
    <Popup v-if="saveChangesPopupVisible"
        :text="'Save updated access rights?'"
        :isChoicePopup="true"
        :confirmText="'Ok'"
        :cancelText="'Cancel'"
        @popupEvent="onSaveChangesPopupEvent"/>
    <Popup v-if="dataProcessedPopupVisible"
        :text="dataProcessedPopupText"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="onDataProcessedPopupEvent"/>
    <Preloader v-if="loading" :type="'full'"/>
  </Panel>
</template>

<script>
import requestDataMixin from '@/mixins/requestDataMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Popup from '@/components/common/ui/Popup.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints'

export default {
  name: 'HandleChanges',
  mixins: [requestDataMixin],
  components: {
    Panel,
    Popup,
    CustomButton,
    Preloader
  },
  props: {
    rolesUpdated: {
      type: Boolean,
      required: true
    },
    rolesModelUpdated: {
      type: [Object, null],
      required: true
    },
    autorizedUserId: {
      type: Number,
      required: true
    },
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saveChangesPopupVisible: false,
      dataProcessedPopupVisible: false,
      loading: false
    }
  },
  computed: {
    buttonsDisabled () {
      return !this.rolesUpdated
    }
  },
  methods: {
    cancelChanges () {
      this.$emit('cancelChanges')
    },
    saveChanges () {
      this.saveChangesPopupVisible = true
    },
    onSaveChangesPopupEvent (event) {
      if (event) {
        this.saveChangesData()
      }
      this.saveChangesPopupVisible = false
    },
    async saveChangesData () {
      const url = endpoints.usersUI + '/' + this.autorizedUserId + '/permissions'

      const params = {
        domain: this.activeFilters?.domain[0],
        source: this.activeFilters?.source[0],
        eventType: this.activeFilters?.eventType[0],
        filters: [],
        editRules: this.rolesModelUpdated.canEditRules.active,
        viewRules: this.rolesModelUpdated.canViewRules.active,
        accessLogs: this.rolesModelUpdated.canAccessAssociatedAuditLogs.active
      }

      const [error, response] = await this.requestData('put', url, params, 'loading')

      if (error || !response.success) {
        this.dataProcessedPopupText = 'An error occured. Please contact the administrator'
      } else {
        this.dataProcessedPopupText = 'Access rights updated.'
        this.dataProcessedProperly = true
      }

      this.dataProcessedPopupVisible = true
    },
    onDataProcessedPopupEvent () {
      this.dataProcessedPopupVisible = false
      this.$emit('saveChanges', true)
    }
  }
}
</script>
