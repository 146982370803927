<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperNewRule"
      @keydown.esc="popuptEvent(false)"
      :tabindex="0">
    <div class="popup popup-big">
      <p class="text-xl text-center mb-2">Create new rule</p>
      <hr class="mb-6"/>
      <p>Name</p>
      <CustomInput v-model='name'
                    :name="'name'"
                    :searchStyle="false"
                    class="mb-4"/>
      <p>Produced entity</p>
      <div class="flex border flex-wrap border-gray-400 rounded mb-4 pl-2 pr-2">
        <div v-for="item in producedEntityNames" :key="item" class="w-1/3">
          <CustomCheckbox :fieldId="'producedEntity_' + item.id"
          :label="item.entity_name"
          v-model:checked="item.selected"
          class="mt-2 mb-2" />
        </div>
      </div>
      <p>Source data type</p>
      <div class="flex border flex-wrap border-gray-400 rounded mb-4 pl-2 pr-2">
        <div v-for="item in sourceEntityNames" :key="item" class="w-1/3">
          <CustomCheckbox :fieldId="'sourceEntity_' + item.id"
          :label="item.entity_name"
          v-model:checked="item.selected"
          class="mt-2 mb-2" />
        </div>
      </div>
      <p>Description</p>
      <textarea v-model="description"
                class="w-full mb-4 h-24"/>
      <div class="flex items-center justify-end">
        <CustomButton @clicked="popuptEvent(null)"
                      class="w-36 mx-2">
          Cancel
        </CustomButton>
        <CustomButton :disabled="isSaveButtonDisabled" @clicked="popuptEvent(true)"
                      class="ml-2 w-36">
          Create
        </CustomButton>
      </div>
    </div>
    <Preloader v-if="loading" :type="'full'"/>
  </div>
</template>

<script>
import requestDataMixin from '@/mixins/requestDataMixin.js'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints'
import CustomCheckbox from '@/components/common/custom/CustomCheckbox.vue'

export default {
  name: 'CreateNewRulePopup',
  mixins: [requestDataMixin],
  components: {
    CustomInput,
    CustomButton,
    Preloader,
    CustomCheckbox
  },
  props: {
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      name: '',
      producedEntityNames: [],
      sourceEntityNames: [],
      description: '',
      entitiesOptions: []
    }
  },
  created () {
    this.fetchEntities()
  },
  mounted () {
    this.$refs.wrapperNewRule.focus()
  },
  computed: {
    producedEntityName () {
      const selected = this.producedEntityNames.filter(item => item.selected)
      return selected.map((obj) => obj.entity_name).join(', ')
    },
    sourceEntityName () {
      const selected = this.sourceEntityNames.filter(item => item.selected)
      return selected.map((obj) => obj.entity_name).join(', ')
    },
    isSaveButtonDisabled () {
      return this.name.replace(/\s/g, '') === '' ||
      this.producedEntityName === ''
    }
  },
  methods: {
    popuptEvent (event) {
      if (event) {
        this.saveData()
      } else {
        this.$emit('cancel')
      }
    },
    async saveData () {
      const url = endpoints.rules

      const data = {
        name: this.name,
        producedEntityName: this.producedEntityName,
        sourceEntityName: this.sourceEntityName,
        description: this.description,
        eventTypeId: (this.activeFilters.eventType[0]).toString(),
        filters: this.activeFilters.filters || []
      }
      const [error, response] = await this.requestData('post', url, {}, data, 'loading')
      this.$emit('popupEvent', error ? false : { id: response.payload.id, name: response.payload.name })
    },
    async fetchEntities () {
      const url = endpoints.entityTypesUI
      const [error, data] = await this.requestData('get', url, {}, {}, 'dataSourceLoading')

      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.sourceEntityNames = data
        // deep copy object before assign
        this.producedEntityNames = JSON.parse(JSON.stringify(data))
      }
    }
  }
}
</script>
