<template>
  <Filters :viewEditRulesView="false"
          :auditDataView="true"
          :auditIndividual="false"
          :filtersConfiguration="filtersConfiguration"
          :dateRangeEnabled="true"
          :effectiveDateEnabled="false"
          :addEditFilterEnabled="false"
          @filtersChange="onFiltersChange"
          @submitFilters="onSubmitFilters"/>
  <Rules v-if="isRulesPanelEnabled"
          :fetchParams="rulesFetchParams"
          :codeUpdated="false"
          :activeFilters="activeFilters"
          :createButtonEnabled="false"
          @selectedRuleChange="onSelectedRuleId" />
  <ItemsTable v-if="isVersionPanelEnabled"
              :title="'Versions'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="false"
              :eventTypesFilter="false"
              :fetchSettings="versionsFetchSettings"
              :tableSettings="versionsTableSettings"
              :selectable="true"
              :multiSelect="false"
              :defaultItem="defaultVersionId"
              :dateDetailed="false"
              @selectItems="onSelectedVersionId"
              ref="versions"/>
  <ItemsTable v-if="isAffectedEventsPanelEnabled"
              :title="'Affected events'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="true"
              :eventTypesFilter="false"
              :fetchSettings="affectedEventsFetchSettings"
              :tableSettings="affectedEventsTableSettings"
              :selectable="true"
              :multiSelect="false"
              :defaultItem="null"
              :dateDetailed="false"
              @selectItems="onSelectedAffectedEventId"/>
  <EventDetails v-if="isEventDetailsPanelEnabled"
                :fetchSettings="eventDetailsFetchSettings"
                :defaultDetails="null"
                :envelopeEnabled="true"
                :eventPayloadEnabled="true"
                :rulesRejectedEnabled="true"/>
</template>

<script>
import Filters from '@/components/common/filters/Filters.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import EventDetails from '@/components/common/EventDetails.vue'
import viewMixin from '@/mixins/viewMixin.js'
import endpoints from '@/configs/endpoints.js'
import Rules from '@/components/common/Rules.vue'

export default {
  name: 'AuditRuleView',
  mixins: [viewMixin],
  components: {
    Filters,
    ItemsTable,
    EventDetails,
    Rules
  },
  props: {
    ruleId: {
      type: String,
      required: false
    },
    versionId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      filtersConfiguration: {
        domain: {
          required: true,
          multiselect: false
        },
        source: {
          required: true,
          multiselect: false
        },
        eventType: {
          required: true,
          multiselect: false
        }
      },
      selectedFilters: null,
      defaultRuleId: null,
      selectedRuleId: null,
      rulesTableSettings: {
        columnTitles: ['Last edited', 'Last event applied', 'Rule name', 'Description'],
        columnKeys: ['lastEdited', 'lastEventApplied', 'name', 'description']
      },
      selectedVersionId: null,
      versionsTableSettings: {
        columnTitles: ['Effective from', 'Effective to', 'Active', 'Version description'],
        columnKeys: ['validFrom', 'validTo', 'active', 'comment']
      },
      selectedAffectedEventId: null,
      affectedEventsTableSettings: {
        columnTitles: ['Event date', 'Event processed on', 'Rules executed', 'Rules skipped due to filters', 'Rules with errors'],
        columnKeys: ['timestamp', 'timestampProcessed', 'rulesExecuted', 'rulesSkipped', 'rulesErrors']
      }
    }
  },
  computed: {
    isRulesPanelEnabled () {
      return this.searchStarted
    },
    rulesFetchParams () {
      return this.activeFilters
    },
    isVersionPanelEnabled () {
      return this.selectedRuleId && this.searchStarted
    },
    versionsFetchSettings () {
      let settings = {}

      if (this.selectedRuleId) {
        settings = {
          url: endpoints.rules + '/' + this.selectedRuleId,
          params: '',
          nestedDataObject: 'versions'
        }
      }

      return settings
    },
    isAffectedEventsPanelEnabled () {
      return this.selectedRuleId && this.searchStarted
    },
    affectedEventsFetchSettings () {
      let settings = {}

      if (this.selectedRuleId) {
        settings = {
          url: endpoints.eventsUI,
          params: this.selectedVersionId ? { ruleId: this.selectedRuleId, ruleVersionId: this.selectedVersionId } : { ruleId: this.selectedRuleId }
        }
        if (this.activeFilters.date_from) {
          settings.params.dateFrom = this.activeFilters.date_from
        }
        if (this.activeFilters.date_to) {
          settings.params.dateTo = this.activeFilters.date_to
        }
      }

      return settings
    },
    isEventDetailsPanelEnabled () {
      return this.selectedAffectedEventId && this.searchStarted
    },
    eventDetailsFetchSettings () {
      let settings = {}

      if (this.selectedAffectedEventId) {
        settings = {
          url: endpoints.eventDetailsUI + '/' + this.selectedAffectedEventId,
          params: {}
        }
      }

      return settings
    }
  },
  watch: {
    searchStarted (newVal, oldVal) {
      if (oldVal) {
        this.defaultRuleId = null
        this.selectedRuleId = null
        this.defaultVersionId = null
        this.selectedVersionId = null
        this.selectedAffectedEventId = null
      }
    }
  },
  methods: {
    onFiltersChange () {
      this.searchStarted = false
      this.selectedFilters = null
    },
    onSelectedRuleId (rule) {
      this.selectedAffectedEventId = null
      this.selectedVersionId = null
      this.defaultVersionId = null
      this.selectedRuleId = rule?.activeOptions.length ? (rule.activeOptions[0].id).toString() : null
    },
    onSelectedVersionId (version) {
      this.selectedAffectedEventId = null
      this.selectedVersionId = version?.id || null
    },
    onSelectedAffectedEventId (event) {
      this.selectedAffectedEventId = event?.id || null
    }
  }
}
</script>
