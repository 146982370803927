<template>
  <p class="heading">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'Heading'
}
</script>
