import { api } from '@/services/apiService.js'
import endpoints from '@/configs/endpoints'
import store from '@/store'

class UserService {
  async setUserInfo () {
    const [error, userInfo] = await api.get(endpoints.authMe)

    if (error) {
      console.log(error)
      const err = JSON.parse(JSON.stringify(error))
      if (err.status) {
        this.$store.commit('catchError', err.status)
      } else {
        this.$store.commit('catchError', err.message)
      }
    } else {
      store.commit('setUserInfo', userInfo)
    }
  }
}

export const userService = new UserService()
