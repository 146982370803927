<template>
  <Panel>
    <div class="flex">
      <CustomButton @clicked="removeAccess"
              :disabled="isRemoveAccesButtonDisabled"
              class="mr-4">Remove access from the user
      </CustomButton>
      <CustomButton @clicked="addAccess">
        Authorize a new user
      </CustomButton>
    </div>
  </Panel>
  <Popup v-if="removeAccessPopupVisible"
        :text="'Remove access from selected user?'"
        :isChoicePopup="true"
        :confirmText="'Ok'"
        :cancelText="'Cancel'"
        @popupEvent="onAccessRemovePopupEvent"/>
  <AddValuePopup v-if="addAccessPopupVisible"
                  :valueType="'email'"
                  :title="'Authoruize a new user'"
                  :text="'Type user email'"
                  :confirmText="'Add'"
                  :cancelText="'Cancel'"
                  @popupEvent="onAccessAddPopupEvent"/>
  <Popup v-if="dataProcessedPopupVisible"
        :text="dataProcessedPopupText"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="onDataProcessedPopupEvent"/>
  <Preloader v-if="loading" :type="'full'"/>
</template>

<script>
import manageReviewAccessMixin from '@/mixins/manageReviewAccessMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Popup from '@/components/common/ui/Popup.vue'
import AddValuePopup from '@/components/common/AddValuePopup.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints'

export default {
  name: 'AddRemoveAccess',
  mixins: [manageReviewAccessMixin],
  emits: ['dataUpdated'],
  components: {
    Panel,
    Popup,
    AddValuePopup,
    CustomButton,
    Preloader
  },
  props: {
    activeFilters: {
      type: Object,
      required: true
    },
    userEmail: {
      type: [String, null],
      required: true
    },
    selectedAuthorizedUserEmail: {
      type: [String, null],
      required: true
    }
  },
  data () {
    return {
      removeAccessPopupVisible: false,
      addAccessPopupVisible: false
    }
  },
  computed: {
    isRemoveAccesButtonDisabled () {
      return !this.userEmail
    }
  },
  methods: {
    removeAccess () {
      this.removeAccessPopupVisible = true
    },
    onAccessRemovePopupEvent (event) {
      if (event) {
        this.handleRequest({ emails: this.selectedAuthorizedUserEmail }, endpoints.authorizedUsersUI, 'delete')
      }

      this.removeAccessPopupVisible = false
    },
    addAccess () {
      this.addAccessPopupVisible = true
    },
    onAccessAddPopupEvent (event, email) {
      if (event) {
        this.handleRequest({ email }, endpoints.authorizedUsersUI, 'post')
      }

      this.addAccessPopupVisible = false
    }
  }
}
</script>
