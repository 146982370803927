<template>
  <Panel>
    <div class="mb-4">
      <Heading class="text-center">Activity details</Heading>
      <hr class="mb-4">
      <p><strong>Activity date:</strong> {{selectedActivityDetails.onDate}}</p>
      <p><strong>User ID:</strong> {{selectedActivityDetails.uid}}</p>
      <p><strong>Request ID:</strong> {{selectedActivityDetails.requestID}}</p>
      <p><strong>Details:</strong> {{selectedActivityDetails.details}}</p>
    </div>
    <div class="flex -mx-2 mb-4">
      <div class="w-1/2 px-2">
        <Column>
          <div v-if="dataBeforeEnabled" class="mb-4">
            <heading>Data before action</heading>
            <CodeEditor :readonly="true"
                      :code="beforeVersions"
                      :language="'json'"
                      :height="'h-56'" />
          </div>
        </Column>
      </div>
      <div class="w-1/2 px-2">
        <Column>
          <div v-if="dataAfterEnabled" class="mb-4">
            <heading>Data after action</heading>
            <CodeEditor :readonly="true"
                      :code="afterVersions"
                      :language="'json'"
                      :height="'h-56'"/>
          </div>
        </Column>
      </div>
    </div>
  </Panel>
</template>

<script>
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import Column from '@/components/common/ui/Column.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'

export default {
  name: 'ActiveDetails',
  mixins: [stringifyDataMixin],
  components: {
    Panel,
    Heading,
    Column,
    CodeEditor
  },
  props: {
    selectedActivityDetails: {
      type: Object,
      required: true
    },
    dataBeforeEnabled: {
      type: Boolean,
      required: true
    },
    dataAfterEnabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    beforeVersions () {
      return this.selectedActivityDetails?.dataBefore != null ? this.stringifyData(JSON.parse(this.selectedActivityDetails?.dataBefore)) : ''
    },
    afterVersions () {
      return this.selectedActivityDetails?.dataAfter != null ? this.stringifyData(JSON.parse(this.selectedActivityDetails?.dataAfter)) : ''
    }
  }
}
</script>
