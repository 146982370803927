<template>
  <Configuration
    v-if="isSuperUser"
    notifications
    title="Notifications configuration"
  />
  <PathNotFound v-else />
</template>

<script>
import Configuration from '@/components/common/Configuration.vue'
import PathNotFound from '@/views/PathNotFound.vue'

export default {
  name: 'NotificationsView',
  components: {
    Configuration,
    PathNotFound
  },
  computed: {
    isSuperUser () {
      return this.$store?._state?.data?.user?.userInfo?.superuser
    }
  }
}
</script>
