<template>
  <div class="w-full" :class="{'search-container':searchStyle}">
     <input
      :value="modelValue"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="onInput"
      type="text"
      :class="{'pl-7':searchStyle}"
      class=
    />
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    searchStyle: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onInput (event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
