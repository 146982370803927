<template>
  <Panel>
    <Heading>User roles</Heading>
    <hr class="mb-4">
    <ul>
      <li v-for="checkbox in rolesModel" :key="checkbox" class="flex mb-2">
        <CustomCheckbox
          :fieldId="checkbox.id"
          v-model:checked="checkbox.active"
          :label="checkbox.label" />
      </li>
    </ul>
    <p v-if="noData">No roles</p>
  </Panel>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import areModelsEqualMixin from '@/mixins/areModelsEqualMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import CustomCheckbox from '@/components/common/custom/CustomCheckbox.vue'
import endpoints from '@/configs/endpoints'

export default {
  name: 'UserRoles',
  mixins: [fetchDataMixin, areModelsEqualMixin],
  components: {
    Panel,
    Heading,
    CustomCheckbox
  },
  props: {
    autorizedUserId: {
      type: Number,
      required: true
    },
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rolesModel: [],
      initialRolesModel: []
    }
  },
  computed: {
    fetchSettings () {
      let authorizedUsersSettings = {}

      if (this.autorizedUserId) {
        authorizedUsersSettings = {
          url: endpoints.usersUI + '/' + this.autorizedUserId + '/permissions',
          params: {
            domain: this.activeFilters?.domain[0],
            source: this.activeFilters?.source[0],
            eventType: this.activeFilters?.eventType[0]
          }
        }
      }

      return authorizedUsersSettings
    },
    noData () {
      return this.rolesModel ? this.rolesModel.length === 0 : true
    }
  },
  watch: {
    fetchedData (data) {
      this.rolesModel = data
      this.initialRolesModel = JSON.parse(JSON.stringify(data))
    },
    rolesModel: {
      handler (rolesModel) {
        this.$emit('rolesUpdated', JSON.stringify(rolesModel) !== JSON.stringify(this.initialRolesModel))
        this.$emit('rolesModelUpdated', rolesModel)
      },
      deep: true
    }
  },
  methods: {
    resetRolesModel () {
      this.rolesModel = JSON.parse(JSON.stringify(this.initialRolesModel))
    },
    updateRolesModel () {
      this.initialRolesModel = JSON.parse(JSON.stringify(this.rolesModel))
    }
  }

}
</script>
