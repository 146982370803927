<template>
  <div v-if="effectiveDateEnabled" class="mb-2">
    <Heading>{{effectiveDateLabel}}</Heading>
    <Datepicker v-model="effectiveDate"
              :enableTimePicker="enableTimePicker"
              placeholder="At date"
              class="w-full"/>
  </div>
  <p class="mb-2 text-sm">{{rangeDateLabel}}</p>
  <Datepicker v-model="rangeDateFrom"
              :maxDate="rangeDateTo"
              :enableTimePicker="enableTimePicker"
              placeholder="Valid from"
              class="w-full"/>
  <span class="mb-2"></span>
  <Datepicker v-model="rangeDateTo"
              :minDate="rangeDateFrom"
              :enableTimePicker="enableTimePicker"
              :placeholder="'Valid to'"
              class="w-full" />
</template>

<script>
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import Heading from '@/components/common/ui/Heading.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'DateFilter',
  emits: ['createDateFilter'],
  mixins: [dateFormatterMixin],
  components: {
    Heading,
    Datepicker
  },
  props: {
    effectiveDateEnabled: {
      type: Boolean,
      required: true
    },
    effectiveDateLabel: {
      type: String,
      required: true
    },
    rangeDateLabel: {
      type: String,
      required: true
    },
    enableTimePicker: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      effectiveDate: null,
      rangeDateFrom: null,
      rangeDateTo: null
    }
  },
  computed: {
    setDateFilterDisabled () {
      return this.effectiveDate === null && this.rangeDateFrom === null && this.rangeDateTo === null
    },
    dateFilter () {
      return {
        date_for: this.effectiveDate ? this.formatDateBE(this.effectiveDate) : null,
        date_from: this.rangeDateFrom ? this.formatDateBE(this.rangeDateFrom) : null,
        date_to: this.rangeDateTo ? this.formatDateBE(this.rangeDateTo) : null
      }
    }
  },
  watch: {
    effectiveDate (newDate) {
      if (newDate !== null) {
        this.rangeDateFrom = null
        this.rangeDateTo = null
      }
    },
    rangeDateFrom (newDate) {
      if (newDate !== null) {
        this.effectiveDate = null
      }
    },
    rangeDateTo (newDate) {
      if (newDate !== null) {
        this.effectiveDate = null
      }
    },
    dateFilter () {
      this.$emit('createDateFilter', this.dateFilter)
    }
  }
}
</script>
