<template>
  <Panel>
    <Heading class="text-center"> {{ title }}</Heading>
    <hr class="mb-4">
    <div class="flex inset-0 -mx-2">
      <div class="w-3/5 px-2">
        <div class="grid grid-cols-12 block pb-1 border-b border-gray-200">
          <span
            v-for="(header, id) in ['Domain', 'Subdomain', 'Entity name']"
            :key="id"
            :class="id === 0 ? 'col-span-3 col-start-2' : 'col-span-4'"
          >
            {{ header }}
          </span>
        </div>
        <div class="grid grid-cols-12 max-h-72 mt-1 gap-y-0.5 overflow-y-auto border-gray-200 rounded-md">
          <div
            v-for="row in data"
            :key="row.id"
            class="grid col-span-12 grid-cols-12 cursor-pointer py-1 rounded-md select-none"
            :class="activeItem.id === row.id ? 'bg-blue-100' : 'hover:bg-gray-100'"
            @click="changeActiveItem(row.id)"
          >
            <CustomCheckbox
              :fieldId="`checkbox ${row.domain_name}`"
              :checked="activeItem.id === row.id"
              @update:checked="changeActiveItem(row.id)"
              label=""
              class="mt-1 pl-2"
            />
            <span class="col-span-3" v-html="row.domain_name" />
            <span class="col-span-4" v-html="row.subdomain_name" />
            <span class="col-span-4" v-html="row.entity_name" />
          </div>
        </div>
      </div>
      <div class="flex flex-col my-auto ml-4">
        <CustomButton
          class="rounded-md px-4 py-2 mb-4"
          @click="reset()"
        >
          (+) Define new
        </CustomButton>
        <CustomButton
          class="rounded-md px-4 py-2 mb-4"
          @click="deleteItem(activeItem.id)"
          :disabled="!activeItem.id"
        >
          Delete selected
        </CustomButton>
        <CustomButton
          class="rounded-md px-4 py-2"
          @click="uploadFile"
        >
          Import from file
        </CustomButton>
        <input type="file" ref="file" @change="readFile()" hidden />
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import CustomCheckbox from '@/components/common/custom/CustomCheckbox.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'

export default {
  name: 'List',
  components: {
    Panel,
    Heading,
    CustomCheckbox,
    CustomButton
  },
  emits: ['activeItem', 'addNewItem', 'deleteItem', 'uploadFile', 'uploadFileFailed'],
  data () {
    return {
      activeItem: {}
    }
  },
  props: {
    data: {
      type: Array,
      default: Array
    },
    notifications: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    changeActiveItem (id) {
      this.activeItem.id === id ? this.activeItem = {} : this.activeItem = this.data.find(el => el.id === id)
      this.$emit('activeItem', this.activeItem)
    },
    reset () {
      this.activeItem = {}
      this.$emit('addNewItem')
    },
    deleteItem () {
      this.$emit('deleteItem', this.activeItem)
    },
    uploadFile () {
      this.$refs.file.click()
    },
    readFile () {
      const file = this.$refs.file.files[0]
      let content = null
      const reader = new FileReader()
      if (file.name.includes('.json')) {
        reader.onload = (res) => {
          content = res.target.result
          this.$emit('uploadFile', content)
        }
        reader.onerror = (err) => console.log(err)
        reader.readAsText(file)
      } else {
        content = 'Operation failed. Check the console for file output'
        this.$emit('uploadFileFailed', content)
        reader.onload = (res) => {
          console.log(res.target.result)
        }
        reader.onerror = (err) => console.log(err)
        reader.readAsText(file)
      }
    }
  }
}
</script>
