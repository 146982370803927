import moment from 'moment'
import appSettings from '../configs/appSettings.js'

const dateFormatterMixin = {
  methods: {
    formatDate (date, format) {
      return date ? moment(date).format(format) : ''
    },
    formatDateUI (date) {
      return this.formatDate(date, appSettings.dateFormatUI)
    },
    formatDateDetailedUI (date) {
      return this.formatDate(date, appSettings.dateFormatDetailedUI)
    },
    formatDateBE (date) {
      if (typeof date === 'string' || date instanceof String) {
        date = new Date(date)
      }
      return moment(date).format()
    },
    formatDateDetailedBE (date) {
      return moment(date).format()
    }
  }
}

export default dateFormatterMixin
