<template>
  <div :class="{
          'checked': checked
        }">
    <input
      @input="(event) => $emit('update:checked',event.target.checked)"
      type="checkbox"
      :checked="checked"
      :id="fieldId"
      class="hidden"
    />
    <label
      :for="fieldId"
      class="flex flex-row items-center cursor-pointer select-none"
    >
      <i
        class="custom-checkbox mr-2"
        :class="{
          'checked': checked
        }"
      ></i>
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    label: {
      type: String,
      required: true
    },
    fieldId: {
      type: [Number, String],
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    }
  }
}
</script>
