<template>
  <Column class="mb-6">
    <p class="mb-2">Filter applicability:</p>
    <div class="flex -mx-2">
      <div class="w-1/3 px-2">
        <Options :label="'Choose the domain:'"
                  :fetchSettings="domainFetchSettings"
                  :selectedItems="domainsSelectedItems"
                  :selectable="true"
                  :multiselect="false"
                  :optionType="'domain'"
                  @optionChange="onOptionChange"/>
      </div>
      <div class="w-1/3 px-2">
        <Options v-if="isSourceOfEventsVisible"
                  :label="'Choose the source of events:'"
                  :fetchSettings="sourceOfEventsFetchSettings"
                  :selectedItems="sourceOfEventsSelectedItems"
                  :selectable="true"
                  :multiselect="false"
                  :optionType="'source'"
                  @optionChange="onOptionChange"/>
      </div>
      <div class="w-1/3 px-2">
        <Options v-if="isTypeOfSourceEventVisible"
                  :label="'Type of source events:'"
                  :fetchSettings="typeOfSourceEventFetchSettings"
                  :selectedItems="typeOfSourceEventSelectedItems"
                  :selectable="true"
                  :multiselect="false"
                  :optionType="'eventType'"
                  @optionChange="onOptionChange"/>
      </div>
    </div>
  </Column>
</template>

<script>
import Column from '@/components/common/ui/Column.vue'
import Options from '@/components/common/Options.vue'
import endpoints from '@/configs/endpoints.js'

export default {
  name: 'AddEditFilterFilters',
  props: {
    selectedFilters: {
      type: Object,
      required: true
    }
  },
  components: {
    Column,
    Options
  },
  data () {
    return {
      domainFetchSettings: {
        url: endpoints.domainsUI,
        params: {}
      },
      activeFilters: {},
      activeDomainIds: [],
      activeSourceOfEventsIds: [],
      activeTypeOfSourceEventIds: []
    }
  },
  computed: {
    domainsSelectedItems () {
      return this.selectedFilters.domains ? this.selectedFilters.domains.map(item => ({ id: item })) : []
    },
    isSourceOfEventsVisible () {
      return this.activeDomainIds.length
    },
    sourceOfEventsFetchSettings () {
      let fetchSettings = {}
      if (this.activeDomainIds.length) {
        fetchSettings = {
          url: endpoints.sourcesUI,
          params: {
            domain: this.activeDomainIds
          }
        }
      }
      return fetchSettings
    },
    sourceOfEventsSelectedItems () {
      return this.selectedFilters.sources ? this.selectedFilters.sources.map(item => ({ id: item })) : []
    },
    isTypeOfSourceEventVisible () {
      return this.activeSourceOfEventsIds.length
    },
    typeOfSourceEventFetchSettings () {
      let fetchSettings = {}
      if (this.activeSourceOfEventsIds.length) {
        fetchSettings = {
          url: endpoints.eventTypesUI,
          params: {
            domain: this.activeDomainIds,
            source: this.activeSourceOfEventsIds
          }
        }
      }
      return fetchSettings
    },
    typeOfSourceEventSelectedItems () {
      return this.selectedFilters.types ? this.selectedFilters.types.map(item => ({ id: item })) : []
    }
  },
  mounted () {
    if (this.selectedFilters) {
      this.activeDomainIds = this.selectedFilters.domains
      this.activeSourceOfEventsIds = this.selectedFilters.sources
      this.activeTypeOfSourceEventIds = this.selectedFilters.types
    }
  },
  watch: {
    selectedFilters ({ domains, sources, types }, prevFilters) {
      if (Object.keys(prevFilters).length === 0) {
        this.activeDomainIds = domains
        this.activeSourceOfEventsIds = sources
        this.activeTypeOfSourceEventIds = types
        this.activeFilters = {
          domain: domains,
          source: sources,
          eventType: types
        }
      }
    }
  },
  methods: {
    onOptionChange (option) {
      this.setFilters(option)
      if (option.type === 'domain') {
        this.activeDomainIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.id) : []
        this.activeSourceOfEventsIds = []
        this.activeTypeOfSourceEventIds = []
      }

      if (option.type === 'source') {
        this.activeSourceOfEventsIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.id) : []
        this.activeTypeOfSourceEventIds = []
      }

      if (option.type === 'eventType') {
        this.activeTypeOfSourceEventIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.id) : []
      }
    },
    setFilters (option) {
      if (option.activeOptions.length > 0) {
        this.activeFilters[option.type] = option.activeOptions.map(obj => obj.id)
      } else {
        delete this.activeFilters[option.type]
      }
      this.$emit('filtersChange', this.activeFilters)
    }
  }
}
</script>
