<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperImportRule"
      @keydown.esc="close"
      :tabindex="0">
    <div class="popup popup-big overflow-auto">
      <p class="mb-4">File name</p>
      <div class="flex">
        <input class="file-input" type="file" ref="doc" @change="readFile()" />
        <CustomCheckbox :fieldId="'dryMode'"
                        v-model:checked="dryMode"
                        :label="'Dry run mode'"
                        class="mt-2" />
       </div>
      <CustomButton @clicked="upload"
                :disabled="!file"
                class="w-24 mt-6">
                Upload
      </CustomButton>
      <p class="my-4">Outcome <span v-html="errorLabel" class="text-red-600"></span></p>
      <CodeEditor :readonly="true"
                  :code="outCome"
                  :language="'json'"
                  :loading="false"
                  :height="'h-80'"
                  class="outcome"
                  :class="outComeStatus"/>
      <div class="flex justify-end">
        <CustomButton @clicked="close"
                    class="mt-4 w-24">
                    Close
        </CustomButton>
      </div>
    </div>
  </div>
  <Preloader v-if="loading" :type="'full'"/>
</template>

<script>
import requestDataMixin from '@/mixins/requestDataMixin.js'
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import endpoints from '@/configs/endpoints'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import CustomCheckbox from '@/components/common/custom/CustomCheckbox.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'
import Preloader from '@/components/common/ui/Preloader.vue'

export default {
  name: 'ImportRulePopup',
  mixins: [requestDataMixin, stringifyDataMixin],
  emits: ['popupEvent'],
  components: {
    CustomButton,
    CustomCheckbox,
    CodeEditor,
    Preloader
  },
  data () {
    return {
      file: null,
      content: null,
      dryMode: false,
      outCome: '',
      outComeStatus: null,
      loading: false,
      errorLabel: ''
    }
  },
  mounted () {
    this.$refs.wrapperImportRule.focus()
  },
  methods: {
    readFile () {
      this.file = this.$refs.doc.files[0]
      const reader = new FileReader()
      if (this.file.name.includes('.json')) {
        reader.onload = (res) => {
          this.content = res.target.result
        }
        reader.onerror = (err) => console.log(err)
        reader.readAsText(this.file)
      } else {
        this.content = 'check the console for file output'
        reader.onload = (res) => {
          console.log(res.target.result)
        }
        reader.onerror = (err) => console.log(err)
        reader.readAsText(this.file)
      }
    },
    async upload () {
      const url = endpoints.rulesImportUI
      const params = { test: this.dryMode }
      const data = JSON.parse(this.content)
      const [error, response] = await this.requestData('post', url, params, data, 'loading')
      if (error) {
        this.outComeStatus = 'error'
        this.errorLabel = ' - Import failed'
      } else {
        this.outComeStatus = 'success'
        this.errorLabel = ''
      }

      this.outCome = this.stringifyData(error ? error.response.data : response)
    },
    close () {
      this.$emit('popupEvent')
    }
  }
}
</script>
