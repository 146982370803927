<template>
  <template v-if="viewEnabled">
    <FilterTypePanel :label="'Domain list'"
                    :sourceTypeLabel="'domain'"
                    :sourceTypeName="'domains'"
                    :parentObj="null"
                    @optionChange="onOptionChange"
                    />
   <FilterTypePanel v-if="!!sourceSystemsPanelEnabled"
                    :key="sourceSystemsPanelEnabled"
                    :label="'Source systems'"
                    :sourceTypeLabel="'source'"
                    :sourceTypeName="'sources'"
                    :parentObj="sourceParentObj"
                    @optionChange="onOptionChange"/>
     <FilterTypePanel v-if="!!eventTypesPanelEnabled"
                    :key="eventTypesPanelEnabled"
                    :label="'Event types'"
                    :sourceTypeLabel="'event type'"
                    :sourceTypeName="'eventTypes'"
                    :parentObj="eventTypeParentObj"/>
  </template>
</template>
<script>
import FilterTypePanel from '@/components/eventSources/FilterTypePanel.vue'

export default {
  name: 'EventSources',
  components: {
    FilterTypePanel
  },
  data () {
    return {
      selectedDomainId: null,
      selectedSourceId: null
    }
  },
  computed: {
    viewEnabled () {
      return this.$store._state.data.user.userInfo && this.$store._state.data.user.userInfo.superuser
    },
    sourceSystemsPanelEnabled () {
      return this.selectedDomainId
    },
    eventTypesPanelEnabled () {
      return this.selectedSourceId
    },
    sourceParentObj () {
      return { domain: this.selectedDomainId }
    },
    eventTypeParentObj () {
      return { source: this.selectedSourceId }
    }
  },
  methods: {
    onOptionChange ({ type, activeOptions }) {
      switch (type) {
        case 'domains':
          this.selectedDomainId = activeOptions.length ? activeOptions[0].id : null
          break
        case 'sources':
          this.selectedSourceId = activeOptions.length ? activeOptions[0].id : null
          break
      }
    }
  }
}
</script>
