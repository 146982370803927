<template>
  <div v-if="type === 'big' || type === 'full'"
        class="inset-0 flex justify-center items-center"
        :class="[type === 'full' ? 'loader fixed bg-black bg-opacity-5 z-10' : type === 'big' ? 'absolute': '']">
    <div class="preloader w-16 h-16"></div>
  </div>

  <div v-if="type === 'small'" class="preloader w-6 h-6"></div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>
