<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperFilterAffects"
      @keydown.esc="popuptEvent('edit')"
      :tabindex="0">
    <div class="popup">
      <p v-html="text" class="text-center mb-6"></p>
      <div class="flex items-center justify-center">
        <CustomButton @clicked="popuptEvent('save')"
                      class="mx-2 w-36">
          Yes, save changes
        </CustomButton>
          <CustomButton @clicked="popuptEvent('edit')"
                      class="w-36 mx-2">
          Back to editing
        </CustomButton>
        <CustomButton @clicked="popuptEvent('discard')"
                      class="w-36 mx-2">
          Discard
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/common/custom/CustomButton.vue'

export default {
  name: 'Popup',
  components: {
    CustomButton
  },
  props: {
    rulesAffecteNr: {
      type: Number,
      required: true
    }
  },
  mounted () {
    this.$refs.wrapperFilterAffects.focus()
  },
  computed: {
    text () {
      const string = this.rulesAffecteNr.length > 1
        ? this.rulesAffecteNr + ' rule(s). The change may affect all those rules'
        : this.rulesAffecteNr + ' rule. The change may affect this rule'
      return 'The rule you have modified is currently used by ' + string + ', immediately. Are you sure you want to save the changes?'
    }
  },
  methods: {
    popuptEvent (event) {
      this.$emit('popupEvent', event)
    }
  }
}
</script>
