<template>
  <Filters :viewEditRulesView="false"
          :auditDataView="true"
          :auditIndividual="false"
          :filtersConfiguration="filtersConfiguration"
          :dateRangeEnabled="true"
          :effectiveDateEnabled="false"
          :addEditFilterEnabled="false"
          @filtersChange="onFiltersChange"
          @submitFilters="onSubmitFilters"/>
  <ItemsTable v-if="isEventsPanelEnabled"
              :title="'Events'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="false"
              :eventTypesFilter="false"
              :fetchSettings="eventsFetchSettings"
              :tableSettings="eventsTableSettings"
              :selectable="true"
              :multiSelect="false"
              :defaultItem="null"
              :dateDetailed="false"
              @selectItems="onSelectedEventId"/>
  <EventDetails v-if="isEventsDetailsPanelEnabled"
                :fetchSettings="eventDetailsFetchSettings"
                :defaultDetails="null"
                :envelopeEnabled="true"
                :eventPayloadEnabled="true"
                :rulesRejectedEnabled="true"/>
</template>

<script>
import Filters from '@/components/common/filters/Filters.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import EventDetails from '@/components/common/EventDetails.vue'
import viewMixin from '@/mixins/viewMixin.js'
import endpoints from '@/configs/endpoints.js'

export default {
  name: 'AuditEventView',
  mixins: [viewMixin],
  components: {
    Filters,
    ItemsTable,
    EventDetails
  },
  data () {
    return {
      filtersConfiguration: {
        domain: {
          required: true,
          multiselect: false
        },
        source: {
          required: true,
          multiselect: false
        },
        eventType: {
          required: true,
          multiselect: false
        }
      },
      eventsTableSettings: {
        columnTitles: ['Event date', 'Event processed on', 'Rules executed', 'Rules skipped due to filters', 'Rules with errors'],
        columnKeys: ['timestamp', 'timestampProcessed', 'rulesExecuted', 'rulesSkipped', 'rulesErrors']
      },
      selectedEventId: null
    }
  },
  computed: {
    isEventsPanelEnabled () {
      return this.activeFilters && this.activeFilters.domain && this.searchStarted
    },
    eventsFetchSettings () {
      let settings = {}

      if (this.activeFilters) {
        settings = {
          url: endpoints.eventsUI,
          params: {
            eventTypeId: this.activeFilters.eventType[0],
            ...(this.activeFilters.date_from && { dateFrom: this.activeFilters.date_from }),
            ...(this.activeFilters.date_to && { dateTo: this.activeFilters.date_to })
          }
        }
      }

      return settings
    },
    isEventsDetailsPanelEnabled () {
      return this.selectedEventId && this.searchStarted
    },
    eventDetailsFetchSettings () {
      let settings = {}

      if (this.selectedEventId) {
        settings = {
          url: endpoints.eventDetailsUI + '/' + this.selectedEventId,
          params: { }
        }
      }

      return settings
    }
  },
  methods: {
    onSelectedEventId (event) {
      this.selectedEventId = event?.id || null
    }
  }
}
</script>
