<template>
  <Options :label="'Filters associated'"
            :fetchSettings="null"
            :options="allOtherFilters"
            :selectable="true"
            :multiselect="true"
            :selectedItems="selectedItems"
            :optionType="'active'"
            :parentLoading="false"
            :disableOnlyChildPreselect="false"
            @optionChange="onOptionChange"
            class="h-full"/>
  <div class="flex justify-end">
    <CustomButton :disabled="updatefiltersBtnDisabled"
                  @clicked="updatefilters"
                class="mt-2 mr-4">
            Update assosiated filters
    </CustomButton>
    <CustomButton @clicked="defineNewFilter"
                class="mt-2">
            Define new filter
    </CustomButton>
    <AddEditFilterPopup v-if="isAddFilterPopupEnabled"
                      :editMode="false"
                      :filterId="null"
                      @popupEvent="onAddEditFilterPopupEvent"/>
    <Popup v-if="isPopupEnabled"
        :text="popupText"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="onPopupEvent"/>
    <Preloader v-if="loading" :type="'full'"/>
  </div>
</template>

<script>
import Options from '@/components/common/Options.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import endpoints from '@/configs/endpoints'
import AddEditFilterPopup from '@/components/common/filters/addEditFilterPopup/AddEditFilterPopup.vue'
import Popup from '@/components/common/ui/Popup.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import areModelsEqualMixin from '@/mixins/areModelsEqualMixin.js'

export default {
  name: 'FiltersAssociated',
  emits: ['filtersAssigned', 'filtersSelectionChanged'],
  components: {
    Options,
    CustomButton,
    AddEditFilterPopup,
    Popup,
    Preloader
  },
  mixins: [requestDataMixin, areModelsEqualMixin],
  props: {
    ruleId: {
      type: String,
      required: true
    },
    otherFilters: {
      type: Array,
      required: true
    },
    filtersAssociated: {
      type: Array,
      retuired: true
    },
    activeFilters: {
      type: Object,
      retuired: true
    }
  },
  data () {
    return {
      selectedItemsIds: [],
      defaultSelectedItemsIds: [],
      newFilters: [],
      isAddFilterPopupEnabled: false,
      isPopupEnabled: false,
      popupText: ''
    }
  },
  computed: {
    allOtherFilters () {
      return [...this.otherFilters, ...this.newFilters]
    },
    updatefiltersBtnDisabled () {
      return this.areModelsEqual(this.selectedItemsIds, this.defaultSelectedItemsIds)
    },
    selectedItems () {
      return this.filtersAssociated.map(item => ({ id: item }))
    }
  },
  methods: {
    onOptionChange (option) {
      this.selectedItemsIds = option?.activeOptions.map(item => ({ id: item.id }))
      this.$emit('filtersSelectionChanged', this.selectedItemsIds)
    },
    async updatefilters () {
      const url = endpoints.rules + '/' + this.ruleId
      const data = { filters: this.selectedItemsIds.map(item => item.id) }

      const [error] = await this.requestData('patch', url, {}, data, 'loading')
      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.isPopupEnabled = true
        this.popupText = 'Assosiated filters updated.'
        this.defaultSelectedItemsIds = this.selectedItemsIds
        this.$emit('filtersAssigned', this.selectedItemsIds)
      }
    },
    defineNewFilter () {
      this.isAddFilterPopupEnabled = true
    },
    onAddEditFilterPopupEvent (data) {
      if (data && data.type === 'add') {
        this.newFilters.push(data.filter)
        this.isPopupEnabled = true
        this.popupText = 'Filter added.'
      }

      this.isAddFilterPopupEnabled = false
    },
    onPopupEvent () {
      this.isPopupEnabled = false
    }
  },
  watch: {
    filtersAssociated (newVal, oldVal) {
      if (newVal.length > 0 && oldVal.length === 0) {
        this.selectedItemsIds = newVal.map(item => ({ id: item }))
        this.defaultSelectedItemsIds = newVal.map(item => ({ id: item }))
      }
    }
  }
}
</script>
