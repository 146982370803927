import requestDataMixin from '@/mixins/requestDataMixin.js'
import appSettings from '@/configs/appSettings.js'

const manageReviewAccessMixin = {
  mixins: [requestDataMixin],
  data () {
    return {
      loading: false,
      dataProcessedPopupVisible: false,
      dataProcessedProperly: false,
      dataProcessedPopupText: ''
    }
  },
  methods: {
    async handleRequest (customParams, url, type) {
      const baseParams = {
        domain: this.activeFilters?.domain[0],
        source: this.activeFilters?.source[0],
        eventType: this.activeFilters?.eventType[0]
      }

      const params = Object.assign(customParams, baseParams)

      const [error, data] = await this.requestData(type, url, params, {}, 'loading')

      if (error || !data.success) {
        this.dataProcessedPopupText = type === 'delete' && data && data.detail ? 'User accesses cannot be removed. ' + data.detail : appSettings.messages.error
      } else {
        this.dataProcessedPopupText = 'Done.'
        this.dataProcessedProperly = true
      }

      this.dataProcessedPopupVisible = true
    },
    onDataProcessedPopupEvent () {
      this.dataProcessedPopupText = ''
      this.dataProcessedPopupVisible = false

      if (this.dataProcessedProperly) {
        this.$emit('dataUpdated')
        this.dataProcessedProperly = false
      }
    }
  }
}

export default manageReviewAccessMixin
