import axios from 'axios'
import appSettings from '@/configs/appSettings.js'
import { getJWTToken } from '@/services/auth0Service.js'

class ApiService {
  async axiosCall ({ method, url, params, requestData }) {
    let headers = null

    if (appSettings.environment === 'local') {
      if (appSettings.auth0Active) {
        const token = await getJWTToken()
        const tokenPayload = token.split('.')[1]

        headers = { 'X-Endpoint-API-UserInfo': tokenPayload }
      } else {
        headers = appSettings.axiosHeaders
      }
    } else {
      const token = await getJWTToken()
      headers = { Authorization: `Bearer ${token}` }
    }

    try {
      const { data } = await axios({
        method,
        url: appSettings.backendApiURL + url,
        data: requestData,
        params,
        headers
      })
      return [null, data]
    } catch (error) {
      return [error]
    }
  }

  async get (url, params, requestData) {
    return this.axiosCall({ method: 'get', url, params, requestData })
  }

  async post (url, params, requestData) {
    return this.axiosCall({ method: 'post', url, params, requestData })
  }

  async delete (url, params) {
    return this.axiosCall({ method: 'delete', url, params })
  }

  async put (url, params, requestData) {
    return this.axiosCall({ method: 'put', url, params, requestData })
  }

  async patch (url, params, requestData) {
    return this.axiosCall({ method: 'patch', url, params, requestData })
  }
}

export const api = new ApiService()
