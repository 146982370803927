<template>
  <h1>Prior data</h1>
  <div v-for="(state, index) in priorData" :key="`state-${index}`">
      <Column>
        <div class="">
          <div class="mb-4">Resource identifier:</div>
          <div class="container mb-4 flex-row space-y-4">
            <span v-for="(resource, resourceIdIndex) in state.resource_id" :key="`resource-${index}-${resourceIdIndex}`">
              <input
                :value="resource"
                @input="onResourceIdChange(index, resourceIdIndex, $event.target.value)"
                type="text"
                :class="'w-auto'"
              />
              <span v-if="!(resourceIdIndex == state.resource_id.length - 1)">
                <span class="ml-4 mr-4">/</span>
              </span>
            </span>
            <CustomButton :disabled="false"
                          :class="'ml-2'"
                          @clicked="onAddResourcePath(index)">
            &plus;
            </CustomButton>
            <CustomButton v-if="state.resource_id.length > 0"
                          :disabled="false"
                          :class="'ml-2'"
                          @clicked="onRemoveResourcePath(index)">
            &minus;
            </CustomButton>
          </div>
        </div>
        <DropdownOptions :label="'Data model validator:'"
                        :fetchSettings="modelsFetchSettings"
                        :selectedItem="state.model"
                        :allowNoChoice="true"
                        :labelNoChoice="'(no schema validation)'"
                        :class="'m-4'"
                        @optionChange="onModelChange(index, $event)"/>
        <div class="mb-4">Data:</div>
        <div v-if="state.data">
        <CodeEditor :readonly="false"
                  :code="state.dataStringified"
                  :language="'json'"
                  :loading="false"
                  :height="'h-40'"
                  :class="'m-4'"
                  @codeUpdated="onDataChange(index, $event)"/>
        </div>
        <div v-if="!state.data">
        <p>No past data</p>
        </div>
        <p v-if="priorDataErrors[index]" class="absolute text-red-500 text-xs">{{priorDataErrors[index]}}</p>
      </Column>
  </div>
</template>

<script>
import CodeEditor from '@/components/common/CodeEditor.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import Column from '@/components/common/ui/Column.vue'
import stringifyDataMixin from '@/mixins/stringifyDataMixin.js'
import DropdownOptions from '@/components/common/DropdownOptions.vue'
import endpoints from '@/configs/endpoints'

export default {
  name: 'Prior data',
  mixins: [stringifyDataMixin],
  emits: ['dataChanged', 'resourceIdChanged', 'modelChanged', 'modelUnselected'],
  components: {
    CodeEditor,
    CustomButton,
    Column,
    DropdownOptions
  },
  props: {
    data: {
      type: [null, Array],
      required: true
    }
  },
  data () {
    return {
      dataError: null,
      priorDataErrors: [],
      modelsFetchSettings: {
        url: endpoints.models,
        params: {}
      }
    }
  },
  computed: {
    priorData () {
      if (this.data) {
        return this.data.map(item => ({ ...item, dataStringified: this.stringifyData(item.data) }))
      } else {
        return []
      }
    }
  },
  watch: {
    priorData () {
      this.priorDataErrors = this.priorData.map(() => null)
    }
  },
  methods: {
    onDataChange (index, dataStringified) {
      try {
        const newData = JSON.parse(dataStringified)
        this.$emit('dataChanged', index, newData)
        this.priorDataErrors[index] = null
      } catch (error) {
        this.priorDataErrors[index] = error.message
      }
    },
    onModelChange (index, value) {
      if (value) {
        this.$emit('modelChanged', index, value.name)
      } else {
        this.$emit('modelUnselected', index)
      }
    },
    onResourceIdChange (index, resourceIdIndex, value) {
      this.priorData[index].resource_id[resourceIdIndex] = value
      this.$emit('resourceIdChanged', index, this.priorData[index].resource)
    },
    onAddResourcePath (index) {
      this.priorData[index].resource_id.push('')
      this.$emit('resourceIdChanged', index, this.priorData[index].resource)
    },
    onRemoveResourcePath (index) {
      this.priorData[index].resource_id.pop()
      this.$emit('resourceIdChanged', index, this.priorData[index].resource)
    }
  }
}
</script>
