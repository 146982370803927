<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperEventDataPicker"
      @keydown.esc="cancel"
      :tabindex="0">
    <div class="popup popup-big overflow-auto">
      <Heading class="mb-2 text-xl">Event data picker</Heading>
      <hr class="mb-6"/>
      <div class="w-3/5 mb-4">
        <div class="flex mb-2">
          <div class="flex items-center w-1/4">
            <p>Entity ID</p>
          </div>
          <div class="w-3/4">
            <CustomInput v-model='enityId'
                      :name="'enityId'"
                      :searchStyle="false"/>
          </div>
        </div>
        <div class="flex mb-2">
          <div class="flex items-center w-1/4">
            <p>Message ID</p>
          </div>
          <div class="w-3/4">
            <CustomInput v-model='messageId'
                      :name="'enityId'"
                      :searchStyle="false"/>
          </div>
        </div>
        <div class="flex mb-2">
          <div class="flex items-center w-1/4">
            <p>Date range</p>
          </div>
          <div class="flex w-3/4">
            <div class="w-1/2">
              <Datepicker v-model="timeRangeFrom"
                          :maxDate="timeRangeTo"
                          placeholder="Valid from"
                          class="w-full pr-2" />
            </div>
             <div class="w-1/2">
              <Datepicker v-model="timeRangeTo"
                          :minDate="timeRangeFrom"
                          placeholder="Valid from"
                          class="w-full pl-2" />
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex items-center w-1/4">
            <p>Correlation ID</p>
          </div>
          <div class="w-3/4">
            <CustomInput v-model='correlationId'
                      :name="'enityId'"
                      :searchStyle="false"/>
          </div>
        </div>
      </div>
      <CustomButton @clicked="searchEvents"
                      class="mb-4">
          Search events
      </CustomButton>
      <div class="flex mb-4">
        <CustomButton :disabled="pickSelectedEventButtonDisabled"
                      @clicked="pickSelectedEvent"
                      class="mr-4">
          Pick selected dataset
        </CustomButton>
        <CustomButton @clicked="cancel">
          Cancel
        </CustomButton>
      </div>
      <ItemsTable :title="'Events matching the critera'"
                  :searchFilter="false"
                  :searchKey="''"
                  :dateRangeFilter="false"
                  :eventTypesFilter="false"
                  :fetchSettings="{}"
                  :defaultItems="events"
                  :tableSettings="eventsSettings"
                  :selectable="true"
                  :multiSelect="false"
                  :defaultItem="null"
                  :dateDetailed="true"
                  @selectItems="onSelectedEvent"/>
      <EventDetails :fetchSettings="{}"
                    :defaultDetails="eventDetails"
                    :eventPayloadEnabled="true"
                    :envelopeEnabled="true"
                    :rulesRejectedEnabled="true"
                    @ruleSelected="onRuleSelected"
                    @ruleUnselected="onRuleUnselected"/>
    </div>
  </div>
</template>

<script>
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import Heading from '@/components/common/ui/Heading.vue'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import EventDetails from '@/components/common/EventDetails.vue'
import endpoints from '@/configs/endpoints.js'

export default {
  name: 'EventDataPickerPopup',
  mixins: [dateFormatterMixin, requestDataMixin],
  emits: ['datasetSelected', 'cancelled'],
  components: {
    Heading,
    CustomInput,
    Datepicker,
    ItemsTable,
    CustomButton,
    EventDetails
  },
  props: {
    activeFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      enityId: '',
      messageId: '',
      timeRangeFrom: null,
      timeRangeTo: null,
      correlationId: '',
      eventsSettings: {
        columnTitles: ['Entity ID', 'Message ID', 'Timestamp', 'Correlation ID'],
        columnKeys: ['principalId', 'messageId', 'timestamp', 'correlationId']
      },
      events: [],
      eventDetails: null,
      selectedEventId: null,
      eventDeteailsFetchSettings: null,
      ruleSelected: null
    }
  },
  mounted () {
    this.$refs.wrapperEventDataPicker.focus()
  },
  computed: {
    pickSelectedEventButtonDisabled () {
      return !this.selectedEventId || !this.ruleSelected
    }
  },
  methods: {
    onRuleSelected (rule) {
      this.ruleSelected = rule
    },
    onRuleUnselected () {
      this.ruleSelected = null
    },
    async searchEvents () {
      const data = {
        eventTypeId: this.activeFilters?.eventType[0],
        messageId: this.messageId,
        principalId: this.enityId,
        correlationId: this.correlationId
      }

      if (this.timeRangeFrom) {
        data.dateFrom = this.formatDateDetailedBE(this.timeRangeFrom)
      }

      if (this.timeRangeTo) {
        data.dateTo = this.formatDateDetailedBE(this.timeRangeTo)
      }

      const [error, response] = await this.requestData('get', endpoints.dataPickerEvents, data, {}, 'loading')

      if (error) {
        if (!error.response.data) {
          this.$store.commit('catchError', error.response.status)
        } else {
          this.$store.commit('catchError', error.response.data.detail)
        }
      } else {
        this.events = response
      }
    },
    onSelectedEvent (event) {
      this.selectedEventId = event?.id || null

      if (this.selectedEventId) {
        this.fetchEventDetails()
      } else {
        this.eventDetails = {}
      }
    },
    async fetchEventDetails () {
      const url = endpoints.eventDetailsUI + '/' + this.selectedEventId
      const [error, response] = await this.requestData('get', url, {}, {}, 'loading')

      if (error) {
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.eventDetails = response
      }
    },
    pickSelectedEvent () {
      this.$emit('datasetSelected', {
        payload: this.eventDetails.payload,
        attributes: this.eventDetails.attributes,
        priorData: this.ruleSelected.before_event
      })
    },
    cancel () {
      this.$emit('cancelled')
    }
  }
}
</script>
