<template>
    <div class="nav">
        <router-link @click="linkClicked" class="router-link" to="/view-edit-rules">View or edit rules</router-link>
        <router-link @click="linkClicked" class="router-link" to="/audit-individual">Audit inividual</router-link>
        <router-link @click="linkClicked" class="router-link" to="/audit-event">Audit event</router-link>
        <router-link @click="linkClicked" class="router-link" to="/audit-rule">Audit rule</router-link>
        <router-link @click="linkClicked" class="router-link" to="/audit-user">Audit user</router-link>
        <router-link @click="linkClicked" class="router-link" to="/manage-access">Manage access</router-link>
        <router-link @click="linkClicked" class="router-link" to="/review-access">Review access</router-link>
        <router-link @click="linkClicked" class="router-link" to="/event-sources">Event sources</router-link>
        <router-link v-if="isSuperUser" @click="linkClicked" class="router-link" to="/storage">Storage</router-link>
        <router-link v-if="isSuperUser" @click="linkClicked" class="router-link" to="/notifications">Notifications</router-link>
        <button @click="logout"
                class="router-link">
          Logout
        </button>
    </div>
</template>

<script>
import appSettings from '@/configs/appSettings.js'
import { auth0Service } from '@/services/auth0Service.js'

export default {
  name: 'Navigation',
  data () {
    return {
      auth0: null
    }
  },
  created () {
    if (appSettings.auth0Active) {
      this.auth0 = auth0Service()
    }
  },
  computed: {
    isSuperUser () {
      return this.$store._state.data.user.userInfo.superuser
    }
  },
  methods: {
    async logout () {
      if (appSettings.auth0Active) {
        if (!this.auth0) {
          return
        }

        await this.auth0.logout({
          returnTo: process.env.VUE_APP_AUTH0_CALLBACK_URL
        })
      } else {
        this.$router.push('/')
      }
    },
    linkClicked () {
      this.$emit('linkClicked')
    }
  }
}
</script>
