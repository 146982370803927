<template>
  <div class="flex">
    <div class="mx-1 flex justify-center">
        <div :class="{ invisible: loading }">
          <Datepicker v-model="rangeDateFrom"
                      :maxDate="rangeDateTo"
                      :enableTimePicker="false"
                      placeholder="Valid from"
                      class="w-full"
                      :class="{ invisible: loading }"/>
        </div>
        <div class="absolute">
          <Preloader v-if="loading"
                  :type="'small'"/>
        </div>
    </div>
    <div class="mx-1 flex justify-center">
      <div :class="{ invisible: loading }">
        <Datepicker v-model="rangeDateTo"
                    :minDate="rangeDateFrom"
                    :enableTimePicker="false"
                    placeholder="Valid to"
                    class="w-full"
                    :class="{ invisible: loading }" />
      </div>
      <div class="absolute">
        <Preloader v-if="loading"
                  :type="'small'"/>
      </div>
      <button v-if="!loading"
              @click="save()"
              :disabled="isSaveRangeBtnDisabled"
              class="apply-data">
        <img src='@/assets/icons/icon-check-blue.svg' class="w-6 h-6" alt="save"/>
      </button>
    </div>
  </div>
  <Popup v-if="dateRangeErrorPopupVisible"
        :text="'Selected date coincides with a date range from another version.'"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="dateRangeErrorPopupEvent"/>
</template>

<script>
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Preloader from '@/components/common/ui/Preloader.vue'
import Popup from '@/components/common/ui/Popup.vue'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import endpoints from '@/configs/endpoints'

export default {
  name: 'RuleVersionDateRange',
  mixins: [dateFormatterMixin, requestDataMixin],
  emits: ['versionUpdate'],
  components: {
    Datepicker,
    Preloader,
    Popup
  },
  props: {
    ruleId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    validFrom: {
      type: String,
      required: true
    },
    validTo: {
      type: String,
      required: true
    },
    versions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      rangeDateFrom: null,
      rangeDateTo: null,
      loading: false,
      dateRangeErrorPopupVisible: false,
      rangeDateFromOld: null,
      rangeDateToOld: null
    }
  },
  computed: {
    isSaveRangeBtnDisabled () {
      return (this.rangeDateFrom === null && this.rangeDateTo === null) ||
             ((this.formatDateUI(this.rangeDateFrom) === this.formatDateUI(this.rangeDateFromOld)) &&
             (this.formatDateUI(this.rangeDateTo) === this.formatDateUI(this.rangeDateToOld)))
    }
  },
  mounted () {
    this.rangeDateFrom = this.validFrom && this.validFrom !== 'Invalid date' ? new Date(this.validFrom) : null
    this.rangeDateFromOld = this.validFrom && this.validFrom !== 'Invalid date' ? new Date(this.validFrom) : null
    this.rangeDateTo = this.validTo && this.validTo !== 'Invalid date' ? new Date(this.validTo) : null
    this.rangeDateToOld = this.validTo && this.validTo !== 'Invalid date' ? new Date(this.validTo) : null
  },
  methods: {
    save () {
      if (!this.active || this.validateDate(this.rangeDateFrom, this.rangeDateTo)) {
        const dateFrom = this.rangeDateFrom && this.rangeDateFrom !== 'Invalid date' ? this.formatDateUI(this.rangeDateFrom) : null
        const dateFromOld = this.rangeDateFromOld && this.rangeDateFromOld !== 'Invalid date' ? this.formatDateUI(this.rangeDateFromOld) : null

        if (dateFrom !== dateFromOld) {
          this.saveData({ validFrom: this.rangeDateFrom ? this.formatDateBE(this.rangeDateFrom) : null })
        }

        const dateTo = this.rangeDateTo && this.rangeDateTo !== 'Invalid date' ? this.formatDateUI(this.rangeDateTo) : null
        const dateToOld = this.rangeDateToOld && this.rangeDateToOld !== 'Invalid date' ? this.formatDateUI(this.rangeDateToOld) : null

        if (dateTo !== dateToOld) {
          this.saveData({ validTo: this.rangeDateTo ? this.formatDateBE(this.rangeDateTo) : null })
        }

        this.rangeDateFromOld = this.rangeDateFrom
        this.rangeDateToOld = this.rangeDateTo
      } else {
        this.dateRangeErrorPopupVisible = true
        this.rangeDateFrom = this.rangeDateFromOld
        this.rangeDateTo = this.rangeDateToOld
      }
    },
    areDateRangesConflicted (rangeDateFrom1, rangeDateTo1, rangeDateFrom2, rangeDateTo2) { // true = conflict exist
      if ((!rangeDateFrom1 && !rangeDateFrom2) || (!rangeDateTo1 && !rangeDateTo2)) {
        return true
      }

      let result = false

      if (rangeDateFrom1 && rangeDateTo1 && rangeDateFrom2 && rangeDateTo2) {
        result = (rangeDateFrom1 >= rangeDateFrom2 && rangeDateFrom1 <= rangeDateTo2) ||
                 (rangeDateTo1 >= rangeDateFrom2 && rangeDateTo1 <= rangeDateTo2) ||
                 (rangeDateFrom1 <= rangeDateFrom2 && rangeDateTo1 >= rangeDateTo2)
      } else if (rangeDateFrom1 && rangeDateTo1 && !rangeDateFrom2 && rangeDateTo2) {
        result = rangeDateFrom1 <= rangeDateTo2 || rangeDateTo1 <= rangeDateTo2
      } else if (rangeDateFrom1 && rangeDateTo1 && rangeDateFrom2 && !rangeDateTo2) {
        result = rangeDateFrom1 >= rangeDateFrom2 || rangeDateTo1 >= rangeDateFrom2
      } else if (!rangeDateFrom1 && rangeDateTo1 && rangeDateFrom2 && rangeDateTo2) {
        result = rangeDateTo1 >= rangeDateFrom2
      } else if (!rangeDateFrom1 && rangeDateTo1 && rangeDateFrom2 && !rangeDateTo2) {
        result = rangeDateTo1 >= rangeDateFrom2
      } else if (rangeDateFrom1 && !rangeDateTo1 && rangeDateFrom2 && rangeDateTo2) {
        result = rangeDateFrom1 <= rangeDateTo2
      } else if (rangeDateFrom1 && !rangeDateTo1 && !rangeDateFrom2 && rangeDateTo2) {
        result = rangeDateFrom1 <= rangeDateTo2
      }

      return result
    },
    validateDate (rangeDateFrom, rangeDateTo) {
      const versions = this.versions.filter(item => item.versionNum !== this.versionId)
      let result = true
      versions.forEach(item => {
        if (this.areDateRangesConflicted(rangeDateFrom, rangeDateTo, item.validFrom && item.validFrom !== 'Invalid date' ? new Date(item.validFrom) : null, item.validTo && item.validTo !== 'Invalid date' ? new Date(item.validTo) : null)) {
          result = false
        }
      })

      return result
    },
    async saveData (data) {
      const url = endpoints.rules + '/' + this.ruleId + '/versions/' + this.versionId
      const patchData = {
        validFrom: this.formatDateBE(this.rangeDateFrom),
        validTo: this.formatDateBE(this.rangeDateTo)
      }
      const [error] = await this.requestData('patch', url, {}, patchData, 'loading')

      if (error) {
        this.error = true
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.error = false
        this.$emit('versionUpdate')
      }
    },
    dateRangeErrorPopupEvent () {
      this.dateRangeErrorPopupVisible = false
    }
  }
}
</script>
