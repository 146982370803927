const appTheme = {
  state: {
    themeDarkMode: false
  },
  mutations: {
    changeThemeDarkMode (state, val) {
      state.themeDarkMode = val
    }
  }
}

export default appTheme
