<template>
    <div class="overflow-auto h-full">
        <div v-for="(funcs, category) in this.topics" v-bind:key="category">
          <p class="heading cursor-pointer" @click="active = 'help-' + category">{{category}}</p>
          <hr class="mb-4"/>
          <div :class="{hidden: active != 'help-' + category}" class="show">
            <div class="mb-4 p-1 border rounded" v-for="func in funcs" v-bind:key="func.name">
                <h5 class="text-sm cursor-pointer" @click="active2 = 'help-' + category + '-' + func.name"><b>{{func.name}}</b> - <i>{{func.short_desc}}</i></h5>
                <div :class="{hidden: active2 != 'help-' + category + '-' + func.name}" class="show text-xs">
                    <hr class="mb-2"/>
                    <p class="font-mono bg-blue-50 mb-2 p-1 rounded" v-html="genFuncCall(func)"></p>
                    <p class="mb-2" v-html="nl2br(code(func.long_desc))"></p>
                    <div v-if="func.params.length">
                      <p class="heading font-bold">Params:</p>
                      <ul v-for="param in func.params" v-bind:key="param.name" class="ml-4">
                          <li><span class="font-mono"><b>{{param.name}}</b>:<i>{{param.type}}</i></span> - {{param.description}}</li>
                      </ul>
                    </div>
                    <div v-if="func.raises.length">
                      <p class="heading font-bold">Raises:</p>
                      <ul v-for="raise in func.raises" v-bind:key="raise.type" class="ml-4">
                          <li><span class="font-mono"><b><i>{{raise.type}}</i></b></span> - {{raise.description}}</li>
                      </ul>
                    </div>
                    <div v-if="func.returns">
                      <p class="heading font-bold">Returns:</p>
                      <ul class="ml-4">
                          <li><span class="font-mono"><b><i>{{func.returns.type}}</i></b></span> - {{func.returns.description}}</li>
                      </ul>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <Preloader v-if="exportRuleLoading" :type="'full'"/>
        <Preloader v-if="dataProcessing" :type="'full'"/>
    </div>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import endpoints from '@/configs/endpoints'
import Preloader from '@/components/common/ui/Preloader.vue'

export default {
  name: 'RulesHelp',
  mixins: [fetchDataMixin, requestDataMixin],
  components: {
    Preloader
  },
  props: {},
  data () {
    return {
      fetchSettingsUrl: endpoints.rulesEngineHelp,
      active: '',
      active2: ''
    }
  },
  computed: {
    fetchSettings () {
      return {
        url: this.fetchSettingsUrl,
        params: this.fetchParams
      }
    },
    topics () {
      return this.fetchedData || {}
    }
  },
  methods: {
    nl2br (str) {
      if (str) {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
      } else {
        return ''
      }
    },
    code (str) {
      if (str) {
        str = str.replace(/`{3}[\r\n]?([^`]+)[\r\n]?`{3}[\r\n]?/gm, '<p class="font-mono text-xs bg-gray-100 mb-2 p-1 rounded">$1</p>')
        str = str.replace(/`([^`]+)`/g, '<span class="font-mono text-xs bg-gray-100 pl-1 pr-1 rounded">$1</span>')
        return str
      } else {
        return ''
      }
    },
    genFuncCall (func) {
      if (func) {
        var args = []
        for (const param of func.params) {
          if (!param.internal) {
            if (param.type) {
              args.push(param.name + ': <i>' + param.type + '</i>')
            } else {
              args.push(param.name)
            }
          }
        }
        var ret = ''
        if (func.returns) {
          ret = ' -> ' + func.returns.type
        }
        return '<b>' + func.name + '</b>(' + args.join(', ') + ')' + ret
      } else {
        return ''
      }
    }
  }
}
</script>
