<template>
  <div class="login-page h-screen">
    <Transition name="slide-fade" appear>
      <div class="login-box">
        <img class="h-16 mt-8 my-4" src="../assets/icons/icon-lock.svg"/>
        <h1 class="text-2xl mb-6">Data Enhancement Service</h1>
        <CustomButton @clicked="login">
            Log in
        </CustomButton>
      <p class="mt-2">
        Authorized access only
      </p>
      </div>
    </Transition>
    <img class="login-bg" src="../assets/backgrounds/login.svg"/>
  </div>
</template>

<script>
import CustomButton from '@/components/common/custom/CustomButton.vue'
import appSettings from '@/configs/appSettings.js'
import { auth0Service } from '@/services/auth0Service.js'

export default {
  name: 'LoginView',
  components: { CustomButton },
  data () {
    return {
      auth0: null
    }
  },
  created () {
    if (appSettings.auth0Active) {
      this.auth0 = auth0Service()
    }
  },
  methods: {
    login () {
      if (appSettings.auth0Active) {
        if (!this.auth0) {
          return
        }

        this.auth0.login()
      } else {
        this.$router.push('/view-edit-rules')
      }
    }
  }
}
</script>
