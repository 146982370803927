const arrayToStringFormatterMixin = {
  methods: {
    arrayToStringFormatter (array) {
      if (array && array.length) {
        let formattedString = ''

        for (let i = 0; i < array.length; i++) {
          formattedString += array[i] + '\n'
        }

        return formattedString
      }

      return ''
    }
  }
}

export default arrayToStringFormatterMixin
