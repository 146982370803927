<template>
  <Panel>
    <Options :label="label"
            :fetchSettings='fetchSettings'
            :selectable="true"
            :multiselect="false"
            :selectedItems="[]"
            :optionType="'individuals'"
            :itemListWithEmail="itemListWithEmail"
            @optionChange="onOptionChange"
            ref="options"/>
  </Panel>
</template>

<script>
import Panel from '@/components/common/ui/Panel.vue'
import Options from '@/components/common/Options.vue'

export default {
  name: 'ItemsList',
  components: {
    Panel,
    Options
  },
  props: {
    label: {
      type: String,
      required: true
    },
    fetchSettings: {
      type: Object,
      required: true
    },
    itemListWithEmail: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    onOptionChange (option) {
      this.$emit('selectedItemChange', option.activeOptions.length > 0 ? option.activeOptions[0] : null)
    },
    fetchData () {
      this.$refs.options.fetchData()
    }
  }
}
</script>
