<template>
  <heading>{{heading}}</heading>
  <div class="mb-4" :class="[themeClass]">
      <div class="h-auto max-h-64 overflow-auto">
          <pre>{{content}}</pre>
      </div>
  </div>
</template>

<script>
import Heading from '@/components/common/ui/Heading.vue'

export default {
  components: {
    Heading
  },
  props: {
    heading: {
      type: String,
      required: false
    },
    content: {
      type: [Object, String],
      required: false
    }
  },
  computed: {
    themeClass () {
      return this.$store.state.appTheme.themeDarkMode ? 'bg-gray-700' : ' bg-gray-100'
    }
  }
}
</script>
