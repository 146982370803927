import { createApp } from 'vue'
import Shell from './Shell.vue'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import axios from 'axios'
import './styles/index.css'
import VueDiff from 'vue-diff'
import 'vue-diff/dist/index.css'

createApp(Shell).use(VueDiff).use(store).use(router).use(VueAxios, axios).mount('#app')
