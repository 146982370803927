<template>
    <div class="w-full">
        <p class="mb-4">Rules considered but not applied because of filters:</p>
        <Options :label="''"
                :fetchSettings='{}'
                :options="rulesRejected"
                :selectable="true"
                :multiselect="false"
                :selectedItems="[]"
                :optionType="'rulesRejected'"
                @optionChange="onRejectedRuledChange"/>
    </div>
    <div  class="w-full">
      <div v-if="isRejectedRuleDetailsVisible">
        <p class="mb-4">Filters that prevented rule from being applied</p>
        <Options :label="''"
                  :fetchSettings='{}'
                  :options="filtersPreventingRuleApplication"
                  :selectable="true"
                  :multiselect="false"
                  :selectedItems="[]"
                  :optionType="'rulesRejected'"
                  @optionChange="onFilterPreventingRuleApplicationChange"/>
        <div v-if="filterSelected">
          <p class="my-4">Filter expression</p>
          <CodeEditor :readonly="true"
                      :code="selectedPreventingFilterDefinition"
                      :language="'python'"
                      :loading="loading"
                      :height="'h-56'"
                      class="mb-4"/>
          <CustomPre v-if="selectedPreventingFilterException"
                      :heading="'Filter exception'"
                      :content="selectedPreventingFilterException"/>
          <CustomPre :heading="'Filter returned'"
                      :content="selectedPreventingFilterReturned"/>
          <CustomPre v-if="selectedPreventingFilterLogOutput"
                    :heading="'Filter log output'"
                    :content="selectedPreventingFilterLogOutput"/>
          <p>Evaluating the filter took {{selectedPreventingFilterPerf}} ms</p>
        </div>
      </div>
    </div>
</template>

<script>
import Options from '@/components/common/Options.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'
import CustomPre from '@/components/common/custom/CustomPre.vue'

export default {
  name: 'RejectedRuleDetails',
  components: {
    Options,
    CodeEditor,
    CustomPre
  },
  props: {
    details: {
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selectedRejectedRule: null,
      filtersPreventingRuleApplication: [],
      selectedPreventingFilterDefinition: '',
      selectedPreventingFilterReturned: '',
      selectedPreventingFilterException: '',
      selectedPreventingFilterLogOutput: '',
      selectedPreventingFilterPerf: 0,
      filterSelected: false
    }
  },
  computed: {
    isRejectedRuleDetailsVisible () {
      return this.selectedRejectedRule
    },
    rulesRejected () {
      if (this.details && this.details.rules_rejected) {
        const rejected = []
        for (const ruleRejected of this.details.rules_rejected) {
          const filters = []
          for (const filterId of ruleRejected.rejected_by_filter_ids) {
            const filterName = this.details.filter_outcomes[filterId].name ? this.details.filter_outcomes[filterId].name : filterId
            filters.push({
              id: filterId,
              name: filterName,
              outcome: this.details.filter_outcomes[filterId].outcome,
              expression: this.details.filter_outcomes[filterId].expression,
              logOutput: this.details.filter_outcomes[filterId].log,
              exception: this.details.filter_outcomes[filterId].exception,
              perf: this.details.filter_outcomes[filterId].perf
            })
          }
          rejected.push({
            name: ruleRejected.rule_name,
            id: ruleRejected.rule_id,
            filters: filters
          })
        }
        return rejected
      } else {
        return []
      }
    }
  },
  methods: {
    onRejectedRuledChange (filter) {
      const rule = filter && filter.activeOptions[0] ? filter.activeOptions[0] : null
      this.selectedRejectedRule = rule
      this.filtersPreventingRuleApplication = rule?.filters || []
    },
    onFilterPreventingRuleApplicationChange (filter) {
      const preventingFilter = filter?.activeOptions[0] || null
      this.filterSelected = filter?.activeOptions[0]
      this.selectedPreventingFilterDefinition = preventingFilter?.expression || ''
      this.selectedPreventingFilterReturned = `${preventingFilter?.outcome}` || ''
      this.selectedPreventingFilterException = preventingFilter?.exception || ''
      this.selectedPreventingFilterLogOutput = preventingFilter?.logOutput || ''
      this.selectedPreventingFilterPerf = preventingFilter?.perf || '0'
    }
  }
}
</script>
