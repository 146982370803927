const _ = require('lodash')
const areModelsEqualMixin = {
  methods: {
    areModelsEqual (a, b) {
      if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length) {
          return false
        }

        for (let i = 0; i < a.length; i++) {
          if (!_.isEqual(a[i], b[i])) {
            return false
          }
        }
        return true
      } else {
        return _.isEqual(a, b)
      }
    }
  }
}

export default areModelsEqualMixin
