<template>
  <div class="fixed inset-0 w-full flex items-center justify-center bg-black bg-opacity-10 z-10"
      ref="wrapperAddValue"
      @keydown.esc="popuptEvent(false)"
      :tabindex="0">
    <div class="popup">
      <p class="text-center mb-2 font-bold">{{title}}</p>
      <p class="text-center mb-4">{{text}}</p>
      <div class="flex justify-center w-56 mx-auto">
        <CustomInput v-model='input'
                      :name="'identifier'"
                      :searchStyle="false"
                      class="mb-6"/>
      </div>
      <div class="flex items-center justify-center">
        <CustomButton @clicked="popuptEvent(false)"
          class="w-36 mx-2">
          {{cancelText}}
        </CustomButton>
        <CustomButton :disabled="addAccesButtonDisabled"
          @clicked="popuptEvent(true)"
          class="mx-2 w-36">
          {{confirmText}}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from '@/components/common/custom/CustomInput.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import validateEmailMixin from '@/mixins/validateEmailMixin.js'
import validateStringMixin from '@/mixins/validateStringMixin.js'

export default {
  name: 'AddValuePopup',
  mixins: [validateEmailMixin, validateStringMixin],
  components: {
    CustomInput,
    CustomButton
  },
  props: {
    valueType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    cancelText: {
      type: String,
      required: false
    },
    confirmText: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      input: ''
    }
  },
  mounted () {
    this.$refs.wrapperAddValue.focus()
  },
  computed: {
    addAccesButtonDisabled () {
      let result = false
      if (this.valueType === 'email') {
        result = !this.validateEmail(this.input)
      } else if (this.valueType === 'string') {
        result = !this.validateString(this.input)
      }

      return result
    }
  },
  methods: {
    popuptEvent (event) {
      this.$emit('popupEvent', event, this.input)
    }
  }
}
</script>
