<template>
  <Panel id="ruleDetails">
    <div class="mb-4">
      <p class="mb-2 text-center">Rule details</p>
      <hr/>
    </div>
    <div class="flex items-center mb-4">
      <label>Name:</label>
      <CustomInput v-model="ruleName"
          :name="'rule-name'"
          :disabled="true"
          :searchStyle="false"
          class="ml-2"/>
    </div>
    <div class="flex -mx-2 mb-4">
      <div class="w-1/3 px-2 flex items-center">
        <label class="whitespace-nowrap">Valid from: </label>
        <Datepicker v-model="versionValidFrom"
              :disabled="!editingEnabled"
              :enableTimePicker="false"
              placeholder=""
              class="w-full ml-2"/>
      </div>
      <div class="w-1/3 px-2 flex items-center">
        <label class="whitespace-nowrap">Valid to: </label>
        <Datepicker v-model="versionValidTo"
              :disabled="!editingEnabled"
              :enableTimePicker="false"
              placeholder=""
              class="w-full ml-2"/>
      </div>
      <div class="flex w-1/3 items-center px-2">
        <label class="whitespace-nowrap">Version: </label>
        <CustomInput v-model="versionNum"
                    :name="'rule-active-version'"
                    :disabled="!editingEnabled"
                    :searchStyle="false"
                    class="ml-2"/>
      </div>
    </div>
    <div class="w-full">
      <Column class="flex flex-col">
        <Heading>Rule definition</Heading>
        <CodeEditor :readonly="codeEditorReadOnly"
                  :code="versionCode"
                  :language="'python'"
                  :loading="loading"
                  :height="'h-96'"
                  :showHelp="true"
                  @codeUpdated="onVersionCodeUpdated"/>
        <p v-if="versionCodeError" class="text-red-500 text-xs mt-0.5">Field is empty</p>
      </Column>
    </div>
    <div class="w-full">
      <Column class="relative flex flex-col h-72 overflow-auto">
        <Heading>Edit history</Heading>
        <table class="w-full items-table">
          <thead>
            <tr class="border-b border-gray-200">
              <td class="pb-2">Version</td>
              <td class="pb-2">Edited on</td>
              <td class="pb-2">Edited by</td>
              <td class="pb-2">Active</td>
              <td class="pb-2">Date range</td>
              <td class="pb-2"></td>
              <td class="pb-2"></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="version in versions"
              :key="version"
              :class="{
                'activeItem': version.active
              }"
            >
              <td class="py-2">{{version.versionNum}}</td>
              <td class="py-2">{{version.editedOn}}</td>
              <td class="py-2">{{version.editedBy}}</td>
              <td class="py-2 pr-2">
                <RuleVersionStatus :versionActive="version.active"
                                    :ruleId="ruleId"
                                    :versionId="version.id"
                                    @versionUpdate="onVersionUpdate"/>
              </td>
              <td class="py-2">
                <RuleVersionDateRange :ruleId="ruleId"
                                      :versionId="version.id"
                                      :validFrom="version.validFrom"
                                      :validTo="version.validTo"
                                      :versions="versions"
                                      @versionUpdate="onVersionUpdate"/>
              </td>
              <td class="py-2">
                <CustomButton @clicked="selectVersion(version.versionNum)"
                        :disabled="!editingEnabled" >
                        Restore
                </CustomButton>
              </td>
            </tr>
          </tbody>
        </table>
        <Preloader v-if="loading" :type="'big'"/>
      </Column>
    </div>
    <div class="flex -mx-2 mb-4">
      <div class="w-1/2 px-2">
        <Column class="flex flex-col h-72">
          <Heading>Description</Heading>
          <CodeEditor :readonly="codeEditorReadOnly"
                      :code="versionComment"
                      :language="'json'"
                      :loading="loading"
                      :height="'h-56'"
                      @codeUpdated="onVersionCommentUpdated"/>
          <p v-if="versionCommentError" class="text-red-500 text-xs mt-0.5">Field is empty</p>
        </Column>
      </div>
      <div class="w-1/2 px-2">
        <Column class="flex flex-col h-72">
          <FiltersAssociated :ruleId="ruleId"
                            :otherFilters="otherFilters"
                            :filtersAssociated="ruleFiltersAssociated"
                            :activeFilters="activeFilters"
                            @filtersSelectionChanged="onFiltersSelectionChanged"/>
        </Column>
      </div>
    </div>
    <div class="flex -mx-2 px-2 space-x-4">
      <CustomButton :disabled="editRuleButtonDisabled" @clicked="toggleEnableEditRule">
        {{ enableButtonText }}
      </CustomButton>
      <CustomButton @clicked="scrollToTesting">
        Jump to testing
      </CustomButton>
    </div>
  </Panel>
</template>

<script>
import fetchDataMixin from '@/mixins/fetchDataMixin.js'
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import Column from '@/components/common/ui/Column.vue'
import Datepicker from '@vuepic/vue-datepicker'
import RuleVersionStatus from '@/components/viewEditRules/RuleVersionStatus.vue'
import RuleVersionDateRange from '@/components/viewEditRules/RuleVersionDateRange.vue'
import FiltersAssociated from '@/components/viewEditRules/FiltersAssociated.vue'
import CodeEditor from '@/components/common/CodeEditor.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import Preloader from '@/components/common/ui/Preloader.vue'
import endpoints from '@/configs/endpoints'

export default {
  name: 'RuleDetails',
  mixins: [fetchDataMixin, dateFormatterMixin],
  components: {
    Panel,
    Heading,
    Column,
    Datepicker,
    RuleVersionStatus,
    RuleVersionDateRange,
    FiltersAssociated,
    CodeEditor,
    CustomButton,
    CustomInput,
    Preloader
  },
  emits: [
    'toggleRuleEdit',
    'versionIdUpdate',
    'versionNumUpdate',
    'versionCodeUpdate',
    'versionCommentUpdate',
    'versionValidFromUpdate',
    'versionValidToUpdate',
    'priorVersionsUpdate',
    'codeUpdated',
    'labelsUpdate',
    'versionChangeType',
    'filtersSelectionChanged'
  ],
  props: {
    activeFilters: {
      type: Object,
      required: true
    },
    ruleId: {
      type: String,
      required: true
    },
    selectedRuleName: {
      type: String,
      required: true
    },
    editingEnabled: {
      type: Boolean,
      required: true
    },
    otherFilters: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      ruleName: '',
      versionId: '',
      versionNum: '',
      versionCode: '',
      versionComment: '',
      versionValidFrom: '',
      versionValidTo: '',
      selectedVersion: null,
      versionCodeError: false,
      versionCommentError: false,
      enableButton: {
        enableText: 'Enable editing',
        disableText: 'Disable editing & discard changes'
      }
    }
  },
  created () {
    this.ruleName = this.selectedRuleName
  },
  computed: {
    fetchSettings () {
      return {
        url: endpoints.rules + '/' + this.ruleId,
        params: {}
      }
    },
    defaultSelectedVersion () {
      return this.fetchedData?.versions.length ? this.fetchedData?.versions[this.fetchedData?.versions.length - 1].versionNum : ''
    },
    versions () {
      const versions = this.fetchedData?.versions || []

      if (versions.length) {
        versions.map((version) => {
          version.editedOn = this.formatDateUI(version.editedOn)
        })

        this.$emit('priorVersionsUpdate', versions)
      }
      // versions.sort(({ versionNum: a }, { versionNum: b }) => b - a)

      return versions
    },
    initialVersions () {
      return this.fetchedData?.versions || []
    },
    codeEditorReadOnly () {
      return !this.editingEnabled
    },
    editRuleButtonDisabled () {
      let disabled = false
      if (this.$store._state.data.user.userInfo &&
          this.activeFilters &&
          this.activeFilters.domain &&
          this.activeFilters.source &&
          this.activeFilters.eventType) {
        if (this.$store._state.data.user.userInfo.superuser) {
          return false
        }
        const grants = this.$store._state.data.user.userInfo.grants

        disabled = !grants.find(item => item.domain === this.activeFilters.domain[0] &&
        item.source === this.activeFilters.source[0] &&
        item.eventType === this.activeFilters.eventType[0] &&
        item.scopes.includes('edit-rule'))
      }

      return disabled
    },
    enableButtonText () {
      return this.editingEnabled ? this.enableButton.disableText : this.enableButton.enableText
    },
    ruleFiltersAssociated () {
      return this.fetchedData?.filters || []
    }
  },
  watch: {
    fetchSettings () {
      this.clearSelectedData()
    },
    fetchedData (data) {
      if (data?.producedEntityName && data?.sourceEntityName) {
        this.$emit('labelsUpdate', { producedEntityName: data.producedEntityName, sourceEntityName: data.sourceEntityName })
      }
      if (data?.filters) {
        this.$emit('filtersSelectionChanged', data.filters)
      }
    },
    versions () {
      this.selectedVersion = this.versions[this.versions.length - 1] || []
      this.versionCode = this.selectedVersion.code || ''
      this.$emit('versionCodeUpdate', this.versionCode)
      this.versionId = this.selectedVersion.id || ''
      this.$emit('versionIdUpdate', this.versionId)
      this.versionNum = this.selectedVersion.versionNum || ''
      this.$emit('versionNumUpdate', this.versionNum)
      this.versionComment = this.selectedVersion.comment || ''
      this.$emit('versionCommentUpdate', this.versionComment)
      this.versionValidFrom = this.selectedVersion.validFrom || ''
      this.$emit('versionValidFromUpdate', this.versionValidFrom)
      this.versionValidTo = this.selectedVersion.validTo || ''
      this.$emit('versionValidToUpdate', this.versionValidTo)
    },
    versionNum (val) {
      this.$emit('versionNumUpdate', val)
      const isUpdate = this.versions.find(item => item.versionNum === val)
      this.$emit('versionChangeType', !!isUpdate)
    },
    versionValidFrom (val) {
      this.$emit('versionValidFromUpdate', val)
    },
    versionValidTo (val) {
      this.$emit('versionValidToUpdate', val)
    }
  },
  methods: {
    selectVersion (versionNum) {
      this.selectedVersion = this.initialVersions.find(version => version.versionNum === versionNum)
      this.restoreVersion()
    },
    restoreVersion () {
      this.versionNum = this.selectedVersion.versionNum || ''
      this.versionCode = this.selectedVersion.code || ''
      this.versionComment = this.selectedVersion.comment || ''
      this.versionValidFrom = this.selectedVersion.validFrom || ''
      this.versionValidTo = this.selectedVersion.validTo || ''
      this.versionCodeError = false
      this.versionCommentError = false
    },
    toggleEnableEditRule () {
      this.$emit('toggleRuleEdit', !this.editingEnabled)
      if (this.editingEnabled) {
        this.versionCodeError = false
        this.versionCommentError = false
      }
    },
    onFiltersSelectionChanged (filters) {
      this.$emit('filtersSelectionChanged', filters)
    },
    onVersionValidFromUpdated (validFrom) {
      this.versionValidFrom = validFrom
    },
    onVersionValidToUpdated (validTo) {
      this.versionValidFrom = validTo
    },
    onVersionCodeUpdated (code) {
      this.versionCodeError = !code.replace(/\s/g, '')
      this.versionCode = code
      this.$emit('versionCodeUpdate', this.versionCode)
      this.updateCodeHandler()
    },
    onVersionCommentUpdated (comment) {
      this.versionCommentError = !comment.replace(/\s/g, '')
      this.versionComment = comment
      this.$emit('versionCommentUpdate', this.versionComment)
      this.updateCodeHandler()
    },
    updateCodeHandler () {
      this.$emit('codeUpdated', this.versionCode !== this.selectedVersion.code || this.versionComment !== this.selectedVersion.comment)
    },
    clearSelectedData () {
      this.$emit('versionNumUpdate', '')
      this.$emit('versionCodeUpdate', '')
      this.$emit('versionCommentUpdate', '')
      this.$emit('versionValidFromUpdate', '')
      this.$emit('versionValidToUpdate', '')
    },
    onVersionUpdate () {
      this.fetchData()
    },
    scrollToTesting () {
      document.getElementById('testRule').scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  beforeUnmount () {
    this.clearSelectedData()
  }
}
</script>
