import requestDataMixin from '@/mixins/requestDataMixin.js'
import { api } from '@/services/apiService.js'

const fetchDataMixin = {
  mixins: [requestDataMixin],
  data () {
    return {
      loading: false,
      error: false,
      fetchedData: null
    }
  },
  created () {
    if (this.fetchSettings && this.fetchSettings.url) {
      this.fetchData()
    }
  },
  watch: {
    fetchSettings () {
      if (this.fetchSettings && this.fetchSettings.url) {
        this.fetchedData = null
        this.fetchData()
      }
    }
  },
  methods: {
    async fetchData () {
      this.loading = true
      this.cleanSelectedOptions()
      const params = new URLSearchParams()

      if (this.fetchSettings.params) {
        for (const param in this.fetchSettings.params) {
          if (Array.isArray(this.fetchSettings.params[param])) {
            this.fetchSettings.params[param].forEach(item => {
              params.append(param, item)
            })
          } else {
            if (this.fetchSettings.params[param] !== undefined) {
              params.append(param, this.fetchSettings.params[param])
            }
          }
        }
      }

      const [error, response] = await api.get(this.fetchSettings.url, params)
      this.loading = false

      if (error) {
        this.error = true
        console.log(error)
        const err = JSON.parse(JSON.stringify(error))
        if (err.status) {
          this.$store.commit('catchError', err.status)
        } else {
          this.$store.commit('catchError', err.message)
        }
      } else {
        this.error = false
        this.fetchedData = this.fetchSettings.nestedDataObject ? response[this.fetchSettings.nestedDataObject] : response
      }
    },
    cleanSelectedOptions () {
      this.selectedOptions = []
    }
  }
}

export default fetchDataMixin
