<template>
  <Filters :viewEditRulesView="true"
          :auditDataView="false"
          :auditIndividual="false"
          :filtersConfiguration="filtersConfiguration"
          :dateRangeEnabled="true"
          :effectiveDateEnabled="true"
          :addEditFilterEnabled="true"
          @otherFiltersFetched="onOtherFiltersFetched"
          @filtersChange="onFiltersChange"
          @submitFilters="onSubmitFilters"/>
  <Rules v-if="isRulesEnabled"
          :key="refresh"
          :fetchParams="rulesFetchParams"
          :codeUpdated="codeUpdated"
          :activeFilters="activeFilters"
          @selectedRuleChange="onSelectedRuleChange" />
  <div v-bind:class="(isRuleTestEnabled) ? 'flex w-full space-x-2' : ''">
    <div v-bind:class="(isRuleTestEnabled) ? 'h-auto w-1/2' : ''">
      <RuleDetails
        v-if="isRuleDetailsEnabled"
        :key="selectedRuleId"
        :activeFilters="activeFilters"
        :ruleId="selectedRuleId"
        :selectedRuleName="selectedRuleName"
        :editingEnabled="editingEnabled"
        :otherFilters="otherFilters"
        @toggleRuleEdit="onToggleRuleEdit"
        @versionIdUpdate="onVersionIdUpdate"
        @versionNumUpdate="onVersionNumUpdate"
        @versionCodeUpdate="onVersionCodeUpdate"
        @versionCommentUpdate="onVersionCommentUpdate"
        @versionValidFromUpdate="onVersionValidFromUpdate"
        @versionValidToUpdate="onVersionValidToUpdate"
        @priorVersionsUpdate="onPriorVersionsUpdated"
        @codeUpdated="onCodeUpdated"
        @labelsUpdate="onLabelsUpdate"
        @versionChangeType="onVersionChangeType"
        @filtersSelectionChanged="onFiltersSelectionChanged"
        ref="ruleDetails"/>
    </div>
    <div class="h-auto w-1/2">
      <RuleTestSave
      v-if="isRuleTestEnabled"
        :activeFilters="activeFilters"
        :ruleId="selectedRuleId"
        :versionEditing="versionEditing"
        :versionId="versionId"
        :versionNum="versionNum"
        :versionCode="versionCode"
        :versionComment="versionComment"
        :versionValidFrom="versionValidFrom"
        :versionValidTo="versionValidTo"
        :priorVersions="priorVersions"
        :filterSelection="filterSelection"
        @toggleRuleEdit="onToggleRuleEdit"
        @dataProcessed="onDataProcessed"/>
    </div>
  </div>
</template>

<script>
import Filters from '@/components/common/filters/Filters.vue'
import Rules from '@/components/common/Rules.vue'
import RuleDetails from '@/components/viewEditRules/RuleDetails.vue'
import RuleTestSave from '@/components/viewEditRules/RuleTestSave.vue'
import viewMixin from '@/mixins/viewMixin.js'

export default {
  name: 'ViewEditRulesView',
  mixins: [viewMixin],
  components: {
    Filters,
    Rules,
    RuleDetails,
    RuleTestSave
  },
  data () {
    return {
      filtersConfiguration: {
        domain: {
          required: true,
          multiselect: false
        },
        source: {
          required: true,
          multiselect: false
        },
        eventType: {
          required: true,
          multiselect: false
        }
      },
      selectedRuleId: null,
      selectedRuleName: null,
      ruleDetailsEnabled: true,
      ruleEditEnabled: true,
      dataEdited: false,
      editingEnabled: true,
      versionEditing: false,
      versionId: null,
      versionNum: null,
      versionCode: '',
      ruleFilters: [],
      versionComment: '',
      versionValidFrom: '',
      versionValidTo: '',
      priorVersions: [],
      codeUpdated: false,
      producedEntityName: '',
      otherFilters: [],
      filterSelection: [],
      refresh: 0
    }
  },
  computed: {
    rulesFetchParams () {
      return this.activeFilters
    },
    isRuleTestEnabled () {
      return (this.isRuleEditEnabled && this.versionId && this.selectedRuleId) || (this.isRuleEditEnabled && this.versionCode)
    },
    isRulesEnabled () {
      return this.activeFilters &&
      this.activeFilters.domain &&
      this.activeFilters.source &&
      this.activeFilters.eventType &&
      this.searchStarted
    },
    isRuleDetailsEnabled () {
      return this.isRulesEnabled && this.selectedRuleId
    },
    isRuleEditEnabled () {
      return true
      // return this.editingEnabled && this.selectedRuleId
    }
  },
  methods: {
    onSelectedRuleChange (rule) {
      this.selectedRuleId = rule?.activeOptions.length ? (rule.activeOptions[0].id).toString() : null
      this.selectedRuleName = rule?.activeOptions.length ? rule.activeOptions[0].name : null
      this.editingEnabled = true
      this.codeUpdated = false
    },
    onToggleRuleEdit (editingEnabled) {
      this.editingEnabled = editingEnabled

      if (!editingEnabled) {
        this.$refs.ruleDetails.restoreVersion()
        this.codeUpdated = false
      }
    },
    onVersionIdUpdate (versionId) {
      this.versionId = versionId
    },
    onVersionNumUpdate (versionNum) {
      this.versionNum = versionNum
      console.log('Version updated to', versionNum)
    },
    onVersionCodeUpdate (versionCode) {
      this.versionCode = versionCode
    },
    onVersionCommentUpdate (versionComment) {
      this.versionComment = versionComment
    },
    onVersionValidFromUpdate (versionValidFrom) {
      this.versionValidFrom = versionValidFrom
    },
    onVersionValidToUpdate (versionValidTo) {
      this.versionValidTo = versionValidTo
    },
    onPriorVersionsUpdated (priorVersions) {
      this.priorVersions = priorVersions
    },
    onCodeUpdated (codeUpdated) {
      this.codeUpdated = codeUpdated
    },
    onFiltersSelectionChanged (filters) {
      const filtersWithIds = []
      for (const filter of filters) {
        if (filter.id) {
          filtersWithIds.push(filter)
        } else {
          filtersWithIds.push({ id: filter })
        }
      }
      this.filterSelection = filtersWithIds
    },
    onDataProcessed (ev) {
      this.$refs.ruleDetails.fetchData()
      this.editingEnabled = false
      this.codeUpdated = false
      this.refresh++
    },
    onLabelsUpdate ({ producedEntityName, sourceEntityName }) {
      this.producedEntityName = producedEntityName
      this.sourceEntityName = sourceEntityName
    },
    onVersionChangeType (val) {
      this.versionEditing = val
    },
    onOtherFiltersFetched (filters) {
      this.otherFilters = filters
    }
  }
}
</script>
