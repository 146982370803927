<template>
<Filters :viewEditRulesView="false"
          :auditDataView="true"
          :auditIndividual="true"
          :filtersConfiguration="filtersConfiguration"
          :dateRangeEnabled="true"
          :effectiveDateEnabled="false"
          :addEditFilterEnabled="false"
          @filtersChange="onFiltersChange"/>
  <IndividualIdentifier v-if="isIndividualIdentifierEnabled"
                        :identifierValidationType="identifierValidationType"
                        @identifierSelected="onIdentifierSelected" />
  <ItemsList v-if="isIndividualsEnabled"
              @selectedItemChange="onSelectedIndividualChange"
              :label="'Matching individuals'"
              :fetchSettings="individualsFetchSettings"/>
  <ItemsTable v-if="isIndividualEventsEnabled"
              :title="'Events'"
              :searchFilter="false"
              :searchKey="''"
              :dateRangeFilter="true"
              :eventTypesFilter="true"
              :fetchSettings="individualEventsFetchSettings"
              :tableSettings="individualItemsSettings"
              :selectable="true"
              :multiSelect="false"
              :defaultItem="null"
              :dateDetailed="false"
              @selectItems="onSelectedEvent"/>
  <EventDetails v-if="isIndividualEventDetailsEnabled"
                :fetchSettings="individualEventDetailsSettings"
                :defaultDetails="null"
                :envelopeEnabled="true"
                :eventPayloadEnabled="true"
                :rulesRejectedEnabled="true"/>
</template>

<script>
import Filters from '@/components/common/filters/Filters.vue'
import IndividualIdentifier from '@/components/auditIndividual/IndividualIdentifier.vue'
import ItemsList from '@/components/common/items/ItemsList.vue'
import ItemsTable from '@/components/common/items/ItemsTable.vue'
import EventDetails from '@/components/common/EventDetails.vue'
import endpoints from '@/configs/endpoints.js'

export default {
  name: 'AuditIndiviudalView',
  components: {
    ItemsTable,
    Filters,
    IndividualIdentifier,
    ItemsList,
    EventDetails
  },
  data () {
    return {
      activeFilters: [],
      filtersConfiguration: {
        domain: {
          required: true,
          multiselect: false
        },
        source: {
          required: true,
          multiselect: false
        },
        eventType: {
          required: true,
          multiselect: false
        }
      },
      identifierValidationType: '',
      selectedIdentifier: null,
      selectedIndividualId: null,
      selectedEventId: null,
      individualItemsSettings: {
        columnTitles: ['Event date', 'Event processed on', 'Rules executed', 'Rules skipped due to filters', 'Rules with errors'],
        columnKeys: ['timestamp', 'timestampProcessed', 'rulesExecuted', 'rulesSkipped', 'rulesErrors']
      }
    }
  },
  computed: {
    isIndividualIdentifierEnabled () {
      return this.activeFilters && this.activeFilters.domain && this.activeFilters.domain.length &&
      this.activeFilters.identifyIndividual && this.activeFilters.identifyIndividual.length
    },
    isIndividualsEnabled () {
      return this.isIndividualIdentifierEnabled && this.selectedIdentifier
    },
    isIndividualEventsEnabled () {
      return this.selectedIndividualId !== null
    },
    isIndividualEventDetailsEnabled () {
      return this.isIndividualEventsEnabled && this.selectedEventId !== null
    },
    individualsFetchSettings () {
      let params = {}

      if (this.activeFilters.domain && this.activeFilters.domain.length > 0 &&
          this.activeFilters.identifyIndividual && this.activeFilters.identifyIndividual.length > 0 &&
          this.selectedIdentifier) {
        params = {
          domain: this.activeFilters.domain[0],
          individualIdentifyType: this.activeFilters.identifyIndividual[0],
          individualIdentifyValue: this.selectedIdentifier
        }
      }

      const settings = {
        url: endpoints.individuals,
        params
      }

      return settings
    },
    individualEventsFetchSettings () {
      let settings = {}

      if (this.selectedIndividualId && this.activeFilters?.eventType && this.activeFilters?.eventType[0]) {
        settings = {
          url: endpoints.eventsUI,
          params: {
            eventTypeId: this.activeFilters.eventType[0],
            principalId: this.selectedIndividualId,
            ...(this.activeFilters.date_from && { dateFrom: this.activeFilters.date_from }),
            ...(this.activeFilters.date_to && { dateTo: this.activeFilters.date_to })
          }
        }
      }

      return settings
    },
    individualEventDetailsSettings () {
      let settings = {}

      if (this.selectedIndividualId) {
        settings = {
          url: endpoints.eventDetailsUI + '/' + this.selectedEventId,
          params: {}
        }
      }

      return settings
    }
  },
  methods: {
    onFiltersChange (filters) {
      this.activeFilters = filters
      this.selectedIdentifier = null

      if (this.activeFilters && this.activeFilters.identifyIndividual && Object.keys(this.activeFilters.identifyIndividual).length !== 0) {
        this.identifierValidationType = this.activeFilters.identifyIndividual[0]
      } else {
        this.identifierValidationType = ''
      }
    },
    onIdentifierSelected (value) {
      this.selectedIdentifier = value
    },
    onSelectedIndividualChange (individual) {
      this.selectedIndividualId = individual?.id || null

      this.selectedEventId = null
    },
    onSelectedEvent (event) {
      this.selectedEventId = event?.id || null
    }
  }
}

</script>
