import { isAuthenticated, isLoading } from '@/services/auth0Service.js'
import { watch } from 'vue'
import appSettings from '@/configs/appSettings.js'

export const authenticationGuard = (to, from, next) => {
  if (appSettings.auth0Active) {
    const guardAction = () => {
      if (isAuthenticated.value) {
        return to.name === 'Login' ? next({ name: 'ViewEditRules' }) : next()
      }

      return to.name === 'Login' ? next() : next({ name: 'Login' })
    }

    if (!isLoading.value) {
      return guardAction()
    }

    watch(isLoading, (currentValue) => {
      if (!currentValue) {
        return guardAction()
      }
    })
  } else {
    return next()
  }
}
