import { createStore } from 'vuex'
import appTheme from './modules/appTheme'
import user from './modules/user'
import error from './modules/error'

export default createStore({
  modules: {
    appTheme,
    user,
    error
  }
})
