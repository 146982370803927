<template>
  <Panel>
    <Heading class="text-center">Scope</Heading>
    <hr class="mb-4">
    <div class="flex inset-0 -mx-2">
      <div class="w-1/5 px-2">
        <Options :label="'Choose the domain:'"
                  :fetchSettings="domainFetchSettings"
                  :selectable="true"
                  :multiselect="domainMultiselect"
                  :selectedItems="[]"
                  :optionType="'domain'"
                  @optionChange="onOptionChange"/>
      </div>
      <div v-if="isSourceOfEventsVisible" class="w-1/5 px-2">
        <Options :label="'Choose the source of events:'"
                  :fetchSettings="sourceOfEventsFetchSettings"
                  :selectable="true"
                  :multiselect="sourceOfEventsMultiselect"
                  :selectedItems="[]"
                  :optionType="'source'"
                  @optionChange="onOptionChange"/>
      </div>
      <div v-if="isTypeOfSourceEventVisible" class="w-1/5 px-2">
        <Options :label="'Type of source events:'"
                  :fetchSettings="typeOfSourceEventFetchSettings"
                  :selectable="true"
                  :multiselect="typeOfSourceEventMultiSelect"
                  :selectedItems="[]"
                  :optionType="'eventType'"
                  @optionChange="onOptionChange"/>
      </div>
      <div v-if="isOtherFiltersVisible" class="w-1/5 px-2">
        <Options :label="'Event payload filters:'"
                  :fetchSettings="otherFiltersFetchSettings"
                  :options="otherFiltersAdditionalOptions"
                  :selectable="true"
                  :multiselect="true"
                  :disableOnlyChildPreselect="true"
                  :selectedItems="[]"
                  :optionType="'otherFilters'"
                  @optionChange="onOptionChange"
                  ref="otherFilters"/>
      </div>
      <div v-if="isDateFilterVisible" class="w-1/5 px-2">
        <Column class="flex flex-col h-80">
          <DateFilter v-if="dateRangeEnabled"
                      :effectiveDateEnabled='effectiveDateEnabled'
                      :effectiveDateLabel="'Effective at date:'"
                      :rangeDateLabel="'Or specify date range:'"
                      @createDateFilter="onCreateDateFilter"/>
          <div v-if="isAddEditFilterEnabled" class="mt-auto">
            <CustomButton @clicked="addEditFilter(false)"
                          class="mb-2 w-full">
                (+) Define new filter
            </CustomButton>
            <CustomButton @clicked="addEditFilter(true)"
                          class="mb-2 w-full"
                          :disabled="isEditFilterButtonDisabled">
              Edit selected filter
            </CustomButton>
            <CustomButton @clicked="deleteFilter(true)"
                          class="w-full"
                          :disabled="isEditFilterButtonDisabled">
              Delete selected filter
            </CustomButton>
          </div>
        </Column>
      </div>
    </div>
    <div v-if="auditIndividual" class="flex mt-4 -mx-2">
      <div class="w-1/5 px-2">
        <Options v-if="identifyIndividualEnabled"
                :label="'How to identify the individual:'"
                :fetchSettings='identifyIndividualMethodFetchSettings'
                :selectable="true"
                :multiselect="false"
                :selectedItems="[]"
                :optionType="'identifyIndividual'"
                @optionChange="onOptionChange"/>
      </div>
    </div>
    <div v-if="!auditIndividual" class="flex justify-end">
      <div class="w-1/5 pl-4">
       <CustomButton v-if="importRuleButtonEnabled"
                    @clicked="importRule"
                    class="w-full mt-2">
              Import rule
        </CustomButton>
        <CustomButton @clicked="submitFilters"
                :disabled="serchButtonDisabled"
                class="w-full mt-4">
                Search
        </CustomButton>
      </div>
    </div>
  </Panel>
  <AddEditFilterPopup v-if="isAddEditFilterPopupEnabled"
                      :editMode="editFilterMode"
                      :filterId="selectedOtherFilterId"
                      :activeFilters="activeFilters"
                      @popupEvent="onAddEditFilterPopupEvent"/>
  <Popup v-if="isAddEditFilterDataProcessedPopupEnabled"
        :text="addEditFilterDataProcessedPopupText"
        :isChoicePopup="false"
        :confirmText="'Ok'"
        @popupEvent="onAddEditFilterDataProcessedPopupEvent"/>
  <ImportRulePopup v-if="importRulePopupEnabled"
                  @popupEvent="ImportRulePopupEvent"/>

</template>
<script>
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import Column from '@/components/common/ui/Column.vue'
import Options from '@/components/common/Options.vue'
import DateFilter from '@/components/common/filters/DateFilter.vue'
import AddEditFilterPopup from '@/components/common/filters/addEditFilterPopup/AddEditFilterPopup.vue'
import Popup from '@/components/common/ui/Popup.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import ImportRulePopup from '@/components/common/filters/ImportRulePopup.vue'

import endpoints from '@/configs/endpoints'

export default {
  name: 'Filters',
  emits: ['filtersChange', 'submitFilters', 'otherFiltersFetched'],
  mixins: [dateFormatterMixin, requestDataMixin],
  components: {
    Panel,
    Heading,
    Column,
    Options,
    DateFilter,
    AddEditFilterPopup,
    Popup,
    CustomButton,
    ImportRulePopup
  },
  props: {
    viewEditRulesView: {
      type: Boolean,
      required: true
    },
    auditDataView: {
      type: Boolean,
      required: true
    },
    auditIndividual: {
      type: Boolean,
      required: true
    },
    filtersConfiguration: {
      type: Object,
      required: true
    },
    dateRangeEnabled: {
      type: Boolean,
      required: true
    },
    effectiveDateEnabled: {
      type: Boolean,
      required: true
    },
    addEditFilterEnabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      domainFetchSettings: {
        url: endpoints.domainsUI,
        params: {}
      },
      activeDomainIds: [],
      activeSourceOfEventsIds: [],
      activeTypeOfSourceEventIds: [],
      activeFilters: {},
      otherFiltersAdditionalOptions: [],
      editFilterMode: false,
      isAddEditFilterPopupEnabled: false,
      isAddEditFilterDataProcessedPopupEnabled: false,
      selectedOtherFilterId: null,
      importRulePopupEnabled: false
    }
  },
  computed: {
    domainMultiselect () {
      return this.filtersConfiguration.domain.multiselect
    },
    isSourceOfEventsVisible () {
      return this.activeDomainIds.length
    },
    sourceOfEventsFetchSettings () {
      let fetchSettings = {}
      if (this.activeDomainIds.length) {
        fetchSettings = {
          url: endpoints.sourcesUI,
          params: {
            domain: this.activeDomainIds
          }
        }
      }
      return fetchSettings
    },
    sourceOfEventsMultiselect () {
      return this.filtersConfiguration.source.multiselect
    },
    isTypeOfSourceEventVisible () {
      return this.activeSourceOfEventsIds.length
    },
    typeOfSourceEventFetchSettings () {
      let fetchSettings = {}
      if (this.activeSourceOfEventsIds.length) {
        fetchSettings = {
          url: endpoints.eventTypesUI,
          params: {
            source: this.activeSourceOfEventsIds
          }
        }
      }
      return fetchSettings
    },
    typeOfSourceEventMultiSelect () {
      return this.filtersConfiguration.eventType.multiselect
    },
    isOtherFiltersVisible () {
      return this.activeTypeOfSourceEventIds.length
    },
    otherFiltersFetchSettings () {
      let fetchSettings = {}
      if (!this.viewEditRulesView && this.activeTypeOfSourceEventIds.length) {
        fetchSettings = {
          url: endpoints.filtersUI,
          params: {
            eventType: this.activeTypeOfSourceEventIds
          }
        }
      }
      return fetchSettings
    },
    isDateFilterVisible () {
      return this.isOtherFiltersVisible
    },
    isAddEditFilterEnabled () {
      return this.addEditFilterEnabled
    },
    isSuperUser () {
      return this.$store?._state?.data?.user?.userInfo?.superuser
    },
    serchButtonDisabled () {
      let access = false

      if (this.$store._state.data.user.userInfo &&
          this.activeFilters &&
          this.activeFilters.domain &&
          this.activeFilters.source &&
          this.activeFilters.eventType) {
        if (this.isSuperUser) {
          return false
        }
        const grants = this.$store._state.data.user.userInfo.grants

        access = grants.find(item => item.domain === this.activeFilters.domain[0] &&
        item.source === this.activeFilters.source[0] &&
        item.eventType === this.activeFilters.eventType[0] &&
        item.scopes.includes(this.auditDataView ? 'audit-logs' : 'view-rule'))
      }

      return !access || (!this.activeDomainIds.length && this.filtersConfiguration.domain.required) ||
      (!this.activeSourceOfEventsIds.length && this.filtersConfiguration.source.required) ||
      (!this.activeTypeOfSourceEventIds.length && this.filtersConfiguration.eventType.required)
    },
    isEditFilterButtonDisabled () {
      return this.selectedOtherFilterId === null
    },
    identifyIndividualEnabled () {
      return !this.serchButtonDisabled
    },
    identifyIndividualMethodFetchSettings () {
      let settings = {}

      if (this.activeDomainIds.length) {
        settings = {
          url: endpoints.individualTypes,
          params: { domain: this.activeDomainIds }
        }
      }

      return settings
    },
    importRuleButtonEnabled () {
      return this.isSuperUser
    }
  },
  methods: {
    async onOptionChange (option) {
      this.setFilters(option)
      if (option.type === 'domain') {
        this.activeDomainIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.id) : []
        this.activeSourceOfEventsIds = []
        this.activeTypeOfSourceEventIds = []
      }

      if (option.type === 'source') {
        this.activeSourceOfEventsIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.id) : []
        this.activeTypeOfSourceEventIds = []
      }

      if (option.type === 'eventType') {
        this.activeTypeOfSourceEventIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.id) : []
        if (this.viewEditRulesView && this.activeTypeOfSourceEventIds.length) {
          const url = endpoints.filtersUI
          const params = {
            eventType: this.activeTypeOfSourceEventIds
          }

          this.loading = true
          const [error, response] = await this.requestData('get', url, params, {}, 'loading')
          this.loading = false

          if (error) {
            console.log(error)
            const err = JSON.parse(JSON.stringify(error))
            if (err.status) {
              this.$store.commit('catchError', err.status)
            } else {
              this.$store.commit('catchError', err.message)
            }
          } else {
            this.otherFiltersAdditionalOptions = response
            this.$emit('otherFiltersFetched', response)
          }
        }
      }

      if (option.type === 'otherFilters') {
        if (option.activeOptions.length === 1 && option.activeOptions[0].type !== 'date') {
          this.selectedOtherFilterId = option.activeOptions[0].id
        } else {
          this.selectedOtherFilterId = null
        }
      }
    },
    setFilters (option) {
      if (option.type === 'otherFilters') {
        if (this.activeFilters.filters) {
          delete this.activeFilters.filters
        }

        if (option.activeOptions.length > 0) {
          option.activeOptions.forEach(item => {
            this.activeFilters.filters = option.activeOptions.map(item => item.id)
          })
        }
      } else {
        if (option.activeOptions.length > 0) {
          this.activeFilters[option.type] = option.activeOptions.map(obj => obj.id)
        } else {
          delete this.activeFilters[option.type]
        }
      }

      this.$emit('filtersChange', this.auditIndividual ? this.activeFilters : true)
    },
    onCreateDateFilter (dateFilter) {
      if (this.activeFilters.date_for) {
        delete this.activeFilters.date_for
      }

      if (this.activeFilters.date_from) {
        delete this.activeFilters.date_from
      }

      if (this.activeFilters.date_to) {
        delete this.activeFilters.date_to
      }

      if (dateFilter.date_for) {
        this.activeFilters.date_for = dateFilter.date_for
      }

      if (dateFilter.date_from) {
        this.activeFilters.date_from = dateFilter.date_from
      }

      if (dateFilter.date_to) {
        this.activeFilters.date_to = dateFilter.date_to
      }
      this.$emit('filtersChange', true)
    },
    submitFilters () {
      this.$emit('submitFilters', this.activeFilters)
    },
    addEditFilter (editMode) {
      this.isAddEditFilterPopupEnabled = true
      this.editFilterMode = editMode
    },
    async deleteFilter () {
      const deletedFilter = '' + this.activeFilters.filters[0]
      console.log(deletedFilter)
      console.log(this.otherFiltersAdditionalOptions)
      const url = '/v1/filters'
      const [error, response] = await this.requestData('delete', url, { ids: deletedFilter }, {}, 'dataProcessing')

      if (error) {
        console.log('Unable to delete filter', error)
      } else {
        if (response.success) {
          console.log('Filter deleted')
          this.otherFiltersAdditionalOptions = this.otherFiltersAdditionalOptions.filter(filter => filter.id !== deletedFilter)
          this.selectedOtherFilterId = null
        } else {
          alert('Unable to delete this filter: ' + response.detail)
        }
      }
    },
    onAddEditFilterPopupEvent (data) {
      if (data && data.type === 'add') {
        this.otherFiltersAdditionalOptions.push(data.filter)
        this.isAddEditFilterDataProcessedPopupEnabled = true
        this.addEditFilterDataProcessedPopupText = 'Filter added.'
      } else if (data && data.type === 'edit') {
        this.$refs.otherFilters.editOption(data.filter)
        this.isAddEditFilterDataProcessedPopupEnabled = true
        this.addEditFilterDataProcessedPopupText = 'Filter edited.'
      }

      this.isAddEditFilterPopupEnabled = false
      this.editFilterMode = false
    },
    onAddEditFilterDataProcessedPopupEvent () {
      this.isAddEditFilterDataProcessedPopupEnabled = false
    },
    importRule () {
      this.importRulePopupEnabled = true
    },
    ImportRulePopupEvent () {
      this.importRulePopupEnabled = false
    }
  }
}
</script>
