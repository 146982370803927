<template>
  <Panel>
    <Heading class="text-center">Edit configuration</Heading>
    <hr class="mb-4">
    <div class="mr-4">
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Domain name</span>
        <CustomInput
          v-model.trim='domain.domain_name'
          name="Domain name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Sub domain name</span>
        <CustomInput
          v-model.trim='domain.subdomain_name'
          name="Sub domain name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Entity name</span>
        <CustomInput
          v-model.trim='domain.entity_type'
          name="Entity name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Event type</span>
        <CustomInput
          v-model.trim='domain.event_type'
          name="Entity name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Notification mode</span>
        <select v-model.trim="domain.mode">
          <option v-for="option in mods" :key="option.value" :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Publisher name</span>
        <CustomInput
          v-model.trim='domain.publisher'
          name="Collection name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Spec version</span>
        <CustomInput
          v-model.trim='domain.spec_version'
          name="Primary key name"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Topic name</span>
        <CustomInput
          v-model.trim='domain.topic_name'
          name="Model"
          :searchStyle="false"
        />
        <span class="block my-auto">Dry run mode topic name</span>
        <CustomInput
          v-model.trim='domain.dry_run_topic_name'
          name="Model"
          :searchStyle="false"
        />
      </div>
      <div class="grid grid-cols-7 mb-4 gap-x-8">
        <span class="block my-auto">Topic project</span>
        <CustomInput
          v-model.trim='domain.topic_project'
          name="Model"
          :searchStyle="false"
        />
        <span class="block my-auto">Dry run mode topic project</span>
        <CustomInput
          v-model.trim='domain.dry_run_topic_project'
          name="Model"
          :searchStyle="false"
          class=""
        />
      </div>
      <div class="flex space-x-4 mb-4">
        <CustomButton
          class="rounded-md px-4 py-2"
          @click="saveChanges()"
        >
          Save changes
        </CustomButton>
        <CustomButton
          class="rounded-md px-4 py-2"
          @click="checkData()"
        >
          Cancel
        </CustomButton>
        <CustomButton
          class="rounded-md px-4"
          @click="exportSelectedDomain"
        >
          Export selected
        </CustomButton>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import CustomInput from '@/components/common/custom/CustomInput.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'

export default {
  name: 'EditFormNotifications',
  components: {
    Panel,
    Heading,
    CustomInput,
    CustomButton
  },
  props: {
    activeItem: {
      type: Object
    }
  },
  emits: ['saveChanges', 'showPopup'],
  data () {
    return {
      domain: {
        domain_name: '',
        subdomain_name: '',
        event_type: '',
        entity_type: '',
        mode: 'one_notification_per_mutated_field',
        publisher: '',
        spec_version: '',
        topic_name: '',
        dry_run_topic_name: '',
        topic_project: '',
        dry_run_topic_project: ''
      },
      mods: [
        { name: 'one notification per one document', value: 'one_notification_per_one_document' },
        { name: 'one notification per mutated field', value: 'one_notification_per_mutated_field' },
        { name: 'one notification with all mutated fields', value: 'one_notification_all_mutated_fields' }
      ]
    }
  },
  mounted () {
    this.checkData()
  },
  watch: {
    activeItem () {
      this.checkData()
    }
  },
  methods: {
    resetForm () {
      this.domain = {
        domain_name: '',
        subdomain_name: '',
        event_type: '',
        entity_type: '',
        mode: 'one_notification_per_mutated_field',
        publisher: '',
        spec_version: '',
        topic_name: '',
        dry_run_topic_name: '',
        topic_project: '',
        dry_run_topic_project: ''
      }
    },
    checkData () {
      if (this.activeItem.id) {
        this.domain = { ...this.activeItem }
      } else {
        this.resetForm()
      }
    },
    saveChanges () {
      const item = { ...this.domain }
      delete item.dry_run_topic_project
      delete item.dry_run_topic_name
      if (Object.values(item).every(x => !!x)) {
        this.$emit('saveChanges', this.domain)
      } else {
        this.$emit('showPopup')
      }
    },
    exportSelectedDomain () {
      const exportItem = { ...this.domain }
      delete exportItem.id
      const a = document.createElement('a')
      const file = new Blob([JSON.stringify(exportItem)], { type: 'text/plain' })
      a.href = URL.createObjectURL(file)
      a.download = `${exportItem.entity_type}.json`
      a.click()
    }
  }
}
</script>
