<template>
  <Panel>
    <Heading class="text-center">Scope</Heading>
    <hr class="mb-4">
    <div class="flex inset-0 -mx-2">
      <div v-if="auditIndividual" class="w-1/3 px-2">
        <Options
                :label="'How to identify the individual:'"
                :fetchSettings='identifyIndividualFetchSettings'
                :selectable="true"
                :multiselect="false"
                :disableOnlyChildPreselect="true"
                :itemListWithEmail="true"
                :idField="'uid'"
                :selectedItems="[]"
                :optionType="'user_id'"
                @optionChange="onOptionChange"/>
      </div>
      <div class="w-1/3 px-2">
        <Column class="flex flex-col h-80">
          <DateFilter v-if="dateRangeEnabled"
                      :effectiveDateEnabled='effectiveDateEnabled'
                      :effectiveDateLabel="'Effective at date:'"
                      :rangeDateLabel="'Specify date range:'"
                      :enableTimePicker="true"
                      @createDateFilter="onCreateDateFilter"/>
        </Column>
      </div>
    </div>
    <div class="flex justify-end">
      <div class="w-1/5 pl-4">
        <CustomButton @clicked="submitFilters"
                :disabled="serchButtonDisabled"
                class="w-full mt-4">
                Search
        </CustomButton>
      </div>
    </div>
  </Panel>

</template>
<script>
import Panel from '@/components/common/ui/Panel.vue'
import Heading from '@/components/common/ui/Heading.vue'
import Column from '@/components/common/ui/Column.vue'
import Options from '@/components/common/Options.vue'
import DateFilter from '@/components/common/filters/DateFilter.vue'
import CustomButton from '@/components/common/custom/CustomButton.vue'
import dateFormatterMixin from '@/mixins/dateFormatterMixin.js'
import requestDataMixin from '@/mixins/requestDataMixin.js'
import endpoints from '@/configs/endpoints'

export default {
  name: 'AuditFilters',
  components: {
    Panel,
    Heading,
    Column,
    Options,
    DateFilter,
    CustomButton
  },
  emits: ['filtersChange', 'submitFilters', 'otherFiltersFetched'],
  mixins: [dateFormatterMixin, requestDataMixin],
  props: {
    viewEditRulesView: {
      type: Boolean,
      required: true
    },
    auditDataView: {
      type: Boolean,
      required: true
    },
    auditIndividual: {
      type: Boolean,
      required: true
    },
    filtersConfiguration: {
      type: Object,
      required: true
    },
    dateRangeEnabled: {
      type: Boolean,
      required: true
    },
    effectiveDateEnabled: {
      type: Boolean,
      required: true
    },
    addEditFilterEnabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      identifyIndividualFetchSettings: {
        url: endpoints.authUsers,
        params: {}
      },
      activeFilters: {},
      selectedOtherFilterId: null
    }
  },
  computed: {
    isAddEditFilterEnabled () {
      return this.addEditFilterEnabled
    },
    serchButtonDisabled () {
      let access = false

      if (this.$store._state.data.user.userInfo) {
        if (this.$store._state.data.user.userInfo.superuser) {
          return false
        }
        const grants = this.$store._state.data.user.userInfo.grants

        access = grants.find(item => item.scopes.includes(this.auditDataView ? 'audit-logs' : 'view-rule'))
      }

      return !access
    },
    isEditFilterButtonDisabled () {
      return this.selectedOtherFilterId === null
    }
  },
  methods: {
    async onOptionChange (option) {
      this.setFilters(option)

      if (option.type === 'user_id') {
        this.activeTypeOfSourceEventIds = option.activeOptions.length > 0 ? option.activeOptions.map(obj => obj.uid) : []
      }
    },
    setFilters (option) {
      if (option.type === 'user_id') {
        this.activeFilters[option.type] = option.activeOptions.map(obj => obj.uid)
      } else {
        if (option.activeOptions.length > 0) {
          this.activeFilters[option.type] = option.activeOptions.map(obj => obj.id)
        } else {
          delete this.activeFilters[option.type]
        }
      }

      this.$emit('filtersChange', this.auditIndividual ? this.activeFilters : true)
    },
    onCreateDateFilter (dateFilter) {
      if (this.activeFilters.date_from) {
        delete this.activeFilters.date_from
      }

      if (this.activeFilters.date_to) {
        delete this.activeFilters.date_to
      }

      if (dateFilter.date_from) {
        this.activeFilters.date_from = dateFilter.date_from
      }

      if (dateFilter.date_to) {
        this.activeFilters.date_to = dateFilter.date_to
      }
      this.$emit('filtersChange', true)
    },
    submitFilters () {
      this.$emit('submitFilters', this.activeFilters)
    }
  }
}
</script>
